.trading-data {
  padding: 12px;
  height: 100%;

  .scroll {
    height: calc(100% - 260px);
  }

  .table td,
  th {
    padding: 6px;
  }

  .btn-secondary {
    background-color: $dark2-color;
    border: 0px;
    color: $light3-color;
    height: 24px;
    font-size: 13px;
    line-height: 20px;
    align-items: center;
    padding: 0px 10px 0px 10px !important;
    margin: 0px;
  }

  .btn-secondary.active {
    background-color: $active-color !important;
    border-color: $active-color !important;
    z-index: 0 !important;
    color: $dark2-color !important;
  }

  .bg-transparent {
    background-color: transparent !important;
    color: $light3-color !important;
    border-color: $dark2-color !important;

    span {
      font-weight: 500;
      color: #f5f5f5 !important;
    }
  }
}
