.fw-700 {
  font-weight: 700 !important;
}

.fw-600 {
  font-weight: 600 !important;
}

.fw-500 {
  font-weight: 500 !important;
}

.fw-400 {
  font-weight: 400 !important;
}

.fw-300 {
  font-weight: 300 !important;
}

.fw-200 {
  font-weight: 200 !important;
}

.fw-100 {
  font-weight: 100 !important;
}

%text {
  .text {
    &--xl {
      font-size: 24px;
      line-height: 1.5;
    }

    &--lg {
      font-size: 20px;
      line-height: 32px;

      font-weight: 500;
    }

    &--md {
      font-size: 16px;
      line-height: 24px;

      font-weight: 500;
    }

    &--sm {
      font-size: 13px;
      line-height: 20px;

      font-weight: 400;
    }

    &--xs {
      font-size: 11px;
      line-height: 14px;
    }

    &--x {
      font-size: 9px;
      line-height: 14px;
    }

    &--gray {
      color: $gray-color;
    }

    &--light {
      color: $light-color;
    }

    &--light1 {
      color: $light1-color;
    }

    &--light3 {
      color: $light3-color;
    }

    &--light-blue2 {
      color: $light-blue2;
    }

    &--light-blue3 {
      color: $light-blue3;
    }

    &--dark-blue3 {
      color: $dark-blue3;
    }

    &--dark-blue2 {
      color: $dark-blue2;
    }

    &.r {
      color: $ref-color;
    }

    &.d {
      color: $down-color;
    }

    &.i {
      color: $up-color;
    }

    &.f {
      color: $floor-color;
    }

    &.c {
      color: $ceil-color;
    }
  }
}
