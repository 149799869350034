.personal-page {
  color: $light-color;
  display: flex;
  flex-direction: row;
  background-color: $bg-dark;

  .icon {
    width: 16px;
    height: 16px;
    object-fit: contain;
    background-color: $gray-color;

    &.is--close {
      transform: rotate(180deg);
      transition: transform 0.3s ease;
    }
  }

  .btn-withdraw {
    display: inline-block;
    height: 100%;
    padding: 2px 12px;
    text-align: center;
    vertical-align: middle;
    color: $light-color;
    border-radius: 24px;
    cursor: pointer;

    &.bg-info {
      background-color: $dark-blue3 !important;
    }

    &.bg-warning {
      background-color: $unAuthen-color;
    }
  }

  .personal-content {
    color: $light-color;
    padding: 12px;
    border-radius: 4px;
    width: calc(100vw - 234px);
    height: calc(100vh - 134px);
    position: relative;

    .card {
      border: none;
      background-color: transparent;

      .card-body {
        padding: 0;
        background-color: #2f3134;
        border: none;
      }
    }
  }

  .form-search-head {
    background-color: $black2-color;
  }
}

.personal-assets {
  position: relative;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  overflow: hidden;
  @extend %text;

  .w-150 {
    width: 150px;
  }

  .w-100px {
    width: 100px;
  }

  .w-80px {
    width: 80px;
  }

  &-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 13px;

    &__right {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 4px;

      .refresh {
        display: flex;
        align-items: center;
        text-align: center;
        justify-content: center;
        // margin-right: 22px;
        cursor: pointer;

        span {
          margin: 0px 2px;
        }

        &:hover {
          .iRefresh {
            background-color: $light-color;
          }

          color: $light-color;
        }
      }
    }

    .icon {
      width: 10px;
      height: 16px;
      object-fit: contain;
      background-color: $light3-color;
    }

    .iRefresh {
      width: 12px !important;
      height: 12px !important;
    }
  }

  &-body {
    padding: 12px;
    height: 100%;
    max-height: calc(100vh - 160px);

    .body-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
    }

    .btn-reload {
      background-color: $dark3-color;
    }
  }

  .title-table {
    font-size: 16px;
    line-height: 24px;
    color: $light-color;
  }

  .section-chart {
    display: flex;
    flex-direction: row;
    font-size: 13px;
    line-height: 20px;
    font-weight: 500;
    margin-top: 12px;
    margin-bottom: 12px;

    .text-title {
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      color: $light3-color;
    }

    .circle {
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background-color: $up-color;
      display: inline-block;
    }

    &__info {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: auto;
      min-width: 260px;
      gap: 24px;
    }

    &__chart {
      display: flex;
    }

    .metric {
      display: flex;
      align-items: center;
      gap: 36px;
      color: $light-color;

      &__value {
        display: flex;
        align-items: center;
        gap: 4px;
        flex: 2;
      }

      &__key {
        flex: 3;
      }
    }

    .chart-panel {
      flex: 1;
      display: flex;
      align-items: center;
      flex-direction: row;
      justify-content: center;
      gap: 24px;
    }
  }

  .section-details {
    display: flex;
    flex-direction: row;
    gap: 12px;

    .section-detail {
      flex: 1;
    }
  }

  .table {
    tr {
      height: 32px;
    }
  }

  .table-header-box {
    margin: 24px 0px 12px 0px;
    display: flex;
    height: 24px;
    align-items: bottom;
    justify-content: space-between;

    .header-left {
      display: flex;
      height: 24px;
      align-items: center;
    }
  }

  .section {
    display: flex;
    margin-top: 12px;
    flex-direction: column;
  }

  .section-right {
    padding-bottom: 72px;

    .table {
      tr {
        height: 32px;
      }
    }
  }

  .package-cards {
    display: flex;
    flex-wrap: wrap;
    gap: 36px;
  }

  .package-card {
    display: flex;
    flex-direction: column;
    flex: 1 1 calc(50% - 18px);
    max-width: calc(50% - 18px);
    background-color: $dark2-color;
    border-radius: 6px;
    border: 2px solid $dark3-color;
    padding: 24px;
    gap: 12px;

    &__head {
      display: flex;
      gap: 12px;
      justify-content: space-between;
    }

    &__action {
      height: 32px;
      flex: 1;

      &--status {
        display: flex;
        border: 1px solid $dark3-color;
        align-items: center;
        text-align: center;
        justify-content: center;
        border-radius: 4px;
        user-select: none;
      }
    }

    &__desc {
      display: grid;
      grid-template-rows: minmax(3em, auto);
    }
  }

  .history-card {
    padding: 12px;
    border-radius: 6px;
    border: 1px solid $dark3-color;

    .badge {
      padding: 6px 12px;
      border: 1px solid $dark3-color;
      border-radius: 4px;
    }

    .btn {
      height: 32px;
    }

    &--left {
      width: 80%;
    }

    &--right {
      width: 40%;
    }
  }

  .margin-package-history {
    &__title {
      padding-top: 24px;
    }

    &__filters {
      padding-top: 12px;
      padding-bottom: 12px;
    }
  }
}

.switch-filter {
  display: flex;
  justify-content: center;
  align-items: center;

  .switch-item {
    margin-left: 8px;
  }
}

.light {
  .package-card {
    background-color: $white-color;
    color: $dark3-color;
    border: 2px solid $light1-color;

    &__action {
      &--status {
        border: 1px solid $light1-color;
      }
    }
  }

  .history-card {
    border: 1px solid $light1-color;

    .badge {
      border: 1px solid $light1-color;
    }
  }
}
