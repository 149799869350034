@import '../helpers/variables';

/**
* !color bang gia
*/

.ordStt_E,
.ordStt_6,
.ordStt_9,
.ordStt_E,
.ordStt_X,
.txt-red,
.d {
  color: $down-color !important;
}

.c,
.txt-gia-tran,
.ceiling {
  color: $ceil-color !important;
}

.r,
.e,
.txt-gia-tc,
.ordStt_P,
.ordStt_N,
.ordStt_R,
.ordStt_5,
.ordStt_3,
.ordStt_2,
.ordStt_1,
.ordStt_8 {
  color: $ref-color !important;
}

.f,
.floor,
.txt-gia-san,
.ordStt_I,
.ordStt_7 {
  color: $floor-color !important;
}

.i,
.txt-lime,
.g,
.ordStt_F,
.ordStt_A,
.ordStt_4 {
  color: $up-color !important;
}

.c.active {
  background: $ceil-color !important;
  color: $light-color !important;
}

.d.active,
.bg-red {
  background: $down-color !important;
  color: $light-color !important;
}

.i.active,
.bg-green {
  background: $up-color !important;
  color: $dark3-color !important;
  font-weight: 500;
}

.e.active,
.bg-tc {
  background: $ref-color !important;
  color: $dark3-color !important;
  font-weight: 600;
}

.up_mark {
  color: $up-color;
}

.down_mark {
  color: $down-color;
}

.unchange_mark {
  color: $ref-color;
}

.ceil_mark {
  color: $ceil-color;
}

.floor_mark {
  color: $floor-color;
}
