%dwn-light {
  // deposite-withdraw for margin (dwm)
  .dwm {
    &-form {
      &-row {
        input {
          background-color: $light-color;
          color: $dark3-color;

          &::placeholder {
            color: $light3-color;
          }
        }
      }
    }

    &-note {
      background-color: $light-color;
    }

    &-tabs {
      background-color: $light-color;
    }

    &-tab {
      color: $gray-color;

      &.active {
        background-color: $active1-color;
        color: $dark3-color;
      }
    }

    &-history {
      margin-top: 36px;
      height: calc(100% - 340px);

      &-header {
        display: flex;
        justify-content: space-between;
      }

      &-body {
        height: calc(100% - 24px);
      }

      &--right {
        display: flex;
        gap: 8px;
      }

      button {
        height: 24px;
      }
    }
  }
}

%common {
  .bg-down-20 {
    background-color: rgba($color: $down1-color, $alpha: 0.2);
  }

  .bg-up-20 {
    background-color: rgba($color: $up1-color, $alpha: 0.2);
  }

  .bg-ref-20 {
    background-color: rgba($color: $ref1-color, $alpha: 0.2);
  }

  .bg-ceil-20 {
    background-color: rgba($color: $ceil1-color, $alpha: 0.2);
  }

  .bg-floor-20 {
    background-color: rgba($color: $floor1-color, $alpha: 0.2);
  }
}

.light {
  @extend %text;
  @extend %common;

  .border-bottom-dark2 {
    border-bottom: 1px solid $light-color;
  }

  /* #region webkit-scroll */
  ::-webkit-scrollbar {
    background-color: $light-color;
  }

  ::-webkit-scrollbar-thumb {
    background-color: $light2-color;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: $light3-color;
  }

  /* # end region webkit-scroll */
  color: $dark3-color;

  /* #region Color */
  .ordStt_E,
  .ordStt_6,
  .ordStt_9,
  .ordStt_E,
  .ordStt_X,
  .txt-red,
  .d {
    color: $down1-color !important;
  }

  .c,
  .txt-gia-tran,
  .ceiling {
    color: $ceil1-color !important;
  }

  .r,
  .e,
  .txt-gia-tc,
  .ordStt_P,
  .ordStt_N,
  .ordStt_R,
  .ordStt_5,
  .ordStt_3,
  .ordStt_2,
  .ordStt_1,
  .ordStt_8 {
    color: $ref1-color !important;
  }

  .f,
  .floor,
  .txt-gia-san,
  .ordStt_I,
  .ordStt_7 {
    color: $floor1-color !important;
  }

  .i,
  .txt-lime,
  .g,
  .ordStt_F,
  .ordStt_A,
  .ordStt_4 {
    color: $up1-color !important;
  }

  .c.active {
    background: $ceil1-color !important;
    color: $white-color !important;
  }

  .d.active,
  .bg-red {
    background: $down1-color !important;
    color: $white-color !important;
  }

  .i.active,
  .bg-green {
    background: $up1-color !important;
    color: $white-color !important;
  }

  .e.active,
  .bg-tc {
    background: $ref1-color !important;
    color: $white-color !important;
  }

  .layout-panel {
    background-color: $white-color;
    label {
      color: $dark3-color;
    }

    .icon {
      background-color: $light3-color !important;
    }

    .menu-tabs {
      .active {
        .icon {
          background-color: $gray-color !important;
        }
      }
    }
  }

  /* #endregion */

  /* #region Table */
  .table {
    color: $gray-color;
    border: 1px solid $light-color;

    tbody td.high-light,
    thead th.high-light {
      background-color: $light-color;
    }

    thead {
      th {
        color: $gray-color;
        // font-weight: 500;
      }
    }

    tbody {
      tr {
        &:nth-child(odd) {
          background-color: $white-color;
        }

        &:nth-child(even) {
          background-color: $light-blue;
        }

        &:hover {
          td {
            background-color: $light-blue1;
          }
        }

        td {
          border: 1px solid $light-color;
          border-top: none;
          border-bottom: none;
        }
      }
    }

    tfoot {
      tr {
        border: 1px solid $light-color;
        td {
          border: 1px solid $light-color;
        }
      }
    }
  }

  .table-bordered {
    th {
      border: 1px solid $light-color;
      background-color: $white-color;
    }
  }

  .radio-button {
    border-color: $light2-color !important;
    background-color: $white-color;

    &::after {
      background-color: $ref1-color;
    }
  }

  .sit-body {
    .radio-button {
      margin-left: 4px;
    }
  }

  .sit {
    &-input__value {
      input {
        color: $dark3-color !important;
        background-color: $light-color !important;

        ::placeholder {
          color: $light3-color;
        }
      }

      .sit-input__overlay {
        color: $dark3-color;
      }
    }
  }

  .paging-custom {
    .button-paging {
      background-color: $light-color !important;
      color: $dark3-color !important;

      .icon {
        background-color: $dark3-color;

        &.disabled {
          background-color: $gray-color;
        }
      }
    }

    .page-count {
      background-color: $light-color !important;
      color: $dark3-color !important;
    }
  }

  .confirm-icon {
    background-color: rgba($down1-color, 0.2);
    justify-content: center;
    align-items: center;

    .icon {
      background-color: rgba($down1-color, 1);
    }
  }

  .paging-size {
    .pagingSize-control-select {
      &__control {
        background-color: $light-color;
        border: none;
      }

      &__menu {
        background-color: $light-color;
      }

      &__single-value {
        color: $dark3-color;
      }

      &__option {
        color: $dark3-color;
        background-color: $light-color;

        &--is-selected {
          color: $dark3-color;
          background-color: $light1-color;
        }

        &:hover {
          background-color: $light2-color;
        }
      }

      &__value-container,
      &__indicators {
      }

      &__dropdown-indicator {
        svg {
          path {
            fill: $dark3-color;
          }
        }

        color: $dark3-color;
      }

      &__indicator-separator {
        display: none;
      }

      &__placeholder {
        color: $dark3-color;
      }
    }
  }

  /* #endregion */

  /* #text region */
  .text {
    &--light {
      color: $dark3-color !important;
    }

    &--light1 {
      color: $dark3-color !important;
    }

    &--light3 {
      color: $gray-color !important;
    }

    &--active {
      color: $active1-color;
    }

    &--success {
      color: $up1-color;
    }

    &--fail {
      color: $down1-color;
    }

    &--gray {
      color: $light3-color;
    }

    &--light-blue3 {
      color: $dark-blue3;
    }
  }

  /* #end region */

  /* switch-custom */
  .switch-custom {
    .model-2 .checkbox input:checked + label {
      background: $up1-color;
    }
  }

  /* end switch-custom */

  /* Toast messages region*/
  .notification-container {
    .notification {
      background-color: $light-blue3;
      color: #000;

      &:hover {
        opacity: 1;
      }

      .toast-body {
        .toast-icon {
          .icon {
          }

          .iSuccess {
            background-color: $up-color;
          }

          .iFail {
            background-color: $down-color;
          }

          .iNotice {
            background-color: $active-color;
          }
        }

        .toast-content {
          .toast-title {
            color: $light-color;
          }

          .toast-description {
            color: $light1-color;
          }
        }
      }
    }
  }

  /* end Toast message region */

  /* #region Layout */
  .app-header {
    &.navbar {
      background-color: $dark-blue3;

      .header-btn {
        background-color: $dark-blue2;
        color: $light-blue2;
      }
    }

    .icon {
      &:hover {
        background-color: $white-color;
      }
    }
  }

  &.app {
    background-color: $light1-color;
  }

  .app-footer {
    background-color: $white-color;
    color: $dark3-color;

    .icon {
      background-color: $dark3-color;
    }

    .iConnecting {
      background-color: $up1-color;
    }

    .footer-btn {
      &:not(.highlight):hover {
        color: $dark3-color;
        background-color: $light-color;

        .icon {
          background-color: $dark3-color;
        }
      }

      &:not(.highlight).active {
        color: $dark3-color;
        background-color: $active1-color;

        .icon {
          background-color: $dark3-color;
        }
      }

      &.highlight {
        background-color: $dark3-color;
        color: $active1-color;
      }
    }

    .text-content {
      color: $gray-color;
    }

    .text-up {
      color: $up1-color;
    }
  }

  .indicator-index {
    .icon {
      background-color: $light3-color;
    }

    .iFloor {
      background-color: $floor1-color;
    }

    .iDown {
      background-color: $down1-color;
    }

    .iRef {
      background-color: $ref1-color;
    }

    .iUp {
      background-color: $up1-color;
    }

    .iCeil {
      background-color: $ceil1-color;
    }

    .market-index {
      &--small {
        background-color: $white-color;
      }

      .market-panel {
        background-color: $white-color;
        color: $dark2-color;
      }
    }

    .switch-view {
      background-color: $white-color;
    }

    .card-index-info {
      &__header {
        background-color: $light-color;
        color: $dark3-color;

        .icon {
          &:hover {
            background-color: $dark3-color;
          }
        }
      }

      .card-index-info-item {
        & + .card-index-info-item {
          border-top: 1px solid $light-color;
        }

        &__name {
          color: $gray-color;
        }
      }
    }

    .switch-view-button {
      &:hover {
        .icon {
          background-color: $dark3-color;
        }
      }
    }

    .unit {
      color: $dark3-color !important;
    }
  }

  .container-fluid .navbar .navbar-collapse {
    background-color: $white-color !important;
  }

  .price-board,
  .drvx-price-board {
    .tbl-toggle-left,
    .tbl-toggle-right {
      path {
        fill: $gray-color;
      }
    }

    .navbar-priceboard {
      .line {
        background-color: $light-color;
      }

      .icon {
        background-color: $gray-color;

        &:not(.active):hover {
          background-color: $dark2-color;
        }
      }

      .iStar {
        &.active {
          background-color: $active1-color;
        }
      }
    }

    .nav-link {
      color: $gray-color !important;

      &.active {
        background-color: $active1-color !important;
        color: $black2-color !important;
      }

      &:not(.active):hover {
        color: $dark3-color !important;
      }
    }

    .dropdown:not(.watchlist-menu) {
      .dropdown-menu {
        .nav-link {
          &:not(.dropdown-toggle) {
            &:not(:last-child)::after {
              border-bottom: 1px solid $light-color;
            }

            &.active {
              background-color: transparent !important;
              color: $active1-color !important;
            }
          }
        }
      }
    }

    .dropdown-menu {
      background-color: $white-color;

      &::before {
        border-top: 9px solid rgba(255, 255, 255, 0);
      }

      .nav-link {
        border-radius: 0;
        color: $gray-color !important;

        :hover {
          background-color: $active1-color;
          color: $light-color !important;
        }

        &.active {
          background-color: $active1-color;
          color: $black2-color !important;
        }
      }
    }

    .watchlist-menu {
      &.active {
        .dropdown-toggle {
          background-color: $active1-color;
          color: $dark-color;

          &:hover {
            color: $dark4-color !important;
          }
        }
      }

      &__item {
        & + .watchlist-menu__item {
          border-top: 1px solid $light-color;
        }
      }

      &__left {
        input {
          background-color: $light-color;
          color: $dark3-color;
        }
      }

      &__right {
        .btn {
          color: $light-color;
          background-color: transparent;
        }
      }

      &__form {
        border-top: 1px solid $light-color;

        .error {
          color: $down-color;
        }
      }

      .nav-link {
        &.active {
          background-color: transparent !important;
          color: $active1-color !important;
        }

        span {
          &:hover {
            background-color: transparent;
            color: $light-color !important;
          }

          &.active {
            color: $active1-color;
          }
        }
      }
    }
  }

  .drvx-price-board {
    .btn--switch {
      background-color: $light-color;
      color: $dark3-color;

      .iNext {
        background-color: $dark3-color;
      }

      &:hover {
        background-color: $light1-color;
      }
    }
  }

  .price-table tr:last-child,
  .watchlist-table tr:last-child {
    border-bottom: 1px solid $light1-color;
  }

  /* #endregion */

  /* #region button */
  .btn {
    @include button(light);
  }

  .btn-icon {
    @include button(light);
  }

  .filter-control-select {
    &__control {
      background-color: $light-color;
    }

    &__menu {
      background-color: $light-color;
    }

    &__single-value {
      color: $dark3-color;
    }

    &__option {
      color: $dark3-color;
      background-color: $light-color;

      &--is-selected {
        color: $dark3-color;
        background-color: $light2-color;
      }

      &:hover {
        background-color: $light1-color;
      }
    }

    &__placeholder {
      color: $dark3-color;
    }
  }

  .filter-datePicker {
    background-color: $light-color;

    &__from,
    &__to {
      color: $dark3-color;
    }

    input {
      background-color: transparent;
      color: $dark3-color;
    }
  }

  .input-text-search {
    background-color: $light-color;
    color: $dark3-color;
    border-radius: 16px;

    input {
      background-color: $light-color;
      color: $dark3-color;
      border: 1px solid $light-color;
    }
  }

  /* #table border radius */
  .table-border-radius {
    box-shadow: 0 0 0 1px $light-color;
  }

  /* #end table border radius */

  /* #endregion */
  .text-white {
    color: $dark3-color !important;
  }

  .text-light {
    color: $dark3-color !important;
  }

  .text-link {
    color: $dark-blue;

    &:hover {
      text-decoration: underline;
    }
  }

  .form-add {
    background-color: $light-color;
    color: $dark3-color;
  }

  .react-autosuggest__suggestions-container--open {
    background-color: $white-color;
  }

  .react-autosuggest__suggestions {
    &-list {
      .react-autosuggest__suggestion {
        + .react-autosuggest__suggestion {
          border-top: 1px solid rgba($light3-color, 0.25);
        }
      }

      .stockSuggest {
        &__symbol {
          color: $dark3-color;
        }

        &__exchange {
          background-color: $light-color;
          color: $gray-color;
        }

        &__right {
          color: $gray-color;
        }
      }
    }
  }

  .react-autosuggest__suggestion--highlighted {
    background-color: $light1-color;
  }

  .expand-search {
    &__input {
      &:hover {
        background-color: $light-color;
        color: $dark3-color;
      }

      &:focus {
        background-color: $light-color;
        color: $dark3-color;
        border: 1px solid $gray-color;
      }

      &:-moz-placeholder {
        color: $light3-color;
      }

      &::-webkit-input-placeholder {
        color: $light3-color;
      }

      &::-webkit-search-decoration,
      &::-webkit-search-cancel-button {
        display: none;
      }
    }
  }

  .card-panel {
    background-color: $white-color;
    color: $dark3-color;

    &-header {
      background-color: $light-color;

      &__title {
        background-color: $white-color;
        color: $dark3-color;

        &.active {
          color: $active1-color;
        }

        &.inactive {
          color: $light4-color;
        }
      }

      &__input {
        background-color: $light-blue;
        color: $dark3-color;
        outline: $active1-color;
      }

      &__select {
        color: $dark3-color;
        background: $light-blue;
      }

      &__label {
        color: #8a8b8d;
      }

      .icon {
        background-color: $dark3-color;
      }
    }

    &-body {
      border: 1px solid $light-color;
    }
  }

  .asset-panel {
    .card-panel-body {
      .assets-items {
        & + .assets-items {
          border-top: 1px solid $light-color;
        }
      }

      .assets-item {
        &__key {
          color: $gray-color;
        }

        &__value {
          color: $dark3-color;
        }
      }
    }

    .short-order-list {
      &__filter {
        background-color: $white-color;
        .input-text-search {
          input {
            background-color: $light-blue;
            color: $dark-color;
          }
        }

        .filter-control-select {
          &__control {
            background-color: $light-blue;
            color: $dark-color;
          }

          &__menu {
            background-color: $light-blue;
            color: $dark-color;
          }

          &__single-value {
            color: $dark-color;
          }

          &__option {
            color: $dark-color;
            background-color: $light-blue;

            &--is-selected {
              color: $dark-color;
              background-color: $light1-color;
            }

            &:hover {
              background-color: $light-color;
            }
          }

          &__placeholder {
            color: $dark1-color;
          }
        }
      }

      &__table {
        th {
          div:hover {
            .icon:not(.iUp),
            .icon:not(.iDown) {
              background-color: $dark-color;
            }
          }
        }

        .header-dropdown {
          &__items {
            background-color: $white-color;
            border: 1px solid $light-color;
          }

          &__item {
            &.active {
              color: $dark-color;
              background-color: $light1-color;
            }

            &:hover {
              background-color: $light-color;
              color: $dark-color;
            }
          }
        }
      }
    }
  }

  .quick-order {
    .order {
      color: $dark3-color;

      .order-button {
        color: $dark3-color;

        &:not(.active):hover {
          background-color: $gray-color;
          color: $light-color;
        }
      }

      .order-buy {
        background-color: $light-color;
        color: $dark3-color;

        &.active {
          background-color: $up1-color;
        }

        &:hover {
          background-color: $up-color;
        }
      }

      .order-sell {
        background-color: $light-color;
        color: $dark3-color;

        &.active {
          background-color: $down1-color;
          color: $light-color;
        }

        &:hover {
          background-color: $down-color;
        }
      }

      .place-order--buy {
        background-color: $up1-color;

        &:hover {
          background-color: $up-color !important;
          color: $dark3-color !important;
        }
      }

      .place-order--sell {
        background-color: $down1-color;
        color: $light-color;

        &:hover {
          background-color: $down-color !important;
        }
      }

      .reset-order {
        background-color: $light-color;

        .icon {
          background-color: $dark3-color;
        }
      }

      .order-text {
        background-color: $light-color;

        .icon {
          background-color: $light3-color;
        }

        input {
          background-color: transparent;
          color: $dark3-color;

          &::placeholder {
            color: $light3-color;
          }

          :-ms-input-placeholder {
            color: $light3-color;
          }

          ::-ms-input-placeholder {
            color: $light3-color;
          }
        }
      }

      .text-desc {
        color: $gray-color;
      }
    }

    .iFloor {
      background-color: $floor1-color !important;
    }

    .iRef {
      background-color: $ref1-color !important;
    }

    .iCeil {
      background-color: $ceil1-color !important;
    }

    .iClose {
      background-color: $dark3-color;
    }

    .trade-price-list {
      .price-list-step {
        &__state {
          background-color: $light-color;
        }

        &__buy {
          background-color: $light-color;
          color: $dark2-color;

          .value {
            background-color: rgba($color: $up1-color, $alpha: 0.2);
          }
        }

        &__sell {
          background-color: $light-color;
          color: $dark2-color;

          .overlay-text {
            color: black;
          }

          .value {
            background-color: rgba($color: $down1-color, $alpha: 0.2);
          }
        }
      }

      .price-list-total {
        background-color: $down1-color;
        color: $light-color;

        &__buy {
          background-color: $up1-color;
          color: $dark3-color;
        }

        &__sell {
          background-color: $down1-color;
          color: $light-color;
        }
      }
    }

    .card-panel {
      &-header {
        .desc {
          color: $dark3-color;
        }
      }
    }
  }

  &.order-list-panel {
    .order-inday-table {
      th {
        color: $gray-color;
        background-color: $white-color;
      }

      td {
        color: $dark3-color;
      }

      .text-white {
        color: $dark3-color !important;
      }
    }

    .input-text-search {
      input {
        background-color: $light-blue;
        border: 1px solid $light-color;
        color: $dark-color;
      }
    }

    .filter-control-select {
      &__control {
        background-color: $light-blue;
      }
    }

    .filter-datePicker {
      background-color: $light-blue;
    }

    .expand-row {
      table td,
      table th {
        background-color: $light-color;
        border: 1px solid $light-color;
      }
    }
  }

  .user-select {
    &__control {
      background-color: $light-color;

      &:hover {
        border-color: $down-color;
      }
    }

    &__option {
      background-color: $white-color !important;
      color: $dark3-color;
      -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

      &:hover {
        background-color: $light-color !important;
      }

      &--is-selected {
        background-color: $light1-color !important;
      }
    }

    &__single-value {
      color: $dark3-color;
    }

    // &__input {}

    &__menu {
      // background-color: hsl(0, 0%, 100%);
      // box-shadow: 0 0 0 1px hsl(0deg 0% 0% / 10%), 0 4px 11px hsl(0deg 0% 0% / 10%);
      background-color: $white-color !important;
    }
  }

  .card-panel-2 {
    background-color: $white-color;
    color: $dark3-color;

    &-header {
      background-color: $light-color;
      color: $gray-color;
    }

    &__tab {
      &.active {
        background-color: $active1-color;
        color: $dark3-color;
      }

      &:not(.active):hover {
        color: $dark3-color;
      }
    }
  }

  .section-overview {
    border-bottom: 1px solid $light-color;

    .overview-metric {
      &__key {
        color: $gray-color;
      }

      &__value {
        color: $dark3-color;
      }
    }
  }

  .personal-content {
    color: $dark3-color;

    .card {
      background-color: transparent;

      .card-body {
        background-color: $white-color;
      }
    }
  }

  .security-search {
    background-color: $light-color;
    color: $light3-color;

    input {
      color: $gray-color;
    }

    .icon {
      background-color: $light3-color;
    }

    &:focus-within {
      border: 1px solid $light1-color;
    }
  }

  .personal-assets {
    &-header {
      &__right {
        .refresh {
          &:hover {
            .iRefresh {
              background-color: $dark3-color;
            }

            color: $dark3-color;
          }
        }
      }

      .icon {
        background-color: $gray-color;
      }
    }

    &-body {
      .btn-reload {
        background-color: $dark3-color;
      }
    }

    table {
      thead {
        tr {
          td {
            border-color: $light-color;
            border-bottom: 1px solid $light-color;
            color: $dark3-color;
          }
        }
      }
    }

    .title-table {
      color: $dark3-color;
    }

    .section-chart {
      .text-title {
        color: $gray-color;
      }

      .circle {
        background-color: $up1-color;
      }

      .metric {
        color: $dark3-color;
      }
    }

    .section-details {
      .text {
        &-white {
          color: $light-color;
        }

        &-desc {
          color: $gray-color;
        }
      }
    }
  }

  .vertical-nav {
    background-color: $white-color;
    border-top: 1px solid $light-color;
    color: $dark3-color;

    .categories {
      & + .categories {
        border-top: 1px solid $light-color;
      }

      &-header {
        a {
          color: $gray-color;
        }

        &:hover {
          background-color: $light-color;

          a {
            color: $dark3-color;
          }
        }

        &.active {
          background-color: $light-color;
          border-right: 2px solid $active1-color;

          a {
            color: $dark3-color;
          }
        }
      }

      .category {
        a {
          color: $gray-color;
        }

        &:hover {
          background-color: $light-color;
          transition: background-color 0.75 ease;

          a {
            color: $dark3-color;
          }
        }

        &.active {
          background-color: $light-color;
          border-right: 2px solid $active1-color;

          a {
            color: $dark3-color;
          }
        }
      }
    }
  }

  .transaction-content {
    background-color: $light1-color;
    color: $dark3-color;

    .input-text-search {
      border-radius: 4px;
    }

    .deposite-withdraw {
      .text {
        &-name {
          color: $dark3-color;
        }

        &-title {
          color: $dark3-color;
        }

        &-desc {
          color: $gray-color;
        }

        &-value {
          color: $up1-color;
        }

        &-highlight {
          color: $active1-color;
        }

        &-warning {
          color: $active1-color !important;
        }

        &--light-3 {
          color: $dark2-color;
        }
      }

      .bg-info--custom {
        background-color: $dark-blue3;
      }

      .bg-warning--custom {
        background-color: $active1-color;
      }

      &-header {
        background-color: $dark1-color;

        &__right {
          color: $light-color;
        }
      }

      &-main {
        &-header {
          background-color: $light-color;
        }

        &-body {
          color: $light-color;
          background-color: $white-color;
        }

        &-tab {
          color: $gray-color;

          &.active {
            color: $dark3-color;
            background-color: $active1-color;
          }

          &:not(.active):hover {
            color: $dark3-color;
          }
        }
      }

      &-main-bankGroup {
        &__content {
          background-color: $light-color;
          color: $dark3-color;
        }
      }

      &-main-bankItem {
        background-color: $light-color;
        color: $dark3-color;

        .bank-infor {
          .bank-logo {
            background-color: $light-color;
          }
        }

        .account-beneficiary {
          .btn {
            &.active {
              background-color: $active1-color;
              color: $dark3-color;
            }
          }
        }

        .bank-qr {
          background-color: $light1-color;
        }

        &-showmore {
          background-color: $light-color;
          color: $dark3-color;
        }
      }
    }

    .cash-internal-transfer-container {
      .cash-transfer-account {
        .row {
          border-bottom: 1px solid $light-color;
        }

        .row-hug {
          border-bottom: 1px solid $light-color;
        }
      }

      .form-trade {
        .form-control {
          background-color: $light-blue;
          color: $dark3-color;
        }
      }
    }

    .bank-hold-container {
      .bank-hold {
        .list-group-item:not(:last-child) {
          border-bottom: 1px solid $light-color;
        }

        .form-control {
          background-color: $light-color;
          color: $dark3-color;
          -webkit-box-shadow: 0 0 0 30px $light-color inset !important;
          -webkit-text-fill-color: $dark3-color !important;
        }
      }
    }

    .executed-gainloss {
      .scrollbar-container {
        .table-container {
          table {
            thead {
              tr {
                td {
                  border-color: $light-color;
                  border-bottom: 1px solid $light-color;
                  color: $dark3-color;
                }
              }
            }
          }
        }
      }
    }

    .filter-datePicker {
      background-color: $light-color;

      &__from,
      &__to {
        color: $dark3-color;
      }

      input {
        background-color: transparent;
        color: $dark3-color;
      }
    }

    .filter-control-calendar {
      .form-hisMargin {
        .input-group {
          background-color: $light-color;

          .form-input {
            color: $dark3-color;
          }
        }
      }
    }

    .paging-custom {
      .button-paging {
        background-color: $light-color !important;
        color: $dark3-color !important;

        .icon {
        }

        .active {
          background-color: $dark3-color;
        }
      }

      .page-count {
        background-color: $light-color !important;
      }
    }

    .paging-size {
      .pagingSize-control-select {
        &__control {
          background-color: $light-color;
          border: none;
        }

        &__menu {
          background-color: $light-color;
        }

        &__single-value {
          color: $dark3-color;
        }

        &__option {
          color: $dark3-color;
          background-color: $light-color;

          &--is-selected {
            color: $dark3-color;
            background-color: $light1-color;
          }

          &:hover {
            background-color: $light2-color;
          }
        }

        &__value-container,
        &__indicators {
        }

        &__dropdown-indicator {
          svg {
            path {
              fill: $dark3-color;
            }
          }

          color: $dark3-color;
        }

        &__indicator-separator {
          display: none;
        }

        &__placeholder {
          color: $dark3-color;
        }
      }
    }

    .transaction-filters__select {
      .filter-control-select {
        &__control {
          background-color: $light-color;
        }

        &__menu {
          background-color: $light-color;
        }

        &__single-value {
          color: $dark3-color;
        }

        &__option {
          color: $dark3-color;
          background-color: $light-color;

          &--is-selected {
            color: $dark3-color;
            background-color: $light2-color;
          }

          &:hover {
            background-color: $light1-color;
          }
        }

        &__placeholder {
          color: $dark3-color;
        }
      }
    }
  }

  .personal-page {
    background-color: $light1-color;
  }

  .account-page-content {
    background-color: $light1-color;

    .account-panel {
      background-color: $white-color;

      &-header {
        border-bottom: 1px solid $light-color;

        &__title {
          color: $dark3-color;
        }
      }

      &-body {
        .account-panel-items {
          & + .account-panel-items {
            border-top: 1px solid $light-color;
          }
        }
      }

      &-item {
        &__name {
          color: $gray-color;
        }

        &__value {
          color: $dark3-color;
        }

        &__action {
          color: $dark3-color;
        }
      }
    }

    .btn-action {
      background: $light-blue3;
    }

    .btn-download {
      background: rgba(109, 110, 113, 0.2);

      color: $dark3-color;
      .iImport {
        display: block;
        background-color: $dark3-color;
      }
    }

    .bank-item {
      &-container {
        background-color: $white-color;

        .bank-infor {
          .bank {
            &-logo {
              border: 1px solid $light-color;
            }

            &-branch {
              color: $gray-color !important;
            }

            &-user-name {
              color: $gray-color !important;
            }
          }
        }

        .delete-account {
          .icon {
            background-color: $dark3-color;
          }
        }

        .register-account {
          .btn-register-bank {
            background-color: rgba($color: $light-color, $alpha: 0.2);
          }

          color: $dark3-color;

          .icon {
            background-color: $dark3-color;
          }
        }
      }
    }

    .account-profile {
      .profile-state {
        color: $active1-color;
      }
    }
  }

  .account-page {
    background-color: $light1-color;
  }

  .trading {
    background-color: $white-color;

    &-main {
      &-header {
        background-color: $white-color;

        .trading-main-tab {
          &.active {
            color: $dark3-color;
            background-color: $active1-color;
            border-radius: 16px;
          }

          color: $gray-color;

          &:not(.active):hover {
            color: $dark3-color;
          }
        }

        &__left {
          .btn {
            border-radius: 24px;
          }
        }

        &__right {
          .icon {
            background-color: $dark3-color;
          }
        }
      }

      .trading-filters {
        background-color: $light-color;

        .input-text-search {
          background-color: $white-color;

          input {
            background-color: $white-color;
            border: 1px solid $white-color;
            color: $dark3-color;
          }
        }

        .filter-control-select {
          &__control {
            background-color: $white-color;
          }

          &__menu {
            background-color: $white-color;
          }

          &__single-value {
            color: $dark3-color;
          }

          &__option {
            color: $dark3-color;
            background-color: $white-color;

            &--is-selected {
              color: $dark3-color;
              background-color: $light2-color;
            }

            &:hover {
              background-color: $light1-color;
            }
          }

          &__placeholder {
            color: $dark3-color;
          }
        }

        .filter-datePicker {
          background-color: $white-color;
          color: $dark3-color;

          &__from,
          &__to {
            color: $dark3-color;
          }

          input {
            color: $dark3-color;
          }
        }
      }
    }

    .table {
      color: $dark3-color;

      .expand-row {
        > td {
          color: $light3-color;
        }

        td {
          th {
            background-color: $light-color;
            border: 1px solid $light1-color !important;
          }

          td {
            background-color: $light-color;
            border: 1px solid $light1-color !important;
          }
        }

        .no-hover {
          &:hover {
            td {
              background-color: $light-color !important;
            }
          }
        }
      }

      tfoot {
        tr {
          border: 1px solid $light-color;

          td {
            border: 1px solid $light-color;
          }
        }
      }
    }
  }

  .highcharts-xaxis .highcharts-axis-line {
    stroke: $light2-color;
  }

  .bond-page {
    .table-group {
      &__nav {
        background-color: $light-color;
        border-left: 2px solid $active1-color;
        .icon {
          background-color: $dark3-color;
        }
      }
    }

    .iStar {
      background-color: $active1-color;
    }

    .bond-product {
      &-type {
        color: $dark3-color;

        &:hover {
          color: $gray-color;
        }

        &.active {
          color: $gray-color;
        }
      }

      .more-infor {
        color: $dark-blue3;
        text-decoration: underline;
      }

      .bond-introduce--faq {
        border-top: 1px solid $light-color;

        &:last-of-type {
          border-bottom: 1px solid $light-color;
        }
      }

      .investor-infors {
        .line {
          border-top: 1px solid $light1-color;
        }
      }
    }

    .filter-control-select {
      &__control {
        background-color: $light-color;
      }

      &__menu {
        background-color: $light-color;
      }

      &__single-value {
        color: $dark3-color;
      }

      &__option {
        color: $dark3-color;
        background-color: $light-color;

        &--is-selected {
          color: $dark3-color;
          background-color: $light2-color;
        }

        &:hover {
          background-color: $light1-color;
        }
      }

      &__placeholder {
        color: $dark3-color;
      }
    }

    .bond-layout__filter {
      input {
        background-color: $light-color;
        color: $dark3-color !important;
        border: 1px solid $light-color;
      }
    }

    .reload-button {
      background-color: $light-color;

      .icon {
        background-color: $gray-color;
      }

      &:hover {
        background-color: $dark3-color;

        .icon {
          background-color: $light-color;
        }
      }
    }

    .tool-tip {
      .icon {
        background-color: $light-blue3;
      }
    }
  }

  .news-container {
    .filter-datePicker {
      background-color: $light-blue;
    }

    .news {
      &__img {
        background-color: $light-color !important;
      }
    }

    .body-panel {
      &--center {
        .filter-control-select {
          &__control {
            background-color: $light-blue;
          }
        }

        .input-text-search {
          background-color: $light-blue;

          input {
            background-color: $light-blue;
          }
        }
      }

      &--left {
        .input-text-search {
          background-color: $light-blue;

          input {
            background-color: $light-blue;
          }
        }

        .left {
          &-top {
            .card-index-info-item {
              border-bottom: 1px solid $light-color !important;
            }
          }

          &-bottom {
            .brief-list {
              .bief-content {
                .morning-brief {
                  color: $dark-color !important;
                }

                .market-brief {
                  background-color: rgba($dark-blue3, 0.2) !important;
                  color: $dark-blue3 !important;
                }
              }
            }
          }
        }
      }

      .event-list {
        .event-item {
          .event-time {
            .icon {
              background-color: $light3-color;
            }
          }

          .event__content {
            background-color: $light-blue;

            .content__title {
              .symbol {
                background-color: rgba($dark3-color, 0.2);

                &:hover {
                  background-color: rgba($color: $dark3-color, $alpha: 1);
                  color: $light-color !important;
                }
              }
            }
          }
        }
      }
    }

    .card-index-info {
      .card-panel {
        &-header {
          &__title {
            &.active {
              color: $dark-color !important;

              &::after {
                border-top: 1px solid $active1-color !important;
              }
            }

            &:not(.active) {
              color: $gray-color !important;

              &:hover {
                background-color: $light-color !important;
              }
            }
          }
        }
      }
    }

    .card-panel-header {
      &__title {
        &:not(.active) {
          background-color: $light-color !important;
          color: $gray-color !important;
        }

        &.active {
          color: $dark3-color !important;
        }
      }
    }
  }

  .bg-active {
    td {
      background-color: $light-blue1 !important;
    }
  }

  .planManagement-page {
    .planProgress {
      &__header {
        .packInfo {
          background-color: $light-blue1;

          &-item {
            &__name {
              color: $gray-color;
            }
          }
        }

        .planCalendar {
          background-color: $light-blue;

          &-viewPicker {
            &--left {
            }

            &--right {
              .planCalendar-viewPicker__tab {
                &.active {
                  background-color: rgba($color: $active1-color, $alpha: 0.2);
                  color: $active1-color;
                }

                &:not(.active) {
                  cursor: pointer;

                  &:hover {
                    background-color: $light-color;
                  }
                }
              }
            }
          }

          &-timeFrame {
            .list-day {
              &__item {
                &--date {
                  &:hover {
                    background-color: $dark3-color;

                    span {
                      color: $light-color !important;
                    }
                  }

                  &.disabled {
                    span {
                      color: $light1-color !important;
                    }

                    &:hover {
                      background-color: $light-color !important;

                      span {
                        color: $dark-color !important;
                      }
                    }
                  }

                  &.active {
                    span {
                      color: $active1-color !important;
                    }

                    &:hover {
                      background-color: $active1-color !important;

                      span {
                        color: $dark-color !important;
                      }
                    }
                  }
                }
              }
            }

            .list-month {
              display: flex;

              &__item {
                background-color: $white-color;
                text-align: center;

                &3 {
                  width: calc(33.33333% - 4px);
                }

                &6 {
                  width: calc(16.66666% - 4px);
                }

                &9 {
                  width: calc(10.11111% - 4px);
                }

                &12 {
                  width: calc(8.33333% - 4px);
                }
              }
            }
          }
        }
      }

      &__body {
        .planProgress-item {
          .portfolio-name {
            background-color: rgba($color: $dark3-color, $alpha: 0.2);
            color: $dark3-color;

            .icon {
              background-color: $down-color;
            }

            &--rebalance {
              background-color: rgba($color: $down-color, $alpha: 0.2);
            }
          }

          .portfolio-progress {
            background-color: $light-blue;

            .pack-progress {
              &--completed {
                background-color: rgba($color: $up1-color, $alpha: 0.2);
                color: $up1-color;
              }

              &--rebalance {
                background-color: rgba($color: $down1-color, $alpha: 0.2);
                color: $down1-color;
              }

              &--running {
                background-color: rgba($color: $light-blue3, $alpha: 0.2);
                color: $light-blue3;
              }
            }
          }

          &__buffer {
            &--completed {
              background-color: rgba($color: $up1-color, $alpha: 0.2);
              color: $up1-color;
            }

            &--rebalance {
              background-color: rgba($color: $down1-color, $alpha: 0.2);
              color: $down1-color;
            }

            &--running {
              background-color: rgba($color: $light-blue3, $alpha: 0.2);
              color: $light-blue3;
            }
          }
        }
      }
    }
  }

  .pinefolio-order-list {
    .table {
      thead {
        th {
          background-color: $white-color;
        }
      }

      tr {
        &:nth-child(even) {
          &.expand-row {
            background-color: $white-color !important;
            border: 1px solid $light-color;

            .text--light {
              color: $light-color;
            }

            > td {
              color: $light3-color;
            }

            td {
              th {
                background-color: $white-color !important;
                border: 1px solid $light-color !important;
              }

              td {
                background-color: $white-color !important;
                border: 1px solid $light-color !important;

                th,
                td {
                  background-color: $light-color !important;
                  border: 1px solid $light1-color !important;
                }
              }
            }

            .no-hover {
              &:hover {
                background-color: unset !important;
              }
            }
          }
        }

        &:nth-child(odd) {
          &.expand-row {
            background-color: $light-blue !important;
            border: 1px solid $light-color;

            .text--light {
              color: $light-color;
            }

            > td {
              color: $light3-color;
            }

            td {
              th {
                background-color: $white-color !important;
                border: 1px solid $light-color !important;
              }

              td {
                background-color: $white-color !important;
                border: 1px solid $light-color !important;

                th,
                td {
                  background-color: $light-color !important;
                  border: 1px solid $light1-color !important;
                }
              }
            }

            .no-hover {
              &:hover {
                background-color: unset !important;
              }
            }
          }
        }
      }
    }

    .expand-row {
      background-color: $light-color !important;
      border: 1px solid $light-color;

      .text--light {
        color: $light-color;
      }

      > td {
        color: $light3-color;
      }

      td {
        th {
          background-color: $white-color !important;
          border: 1px solid $light-color !important;
        }

        td {
          background-color: $white-color !important;
          border: 1px solid $light-color !important;
        }
      }

      .no-hover {
        &:hover {
          background-color: unset !important;
        }
      }
    }

    .expand-row--lv2 {
      background-color: $white-color !important;

      .text--light {
        color: $light-color;
      }

      > td {
        color: $light3-color;
      }

      td {
        th {
          background-color: $light-color !important;
          border: 1px solid $light1-color !important;
        }

        td {
          background-color: $light-color !important;
          border: 1px solid $light1-color !important;
        }
      }

      .no-hover {
        &:hover {
          background-color: $white-color !important;
        }
      }
    }
  }

  .layout-page {
    .input-text-search {
      background-color: $light-color;
      color: $dark3-color;
      border-radius: 16px;

      input {
        background-color: $light-color;
        color: $dark3-color;
        border: 1px solid $light-color;
      }
    }

    .filter-control-select {
      &__control {
        background-color: $light-color;
      }

      &__menu {
        background-color: $light-color;
      }

      &__single-value {
        color: $dark3-color;
      }

      &__option {
        color: $dark3-color;
        background-color: $light-color;

        &--is-selected {
          color: $dark3-color;
          background-color: $light2-color;
        }

        &:hover {
          background-color: $light1-color;
        }
      }

      &__placeholder {
        color: $dark3-color;
      }
    }

    .filter-datePicker {
      background-color: $light-color;

      &__from,
      &__to {
        color: $dark3-color;
      }

      input {
        background-color: transparent;
        color: $dark3-color;
      }
    }

    .filter-control-calendar {
      .react-datepicker {
        &__input-container {
          input {
            background-color: $light-color;
            color: $dark-color;
          }
        }
      }

      .icon {
        background-color: $gray-color;
      }
    }
  }

  .pinefolio-product {
    &-body {
      .pinefolio-cards {
        .pinefolio-card {
          .theme {
            background-color: rgba($light-blue, 1);

            &-header {
              &__name {
                color: $light-color;
              }
            }
          }
        }
      }
    }
  }

  .portfolio-section {
    &__body {
      .expand-row {
        .bg-dark3 {
          background-color: $light-color !important;
        }

        background-color: $light-color !important;
        border: 1px solid $light-color;

        .text--light {
          color: $light-color;
        }

        > td {
          color: $light3-color;
        }

        td {
          th {
            background-color: $white-color !important;
            border: 1px solid $light-color !important;
          }

          td {
            background-color: $white-color !important;
            border: 1px solid $light-color !important;
          }
        }

        .no-hover {
          &:hover {
            background-color: unset !important;
          }
        }
      }
    }

    &.stockout-section {
      .portfolio-section__body {
        table {
          thead {
            tr {
              td {
                border-right: 1px solid $light-color;
              }
            }
          }
        }
      }
    }
  }

  .pinefolio-page {
    .reload-button {
      background-color: rgba($light3-color, 0.2);

      &:hover {
        background-color: $light3-color;

        .icon {
          background-color: $light-color;
        }
      }
    }

    .btn {
      &--transparent {
        background-color: transparent;
        color: $light1-color;

        &:hover {
          background-color: $dark3-color !important;
          color: $light1-color;

          .icon {
            background-color: $light1-color;
          }
        }
      }
    }
  }

  .iTooltip {
    background-color: $light-blue3 !important;
  }

  .analysis-report {
    .bg--active {
      background-color: $active1-color !important;
    }

    .event-list {
      .event-item {
        .event-time {
          .icon {
            background-color: $light3-color;
          }
        }

        .event__content {
          background-color: $light-blue;

          .content__title {
            .symbol {
              background-color: rgba($dark3-color, 0.2);

              &:hover {
                background-color: rgba($color: $dark3-color, $alpha: 1);
                color: $light-color !important;
              }
            }
          }
        }
      }
    }
  }

  .margin-competition {
    .general-intro {
      .wrapper {
        .banner {
          .organizer {
            &__logo {
              display: flex;
              align-items: end;
              gap: 15px;

              .iLogo {
                height: 32px;
                width: 84px;
              }

              .iCafeF {
                height: 24px;
                width: 107px;
              }
            }
          }

          .title {
            .iMrBanner {
              background-image: linear-gradient(
                338deg,
                #2db25b 0%,
                #f0ae03 100%
              );
            }
          }

          .overview {
            .overview-item {
              .icon {
                background-color: $light-blue3;
              }
            }
          }
        }

        .content {
          color: $light3-color;
        }

        .round-infor {
          .round {
            border: 1px solid $light1-color;

            #time-remain {
              color: $active1-color;
            }

            &.current-round {
              .b-dashed {
                border: 1px dashed !important;
              }

              .b-gray {
                border-color: $gray-color;
              }
            }

            .btn {
              color: $gray-color;
            }
          }

          .badge {
            background-color: $light-color;
            color: $gray-color;
          }
        }

        .form-register {
          background-color: $light-blue;
        }
      }
    }

    .rule-award {
      .award {
        .super-prize {
          background-color: $light-blue;

          &::before {
            background: linear-gradient(
              to left,
              rgba(240, 174, 3, 0) 0%,
              #f0ae03 50.52%,
              rgba(240, 174, 3, 0) 100%
            );
          }

          &::after {
            background: linear-gradient(
              to left,
              rgba(240, 174, 3, 0) 0%,
              #f0ae03 50.52%,
              rgba(240, 174, 3, 0) 100%
            );
          }

          .iPrize2 {
            background-color: $active1-color;
          }
        }

        .groups-prize {
          .group-prize {
            background-color: $light-blue;

            .prize-infor {
              div {
                position: relative;

                &:first-child() {
                  &::before {
                    background: linear-gradient(
                      to left,
                      rgba(240, 174, 3, 0) 0%,
                      #f0ae03 50.52%,
                      rgba(240, 174, 3, 0) 100%
                    );
                  }
                }

                &::after {
                  background: linear-gradient(
                    to left,
                    rgba(240, 174, 3, 0) 0%,
                    #f0ae03 50.52%,
                    rgba(240, 174, 3, 0) 100%
                  );
                }
              }
            }

            .group-infor {
              .icon {
                background-color: $light-color;
              }
            }
          }
        }
      }

      .other-endow {
        .endow-item {
          background-color: $light-blue;
          color: $gray-color;
        }
      }

      .text-title {
        color: $dark3-color;
      }
    }

    .leader-board {
      &__body {
        .round-tab {
          color: $light3-color;
          .active {
            color: $dark3-color;
          }
        }

        .groups-rank {
          &__item {
            .group-infor {
              .icon {
                background-color: $light-color;
              }
            }
            .group-board {
              table {
                tbody {
                  tr:nth-child(1),
                  tr:nth-child(2),
                  tr:nth-child(3),
                  tr:nth-child(4) {
                    &::before {
                      background: linear-gradient(
                        to left,
                        rgba(240, 174, 3, 0) 0%,
                        #f0ae03 50.52%,
                        rgba(240, 174, 3, 0) 100%
                      );
                    }
                  }
                  tr:nth-child(1),
                  tr:nth-child(2),
                  tr:nth-child(3) {
                    .stock-rank {
                      background-color: rgba(
                        $color: $active1-color,
                        $alpha: 0.2
                      );
                    }
                  }
                }
              }
              .iArrowUp {
                background-color: $up1-color;
              }
              .iArrowDown {
                background-color: $down1-color;
              }
            }
          }
        }

        .top-trading {
          .list-symbol {
            .stock-infor {
              .stock-symbol {
                background-color: rgba($color: $gray-color, $alpha: 0.2);
              }
              &:nth-child(1),
              &:nth-child(2),
              &:nth-child(3) {
                .stock-symbol {
                  background-color: rgba($color: $active1-color, $alpha: 0.2);
                }
              }
            }
          }
        }
      }

      .stock-rank {
        background-color: rgba($color: $light-color, $alpha: 1);
      }
    }

    .performance {
      .performance {
        &__body {
          &--left {
            .performance-chart {
              .highcharts-background {
                fill: transparent;
              }

              .highcharts-yaxis-grid {
                .highcharts-grid-line {
                  stroke: $light-color;
                }
              }

              .chart-explain {
                .chart-tab {
                  .active {
                    color: $dark3-color;
                  }
                }

                .chart-metrics {
                  .metric__value {
                    &.investment-effect {
                      color: $up1-color;
                      .circle {
                        background-color: $up1-color;
                      }
                    }
                    &.index {
                      color: $gray-color;
                      .circle {
                        background-color: $gray-color;
                      }
                    }
                  }
                }
              }
            }
            .performance-warning {
              background-color: rgba($color: $down1-color, $alpha: 0.2);
              color: $down1-color;
              .iWarning {
                background-color: $down1-color;
              }
            }
            .performance-detail {
              .detail-item {
                .iArrowUp {
                  background-color: $up1-color;
                }
                .iArrowDown {
                  background-color: $down1-color;
                }
                .group-icon {
                  background-color: $light-color;
                }
              }
            }
          }
          .horizon-y {
            border-left: 1px solid $light1-color;
          }
        }
      }
    }
  }

  .news-tab {
    .news {
      &__title {
        color: $dark3-color;
      }
    }
  }

  &.derivative {
    .divider {
      border-top: 1px solid $light-color;
    }

    table {
      border: 1px solid $light-blue1;
      background-color: $white-color;
    }

    .popup-fee-tax {
      background-color: $white-color;
    }

    thead {
      tr {
        background-color: $light-blue1;
      }
    }

    .iInfor {
      background-color: $light-blue3;
    }

    tbody {
      tr:nth-child(even) {
        background-color: $light-color;
      }
    }

    .warning-threshold {
      background-color: rgba($color: $dark3-color, $alpha: 0.2);
      .current-ratio {
        background-color: $up1-color;
      }
      .warning-ratio {
        background-color: $ref1-color;
      }
      .forcesell-ratio {
        background-color: $down-color;
      }
    }

    .warning-explain {
      .current-ratio {
        .square {
          background-color: $up1-color;
        }
      }

      .warning-ratio {
        .square {
          background-color: $ref1-color;
        }
      }

      .forcesell-ratio {
        .square {
          background-color: $down1-color;
        }
      }
    }

    .infor-group {
      width: 100%;

      &:not(:last-child) {
        border-bottom: 1px solid $dark2-color;
      }

      .infor-item {
        &--label {
          color: $light3-color;
        }

        &--value {
          color: $light-color;
        }
      }
    }

    .expand-row {
      table {
        th,
        td {
          background-color: $light-color;
          border: 1px solid $light1-color;
        }
      }
    }
  }

  .derivative-order {
    // .btn--dark {
    //   &:not(.active) {
    //     background-color: $light-color;
    //     color: $gray-color;
    //   }
    // }
  }

  .derivative_top-index {
    .top-index__item {
      background-color: rgba($color: $light-color, $alpha: 0.2);
    }
  }
}

.modal-open {
  @extend %text;

  .light {
    @extend %common;

    background-color: $white-color;
    color: $dark3-color;

    .icon {
      background-color: $dark3-color;
    }

    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
      background-color: $light-color !important;
      color: $dark3-color !important;
      -webkit-box-shadow: 0 0 0 30px $light-color inset !important;
      -webkit-text-fill-color: $dark3-color !important;
    }

    .header__tabs {
      background-color: $light-color;

      .header__tab {
        &.active {
          background-color: $active1-color;
          color: $dark3-color;
        }
      }
    }

    &.modal-content {
      box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.16);

      .confirm-order {
        &-header {
          background-color: $light-color;
        }

        &-body {
          background-color: $white-color;

          &__infor {
            &-title {
              color: $gray-color;
            }

            &-value {
              color: $dark3-color;
            }
          }

          &__detail {
            input {
              background-color: $white-color;
            }
          }
        }
      }

      .delete-order {
        &-header {
          background-color: $light-color;
          color: $dark3-color;
        }

        &-body {
          background-color: $white-color;

          &__infor {
            &-title {
              color: $gray-color;
            }

            &-value {
              color: $dark3-color;
            }
          }
        }
      }

      .modal-form {
        .modal-header {
          background-color: $light-color !important;
          color: $dark3-color;

          .icon {
            background-color: $dark3-color;
          }
        }

        .filter-control-select {
          &__control {
            background-color: $light-color;
          }

          &__menu {
            background-color: $light-color;
          }

          &__single-value {
            color: $dark3-color;
          }

          &__option {
            color: $dark3-color;
            background-color: $light-color;

            &--is-selected {
              color: $dark3-color;
              background-color: $light2-color;
            }

            &:hover {
              background-color: $light1-color;
            }
          }

          &__placeholder {
            color: $dark3-color;
          }
        }

        .modal-body {
          background-color: $white-color;

          .input-container {
            .label {
              color: $gray-color;
            }

            .icon {
              background-color: $dark3-color;
            }

            input {
              background-color: $light-color;
              color: $dark3-color;
              border: 1px solid $light-color;
            }
          }

          .body-right {
            &--content {
              background-color: $light-color;

              span {
                color: $active1-color;
              }

              li {
                color: $gray-color;
              }
            }
          }
        }
      }
    }

    .login-logo {
      background-color: $dark-blue3;
    }

    &.address-change-content {
      background-color: $white-color;

      .title {
        color: $dark3-color;
      }

      .change-form {
        .order-text {
          background-color: $light-color;

          input {
            background-color: $light-color;
            color: $dark3-color;
          }

          .icon {
            background-color: $gray-color;
          }
        }

        .file {
          background-color: $light-color;

          &--upload {
            background-color: rgba($light-blue3, 0.2);
            color: $light-blue3;
          }
        }
      }

      .desc {
        color: $gray-color;
      }

      .profile-select {
        &__control {
          background-color: $light-color;
        }

        &__menu {
          background-color: $light-color;
        }

        &__single-value {
          color: $dark3-color;
        }

        &__option {
          color: $dark3-color;
          background-color: $light-color;

          &--is-selected {
            color: $dark3-color;
            background-color: $light2-color;
          }

          &:hover {
            background-color: $light1-color;
          }
        }

        &__placeholder {
          color: $dark3-color;
        }
      }

      .account-panel {
        &-item {
          &__name {
            color: $gray-color;
          }

          &__value {
            color: $dark3-color;
          }
        }
      }

      .profile-image {
        &-desc {
          color: $gray-color;
        }
      }

      .help {
        color: $dark3-color;

        a {
          color: $active1-color;
        }
      }
    }

    .authen-section {
      border: 1px solid $light-color;

      .authen-type-switches {
        background-color: $light1-color;

        .authen-type-switch {
          color: $gray-color;

          &:not(.active):hover {
            color: $active1-color;
          }
        }

        .active {
          background-color: $active1-color;
          color: $dark3-color;
        }
      }

      .otp-section {
        input {
          background-color: rgba($color: $dark3-color, $alpha: 0.2);
          color: $dark3-color;
        }
      }

      .matrix-section {
        input {
          background-color: rgba($color: $dark3-color, $alpha: 0.2);
          color: $dark3-color;
        }
      }
    }

    &.login-modal-content {
      .text-title {
        color: $dark3-color;
      }

      .btn-forgot-password {
        color: $gray-color;
      }

      .iLogo {
        background-color: $dark-blue3;
      }

      .login-form {
        .order-text {
          background-color: $light-color !important;

          input {
            background-color: $light-color !important;
            color: $dark3-color;
          }

          input:-webkit-autofill,
          input:-webkit-autofill:hover,
          input:-webkit-autofill:focus,
          input:-webkit-autofill:active {
            background-color: $light-color !important;
            color: $light3-color !important;
            -webkit-box-shadow: 0 0 0 30px $light-color inset !important;
            -webkit-text-fill-color: $dark-color !important;
          }
        }

        .hint-password {
          li {
            color: $gray-color;
          }

          .active > span {
            color: $up1-color !important;
          }

          .hint-content {
            color: $ref1-color;
          }
        }
      }

      .first-login-form {
        .hint-password {
          li {
            color: $gray-color;
          }

          .active > span {
            color: $up1-color !important;
          }

          .hint-content {
            color: $ref1-color;
          }
        }
        .order-text {
          background-color: $light-color;

          input {
            color: $dark3-color;
            background-color: $light-color;
          }
        }
      }

      .feedback-form {
        .order-text {
          &--transparent {
            background-color: transparent !important;
          }

          textarea {
            color: $dark3-color;
            background: $light-color !important;
          }
        }
      }
    }

    &.notifications-content-v2 {
      color: $dark3-color;
      .header {
        background-color: $light-color;
        color: $dark3-color;
        .right:hover {
          cursor: pointer;
          background-color: $dark3-color;
        }
      }
      .content {
        background-color: $white-color;
        .description {
          color: $gray-color;
        }
      }

      .action {
        background-color: $white-color;
        .btn-primary {
          border-radius: 4px;
          background: $light-blue3;
          border-color: $light-blue3;
        }

        .btn-primary:hover {
          background: $dark-blue3;
          border-color: $dark-blue3;
        }

        .btn-secondary {
          border-radius: 4px;
          border-color: $light-color;
          background: var(--Opacity-Dark-3-20, rgba(59, 61, 64, 0.2));
          span {
            color: $dark3-color;
          }
        }

        .btn-secondary:hover {
          background: $gray-color;
          border-color: $light-color;
          span {
            color: $light-color;
          }
        }
      }

      .pagination {
        .label {
          background: var(--Opacity-Dark-3-20, rgba(59, 61, 64, 0.2));
          color: $dark3-color;
        }

        button {
          border-color: $light-color;
          color: $dark3-color;
          background: var(--Opacity-Dark-3-20, rgba(59, 61, 64, 0.2));
          span {
            background-color: $dark3-color;
          }
        }

        button:hover {
          background: $gray-color;
          border-color: $light-color;
          color: $light-color;
          span {
            background-color: $light-color;
          }
          // background-color: $dark3-color;
        }

        button:disabled,
        button[disabled] {
          cursor: not-allowed;
          border-radius: 4px;
          background: var(--Opacity-Light-20, rgba(242, 242, 243, 0.2));
          border-color: $light-color;
          span {
            background-color: $light-color;
          }
        }
      }
    }

    &.auth-content {
      background-color: $white-color;

      .iLogo {
        background-color: $dark-blue3;
      }

      .text-title {
        color: $dark3-color;
      }

      .text-desc {
        color: $gray-color;
      }

      .text-otp {
        background-color: rgba($color: $dark3-color, $alpha: 0.2);
        color: $dark3-color;

        &:focus-visible {
          border: 1px solid $down-color;
        }
      }

      .text-resend {
        color: $dark3-color;

        .icon {
          background-color: $dark3-color;
        }
      }

      .text-refresh {
        color: $dark3-color;

        .icon {
          background-color: $dark3-color;
        }
      }

      .list-group {
        .list-group-item {
          background-color: $light-color !important;
        }
      }

      .loading-backdrop {
        border: solid 4px rgba($dark3-color, 0.2);

        .circle-loading {
          border: solid 4px $dark3-color;
          border-right-color: transparent;
          border-bottom-color: transparent;
          border-left-color: transparent;
        }
      }
    }

    &.market-index-modal {
      .market-index-modal__header {
        background-color: $light-color;
        color: $dark3-color;
      }

      .market-index-modal__body {
        background-color: $light-color;
      }

      .icon {
        &:hover {
          background-color: $dark3-color;
        }
      }
    }

    &.changeBank-modal {
      .filter-control-select {
        &__control {
          background-color: $light-color;
          border: none;
        }

        &__menu {
          background-color: $light-color;
        }

        &__single-value {
          color: $dark3-color;
        }

        &__option {
          color: $dark3-color;
          background-color: $light-color;
          border-bottom: 1px solid $light1-color;

          &--is-selected {
            color: $dark3-color;
            background-color: $light2-color;
          }

          &:hover {
            background-color: $light1-color;
          }
        }

        &__placeholder {
          color: $dark3-color;
        }
      }

      .form-confirm-bank {
        .form-label {
          color: $gray-color;
        }
      }

      .input-with-icon {
        background-color: $light-color;

        .icon {
          background-color: $light3-color;
        }

        .form-control {
          background-color: $light-color;
          color: $dark3-color;
        }
      }

      ::-webkit-scrollbar {
        background-color: $light2-color;
      }

      /* Handle */
      ::-webkit-scrollbar-thumb {
        background-color: $light3-color;
      }

      /* Handle on hover */
      ::-webkit-scrollbar-thumb:hover {
        background: #888;
      }
    }

    &.reset-pass-content {
      .icon {
        background-color: $light3-color;
      }

      .btn-forgot-password {
        background-color: transparent;
        color: $light3-color;
      }

      .btn-open-account {
        border: 1px solid $light-blue3;
        color: $light-blue3;
      }

      .reset-pass-form {
        .order-text {
          background-color: $light-color;

          input {
            color: $dark3-color;
            background-color: $light-color;
          }

          input:-webkit-autofill,
          input:-webkit-autofill:hover,
          input:-webkit-autofill:focus,
          input:-webkit-autofill:active {
            background-color: $dark-color !important;
            color: $light-color !important;
            -webkit-box-shadow: 0 0 0 30px $dark-color inset !important;
            -webkit-text-fill-color: $light-color !important;
          }
        }
      }
    }

    /* notice detail */

    &.noti-detail-content {
      .icon {
        background-color: $light3-color;
      }

      .noti-detail {
        background-color: $white-color;

        .text-title {
          color: $dark3-color;
        }

        .text-desc {
          color: $gray-color;
        }

        .text-time {
          color: $gray-color;
        }
      }

      background-color: $white-color;
    }

    /* end notice detail */

    &.bond-detail {
      .bond-contract {
        background-color: $white-color;

        .bond-contract-steps {
          border: 1px solid $light-color;

          .bond-contract-rec {
            background-color: $light-color;
          }

          .bond-contract-step {
            &:hover {
              background-color: $light1-color;
            }

            + .bond-contract-step {
              border-top: 1px solid $light3-color;
            }
          }
        }

        .bond-contract-preview {
          &__body {
            background-color: $light-color;
          }
        }

        .cbb-box {
          border: 1px solid $light3-color !important;

          &::after {
            border: 2px solid $up1-color;
            border-top: 0;
            border-right: 0;
          }
        }
      }

      .bond-detail-order {
        &__infor {
          border: 1px solid $light-color;
        }

        .order-infor {
          &__title {
            color: $gray-color;
          }

          &__desc {
            color: $dark3-color;
          }
        }
      }

      .bond-detail-panel {
        .bond-detail-body {
          .bond-demo-overview {
            .bond-chart {
              .chart-content {
                .highcharts-axis-labels {
                  text {
                    color: $dark3-color !important;
                    fill: $dark3-color !important;
                  }
                }
              }
            }
          }
        }
      }

      .modal-close {
        background-color: $white-color;

        .icon {
          background-color: $dark3-color;
        }
      }

      .purchase-date {
        background-color: $light-color !important;
        color: $gray-color;
      }

      .filter-control-calendar {
        .react-datepicker {
          &__input-container {
            input {
              background-color: $light-color;
              color: $dark-color;
            }
          }
        }

        .icon {
          background-color: $gray-color;
        }
      }

      .filter-control-select {
        &__control {
          background-color: $light-color;
        }

        &__menu {
          background-color: $light-color;
        }

        &__single-value {
          color: $dark3-color;
        }

        &__option {
          color: $dark3-color;
          background-color: $light-color;

          &--is-selected {
            color: $dark3-color;
            background-color: $light2-color;
          }

          &:hover {
            background-color: $light1-color;
          }
        }

        &__placeholder {
          color: $dark3-color;
        }
      }

      .highcharts-background {
        fill: $white-color !important;
      }

      .highcharts-axis-labels {
        text {
          color: $dark3-color !important;
          fill: $dark3-color !important;
        }
      }

      .highcharts-grid-line {
        stroke: $light1-color !important;
      }

      .chart-content {
        border: 1px solid $light1-color !important;
      }

      input {
        &:disabled {
          background-color: rgba($color: $light-color, $alpha: 0.7);
          color: $dark3-color;
        }
      }
    }

    &.bond-notification {
      background-color: $white-color !important;

      .bond-notification {
        &__header {
          background-color: $light-color;
        }

        &__body {
          background-color: $white-color;

          .bond-notification__logo {
            &--up {
              background-color: rgba($color: $up1-color, $alpha: 0.2);

              .icon {
                background-color: $up1-color;
              }
            }

            &--down {
              background-color: rgba($color: $down1-color, $alpha: 0.2);

              .icon {
                background-color: $down1-color;
              }
            }

            &--r {
              background-color: rgba($color: $active1-color, $alpha: 0.2);

              .icon {
                background-color: $active1-color;
              }
            }

            &--blue3 {
              background-color: rgba($color: $light-blue3, $alpha: 0.2);
            }
          }
        }

        &__footer {
          background-color: $white-color;
        }
      }
    }

    .tool-tip {
      .icon {
        background-color: $light-blue3;
      }
    }

    &.custom-modal {
      .popup-header {
        background-color: $light-color;

        &__title {
          color: $dark3-color;
        }

        .icon {
          background-color: $dark3-color;
        }
      }

      .popup-body {
        background-color: $white-color;

        &__icon {
          &.icon--notificaton {
            background-color: rgba($color: $active1-color, $alpha: 0.2);

            .icon {
              background-color: $active1-color;
            }
          }

          &.icon--warning {
            background-color: rgba($color: $down1-color, $alpha: 0.2);

            .icon {
              background-color: $down1-color;
            }
          }

          &.icon--success {
            background-color: rgba($color: $up1-color, $alpha: 0.2);

            .icon {
              background-color: $up1-color;
            }
          }
        }
      }
    }

    .depositToPortfolio-modal {
      .modal {
        &-body {
          .total-assets {
            border-bottom: 1px dashed $light1-color;
          }

          .modal-body__rebalanceInfor {
            width: 100%;

            table {
              width: 100%;

              thead {
                tr {
                  td {
                    border-right: 12px solid $white-color;

                    .iIn {
                      background-color: $up1-color;
                    }

                    .iOut {
                      background-color: $down1-color;
                    }
                  }
                }
              }

              tbody {
                tr {
                  td {
                    border-top: 1px dashed $light-color;
                    border-right: 12px solid $white-color;
                  }
                }
              }
            }
          }
        }

        &--footer {
          background-color: $white-color;
        }
      }
    }

    .edit-plan {
      .filter-control-calendar {
        .react-datepicker {
          &__input-container {
            input {
              background-color: $light-color;
              color: $dark-color;
            }
          }
        }

        .icon {
          background-color: $gray-color;
        }
      }

      &__header {
        border-bottom: 1px solid $light1-color;

        .btn--icon {
          .icon {
            background-color: $dark3-color;
            cursor: pointer;
          }

          &:hover {
            background-color: $dark3-color;

            .icon {
              background-color: $light1-color;
            }
          }
        }

        &--right {
          tr {
            td {
              border: 1px solid $light-color;
            }
          }
        }
      }

      &__body {
        .pack-absolute {
          .pack-relative {
            .next-pack {
              background-color: rgba($color: $dark3-color, $alpha: 0.2);

              &::before {
                border-left: 1px dashed $light3-color;
              }

              &::after {
                border-left: 1px dashed $light3-color;
              }

              .icon {
                background-color: $gray-color;
              }
            }

            .pack-detail {
              .viewPlan {
                &-setting {
                  .input-text-search {
                    input {
                      background-color: #f2f2f3;
                      color: #3b3d40;
                      border: 1px solid #f2f2f3;
                    }
                  }

                  .filter-control-select {
                    &__control {
                      background-color: $light-color;
                    }

                    &__menu {
                      background-color: $light-color;
                    }

                    &__single-value {
                      color: $dark3-color;
                    }

                    &__option {
                      color: $dark3-color;
                      background-color: $light-color;

                      &--is-selected {
                        color: $dark3-color;
                        background-color: $light2-color;
                      }

                      &:hover {
                        background-color: $light1-color;
                      }
                    }

                    &__placeholder {
                      color: $dark3-color;
                    }
                  }

                  &__item {
                    &--name {
                      color: $gray-color;
                    }

                    &--value {
                    }
                  }
                }

                &-step {
                  .input-text-search {
                    input {
                      background-color: $light-color;
                      color: $dark-color;

                      &::placeholder {
                        color: $light3-color;
                        opacity: 1;
                      }
                    }
                  }

                  tr {
                    &:not(:first-child) {
                      .step-num {
                        span {
                          color: $up1-color;
                        }

                        &::before {
                          border-top: 8px solid $light1-color;
                        }

                        &::after {
                          border-top: 2px solid $light1-color;
                        }
                      }
                    }

                    &:first-child {
                      .step-num {
                        &::before {
                          border-top: 2px solid $light1-color;
                        }

                        &::after {
                          border-top: 2px solid $light1-color;
                        }
                      }
                    }
                  }

                  .step-num {
                    background-color: rgba($color: $up1-color, $alpha: 0.2);
                    border: 1px solid $white-color;
                    color: $up1-color;

                    &--edit {
                      background-color: rgba($color: $dark-blue3, $alpha: 0.2);
                      color: $dark-blue3;
                      border: 1px solid $white-color;
                    }

                    span {
                      color: $up1-color;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    .wts-modal {
      &__header {
        background-color: $light-color;
        color: $dark3-color;
      }
    }

    .wts-modal2 {
      &__header {
        background-color: $light-color;
      }
    }

    .sellPortfolio-modal {
      &__body {
        .order-type {
          .order-type-switches {
            background-color: $light-color;
            color: $dark-color;

            .order-type-switch {
              &.active {
                background-color: $active1-color;
              }
            }
          }
        }

        .product-info {
          &__infors {
            border-bottom: 1px dashed $light1-color;
          }

          &__order {
            .infor-row {
              .order-quantity {
                .input-text-search {
                  background-color: $light-color;
                  color: $dark3-color;
                }
              }
            }
          }
        }

        .odd-order {
          .price-list-step {
            background-color: $light-blue;

            .text--light1 {
              color: $dark3-color;
            }

            &__buy {
              background-color: $light-blue;
            }

            &__sell {
              background-color: $light-blue;
            }
          }

          .info-section {
            .enter-section {
              .order-text {
                background-color: $light-color;

                .filter-control-select {
                  &__control {
                    background-color: $light-color;
                  }

                  &__menu {
                    background-color: $light-color;
                  }

                  &__single-value {
                    color: $dark3-color;
                  }

                  &__option {
                    color: $dark3-color;
                    background-color: $light-color;

                    &--is-selected {
                      color: $dark3-color;
                      background-color: $light2-color;
                    }

                    &:hover {
                      background-color: $light1-color;
                    }
                  }

                  &__placeholder {
                    color: $dark3-color;
                  }
                }
              }
            }
          }
        }
      }
    }

    .pinefolio-buy {
      &__body {
        .order-main {
          .product-type {
            border-bottom: 1px dashed $light1-color;

            .pinefolio-level {
              &.active {
                background-color: $dark-blue3;
                color: $light-color;
              }
            }
          }

          .product-info {
            &__infors {
              border-bottom: 1px dashed $light1-color;
            }

            &__order {
              .infor-row {
                &__value {
                  .order-quantity {
                    .input-text-search {
                      background-color: $light-color;
                      color: $dark3-color;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    .pinefolio-sell {
      &__body {
        .order-main {
          .product-type {
            .pinefolio-level {
              &.active {
                background-color: $dark-blue3;
                color: $light-color;
              }
            }
          }

          .product-info {
            &__infors {
              border-bottom: 1px dashed $light1-color;
            }
          }

          .product-info {
            &__order {
              .infor-row {
                &__value {
                  .order-quantity {
                    .input-text-search {
                      background-color: $light-color;
                      color: $dark3-color;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    .pinefolio-order-confirm {
      &__orders {
        .pinefolio-order-confirm__order {
          border: 1px solid $light1-color;
        }

        .pinefolio-order-confirm__order--title {
          background-color: $light-color;
        }

        .contract {
          .contract__name {
            &.active {
              color: $active1-color;
            }
          }

          &:hover {
            background-color: $light1-color;
          }
        }
      }
    }

    &.pinefolio-theme-detail {
      .pinefolio-navs {
        border-bottom: 1px dashed $light1-color;

        .pinefolio-level {
          &.active {
            background-color: rgba($color: $dark-blue3, $alpha: 1);
            color: $light-color;
          }
        }
      }

      .pinefolio-overview {
        border-bottom: 1px dashed $light1-color;
      }

      .pinefolio-theme-detail {
        &__body {
          .theme-intro {
            .intro-tabs {
              .intro-tab {
                &.active {
                  &::after {
                    border-bottom: 2px solid $active1-color;
                  }
                }
              }
            }
          }
        }
      }
    }

    &.pinefolio-open-account,
    &.pinefolio-rebalance-contract {
      .pinefolio-rebalance-contract__right,
      .pinefolio-open-account__right {
        background-color: $light-color;

        .rpv-core__viewer {
          border: 1px solid $light1-color !important;
        }
      }
    }

    &.pinefolio-sell {
      .pinefolio-sell__body {
        .order-main {
          .product-type {
            border-bottom: 1px dashed $light1-color;
          }

          .product-info {
            &__infors {
              border-bottom: 1px dashed $light1-color;
            }
          }
        }
      }
    }

    .pinefolio-rebalance {
      &__body {
        .rebalance-content {
          &__infors {
            .rebalance-content__infor + .rebalance-content__infor {
              border-top: 1px dashed $light1-color;
            }
          }
        }
      }
    }

    .pinefolio-icash-transfer {
      &__select {
        &__control {
          background-color: $light-color;
        }

        &__menu {
          background-color: $light-color;
        }

        &__single-value {
          color: $dark3-color;
        }

        &__option {
          color: $dark3-color;
          background-color: $light1-color;

          &--is-selected {
            color: $dark3-color;
            background-color: $light2-color;
          }

          &:hover {
            background-color: $light3-color;
          }
        }

        &__value-container,
        &__indicator-separator {
          display: none;
        }

        &__placeholder {
          color: $light-color;
        }
      }

      .transfer-value {
        .input-text-search {
          background-color: $light-color;
          color: $dark3-color;
        }
      }
    }

    &.pinefolio-performance {
      background-color: $white-color !important;

      .pinefolio-performance__header {
        .pinefolio-levels {
          .pinefolio-level {
            &.active {
              background-color: $dark-blue3;
              color: $light-color;
            }
          }
        }
      }

      .pinefolio-performance__body {
        .chart-explain {
          .chart-explain__navigations {
            .chart-explain__navigation {
              &.active {
                background-color: rgba($color: $active1-color, $alpha: 0.2);
                color: $active1-color;
              }

              &:hover {
                background-color: rgba($dark3-color, 1) !important;
                color: $light-color;
              }
            }
          }
        }

        .performance-index {
          background-color: $light-color;
        }
      }
    }

    .iTooltip {
      background-color: $light-blue3 !important;
    }

    &.plan-modal {
      .bg--light {
        background-color: $dark3-color !important;
      }

      .break-line {
        &--verticle {
          border: 1px dashed $light1-color;
        }
      }

      .plan-modal {
        &__header {
          border-bottom: 1px solid $light1-color;

          &--left {
            .btn--icon {
              .icon {
                background-color: $dark3-color;
              }
            }
          }

          &--right {
            .table-interest-rate {
              border: 1px solid $light1-color;
            }
          }
        }

        &__body {
          &--left {
            .pf-infor {
              border-bottom: 1px dashed $light1-color;
            }
          }

          &--right {
            .bond-for-sell {
              border-bottom: 1px dashed $light1-color;
            }
          }

          .filter-control-calendar {
            .react-datepicker {
              &__input-container {
                input {
                  background-color: $light-color;
                  color: $dark-color;
                }
              }
            }

            .icon {
              background-color: $gray-color;
            }

            &--readOnly {
              .react-datepicker {
                &__input-container {
                  input {
                    color: $gray-color;
                  }
                }
              }
            }
          }
        }
      }

      .buy-now-layout {
        background-color: $white-color;

        &__header {
          background-color: $light-color;
        }

        .muti-select-box {
          .checkbox-button {
            &.active {
              .cbb-box {
                background-color: $active1-color;
              }
            }

            .cbb-box {
              background-color: $white-color;
              border: 2px solid $light2-color;
            }
          }
        }
      }

      &.pinefolio-change-plan-ticket {
        background-color: $white-color !important;

        .header {
          border-bottom: 1px solid $light1-color;
        }

        .rpv-core__viewer {
          border: 1px solid $light-color !important;
          background-color: $light-color !important;
        }
      }
    }

    &.margin-extending-modal {
      .subAccount {
        &--value {
          background-color: $light-color;
        }
      }
    }

    &.transaction-notification {
      background-color: $light-color;

      .transaction-notification {
        &__header {
          background-color: $light-color;
        }
      }
    }
    .open-account-booking-vc {
      .activated {
        background-color: $active1-color !important;
      }

      .disabled {
        background-color: rgba($color: $light-color, $alpha: 0.2) !important;
        color: $light-color !important;
      }

      .section__content {
        .date-item {
          &__date {
            background-color: rgba($dark3-color, 0.2);
            color: $dark3-color;

            &:hover {
              color: $light-color;
              background-color: $dark3-color;
            }
          }

          &__day {
            color: $light3-color;
          }
        }

        .timeFrame-item {
          background-color: rgba($color: $dark3-color, $alpha: 0.2);
          color: $dark3-color;

          &:hover {
            color: $light-color;
            background-color: $dark3-color;
          }
        }

        .booking-status {
          .icon {
            background-color: $active1-color !important;
          }
        }
      }
    }

    .open-account-success {
      &__header {
        background-color: $light-color;
        color: $dark3-color;
      }

      &__card {
        background-color: $light-blue;
        color: $dark3-color;
      }

      &__icon {
        background-color: rgba($color: $up1-color, $alpha: 0.2);
        .iCheck {
          background-color: $up1-color;
        }
      }
    }
    .wts-modal {
      &-backdrop2 {
        background-color: rgba($color: $dark-color, $alpha: 0.5);
      }
      &__header {
        background-color: $light-color;
        .header {
          &__right {
            .btn--icon {
              &:hover {
                background-color: $light3-color;
                .icon {
                  background-color: $dark3-color;
                }
              }
            }
          }
        }
      }
      &__body {
        background-color: $white-color;
      }
    }
  }
}

.ReactModal__Body--open {
  .light {
    .icon {
      background-color: $dark3-color;
    }
    @extend %text;
    @extend %dwn-light;
    @extend %common;

    &.react-modal-content {
      background-color: $white-color;

      .modal-user {
        color: $light-color;

        .icon {
          background-color: $light3-color;
          margin-right: 8px;
        }

        .iWarming {
          background-color: $down-color;
        }

        .text-desc {
          color: $light3-color;
        }

        .text-white {
          color: $light-color;
        }

        &__header {
          .user-infor {
            &__avatar {
              background-color: $light-color;
            }

            &__desc {
              .text-title {
                color: $dark2-color;
              }

              .text-desc {
                color: $gray-color;
              }
            }
          }

          .sub-account {
            &__title {
              background-color: transparent;
              color: $gray-color;
            }

            &__infor {
              background-color: transparent;
              color: $dark3-color;
            }

            &.active {
              background-color: $light-color;
              color: $dark3-color;
            }
          }
        }

        &__body {
          .about {
            border-top: 1px solid $light-color;
            border-bottom: 1px solid $light-color;
          }

          .categories {
            color: $gray-color;
            border-top: 1px solid $light-color;

            a {
              color: $gray-color;
            }

            .schedule {
              color: $dark3-color;
            }

            .category {
              color: $gray-color;

              &:hover {
                background-color: $light-color;

                a {
                  color: $dark3-color;
                }

                .icon {
                  background-color: $dark3-color;
                }
              }

              &.category-margin {
                .icon {
                  background-color: $dark3-color;
                }
              }
            }
          }
        }
      }

      .modal-notification {
        color: $light-color;

        &__header {
          border-bottom: 1px solid $light-color;

          .badge {
            background-color: transparent;
            color: $gray-color;

            &.active {
              background-color: $active1-color;
              color: $dark3-color;
            }
          }
        }

        &__body {
          .notifications {
            &__item {
              & + .notifications__item {
                border-top: 1px solid $light-color;
              }

              .cbadge-warning {
                background-color: rgba($color: $down1-color, $alpha: 0.2);
                color: $down1-color;
              }

              .cbadge-infor {
                background-color: rgba($color: $dark3-color, $alpha: 0.2);
                color: $dark3-color;
              }

              .cbadge-login {
                background-color: rgba($color: $dark-blue3, $alpha: 0.2);
                color: $dark-blue3;
              }

              .cbadge-order {
                background-color: rgba($color: $active1-color, $alpha: 0.2);
                color: $active1-color;

                &__type {
                  &--sell {
                    background-color: $down1-color;
                    color: $light-color;
                  }

                  &--buy {
                    background-color: $up1-color;
                    color: $dark3-color;
                  }
                }

                &__symbol {
                  background-color: $light-color;
                  color: $dark3-color;
                }
              }

              .text-title {
                color: $dark3-color;
              }

              .text-desc {
                color: $gray-color;
              }

              .text-time {
                color: $gray-color;
              }
            }
          }
        }
      }

      .mobile-menu {
        &__header {
          border-bottom: 1px solid $light-color;
        }
        a {
          color: $gray-color;
        }
      }
    }

    .price-list {
      .price-list-step {
        color: $dark3-color;

        .price {
          &:hover {
            background-color: rgba($color: $dark3-color, $alpha: 0.1);
          }
        }

        &__buy {
          background-color: $light-color;
          color: $dark3-color;

          .value {
            background-color: rgba($color: $up1-color, $alpha: 0.2);
          }
        }

        &__sell {
          background-color: $light-color;
          color: $dark3-color;

          .overlay-text {
            color: black;
          }

          .value {
            background-color: rgba($color: $down1-color, $alpha: 0.2);
          }
        }
      }

      .price-list-total {
        background-color: $down1-color;
        color: $light-color;

        &__buy {
          background-color: $up1-color;
          color: $dark3-color;
        }

        &__sell {
          background-color: $down1-color;
          color: $light-color;
        }
      }
    }

    &.stock-detail-modal {
      background-color: $light-color;
      color: $dark3-color;

      .icon {
        background-color: $gray-color;

        &:hover {
          background-color: $light-color;
        }
      }

      .stock-detail-modal-header {
        background-color: $white-color;
      }

      .stock-detail-modal-body {
        background-color: $light1-color;
        color: $dark3-color;

        .card-panel {
          background-color: transparent !important;

          &-header {
            &__title {
              background-color: transparent;
              color: $gray-color;

              &:hover {
                color: $dark3-color;
              }

              &.active {
                color: $dark3-color;
                background-color: $white-color;
              }
            }

            &__input {
              background-color: $white-color;
            }
          }

          &-body {
            background-color: $white-color !important;
            color: $dark3-color;

            .grid-table-body {
              div {
                background-color: transparent;
              }

              & + .odd {
                background-color: $light-blue !important;
              }
            }
          }
        }

        .symbol-infor {
          color: $dark3-color;

          .icon {
            background-color: #6d6e71;
          }

          .symbol-brand {
            &__name {
              color: $dark3-color;
            }

            &__exchange {
              color: $light3-color;
            }
          }

          .progress-bar {
            background-color: $light2-color;

            .anchor {
              background-color: $light1-color;
            }

            .anchor-up {
              background-color: $up1-color;
            }

            .anchor-down {
              background-color: $down-color;
            }

            &::after {
              border-bottom: 5px solid white;
              background-color: transparent;
            }
          }
        }

        .grid-table-header div {
          background-color: transparent;
          color: $gray-color;
        }

        .event-tab {
          .events {
            .event {
              background-color: $light-blue;

              &__title {
                color: $dark3-color;
              }

              &__desc {
                color: $gray-color;
              }
            }

            .icon {
              background-color: $gray-color;
            }
          }
        }

        .profile-tab {
          .profile-title {
            &__name {
              color: $dark3-color;
            }
          }

          .profile-desc {
            color: $dark3-color;
          }

          .profile-intro {
            color: $gray-color;
          }

          .listing {
            border-bottom: 1px solid $light-color;

            &__metric {
              color: $gray-color;
            }

            &__name {
              color: $dark3-color;
            }

            &__percent {
              color: $gray-color;
            }

            &__head {
              color: $dark3-color;
            }
          }
        }

        .price-list {
          .price-list-step {
            color: $dark3-color;
            &__buy {
              background-color: $light-color;
              color: $dark3-color;

              .value {
                background-color: rgba($color: $up1-color, $alpha: 0.2);
              }
            }

            &__sell {
              background-color: $light-color;
              color: $dark3-color;

              .overlay-text {
                color: black;
              }

              .value {
                background-color: rgba($color: $down1-color, $alpha: 0.2);
              }
            }
          }

          .price-list-total {
            background-color: $down1-color;
            color: $light-color;

            &__buy {
              background-color: $up1-color;
              color: $dark3-color;
            }

            &__sell {
              background-color: $down1-color;
              color: $light-color;
            }
          }
        }

        .trading-data {
          .btn-secondary {
            background-color: $light1-color;
            color: $gray-color;

            &.active {
              background-color: $active1-color !important;
            }

            span {
              color: $dark3-color !important;
            }
          }

          .bg-transparent {
            border-color: $light1-color !important;
            color: $gray-color;
          }
        }

        .finance {
          .finance-header {
            .btn-secondary {
              background-color: $light1-color;
              color: $gray-color;

              &.active {
                background-color: $active1-color !important;
                border-color: $active1-color !important;
                color: $dark3-color !important;
              }
            }
          }

          .tab-panel {
            tbody {
              tr {
                td {
                  color: #000;
                }
              }
            }
          }

          .tabs {
            color: $light4-color;

            .tab {
              &.active {
                color: $active1-color;
                border-bottom: 1px solid $active1-color;
              }
            }
          }

          .scroll {
            .card {
              .card-header {
                background-color: transparent;
              }

              .card-body {
                .table-borderless th {
                  border-top: 1px solid #c4c9d0;
                }
              }
            }
          }
        }
      }

      .price-analystic {
        .progress-bar {
          background-color: $light-color;

          .value {
            background-color: $light-blue3;
          }
        }
      }

      @keyframes blink {
        0% {
          background-color: rgba($light1-color, 0.1);
        }

        50% {
          background-color: rgba($light1-color, 0.2);
        }

        100% {
          background-color: rgba($light1-color, 0.25);
        }
      }

      @-webkit-keyframes blink {
        0% {
          background-color: rgba($light1-color, 0.1);
        }

        50% {
          background-color: rgba($light1-color, 0.2);
        }

        100% {
          background-color: rgba($light1-color, 0.25);
        }
      }

      .realtime > div {
        -moz-transition: all 0.2s ease-in-out;
        -webkit-transition: all 0.2s ease-in-out;
        -o-transition: all 0.2s ease-in-out;
        -ms-transition: all 0.2s ease-in-out;
        transition: all 0.2s ease-in;

        -moz-animation: blink normal 0.5s ease-in-out;
        /* Firefox */
        -webkit-animation: blink normal 0.6s ease-in-out;
        /* Webkit */
        -ms-animation: blink normal 0.6s ease-in-out;
        /* IE */
        animation: blink normal 0.6s ease-in-out;
        /* Opera */
      }
    }

    &.place-order {
      background-color: $white-color;
    }

    .iTooltip {
      background-color: $light-blue3 !important;
    }

    &.derivative {
      .filter {
        .input-text-search {
          input {
            background-color: $white-color;
          }
        }
        .filter-datePicker {
          background-color: $white-color;
        }
        .filter-control-select {
          &__control {
            background-color: $white-color;
          }
        }
      }
      .derivative-pl-report {
        .bg-dark3 {
          background-color: $light-blue1 !important;
        }
        table {
          thead {
            tr {
              &:not(:first-child) {
                td {
                  border-top: 1px solid $dark2-color;
                }
              }
            }
          }

          tbody {
            tr {
              td {
                pointer-events: none;
              }

              &:nth-child(4n + 1),
              &:nth-child(4n + 2) {
                td {
                  background-color: $light-blue !important;
                }
              }

              &:nth-child(4n),
              &:nth-child(4n + 3) {
                td {
                  background-color: $white-color !important;
                }
              }
            }
          }

          .bg-dark3 {
            background-color: $dark3-color;
          }
        }
      }
      .infor-group {
        &:not(:last-child) {
          border-bottom: 1px solid $light-color;
        }
      }
    }
    .derivative-mpa {
      .price-list-step__buy,
      .price-list-step__sell {
        background-color: $light-blue;
      }
      .match-analystic {
        .grid-table-row {
          &.odd {
            background-color: rgba($light-blue, 1);
          }
        }
      }
      .price-analystic {
        .progress-bar {
          background-color: $light-color;
        }
      }
    }
    .popup-setting {
      background-color: $white-color;
    }
  }

  &.light.contract-detail-modal {
    background-color: $white-color;
  }
  &.light.condition-detail-modal {
    background-color: $white-color;
  }
}

.light {
  .condition-detail-modal-header {
    background-color: $light2-color;
  }
}
