.match-analystic {
  height: 100%;

  .scroll {
    height: calc(100% - 20px);
    margin-bottom: 100px;
  }

  .time {
    color: $light3-color;
    font-weight: 400 !important;
    font-style: normal;
  }

  .realtime > div {
    -moz-transition: all 0.2s ease-in-out;
    -webkit-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    -ms-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in;

    -moz-animation: blink normal 0.5s ease-in-out;
    /* Firefox */
    -webkit-animation: blink normal 0.6s ease-in-out;
    /* Webkit */
    -ms-animation: blink normal 0.6s ease-in-out;
    /* IE */
    animation: blink normal 0.6s ease-in-out;
    /* Opera */
  }

  .grid-table-header,
  .grid-table-body {
    display: grid;
  }

  .grid-table {
    grid-template-rows: 24px 72px;
    width: 100%;
    height: calc(100% - 24px) !important;
  }

  .grid-table-header,
  .grid-table-body {
    grid-template-columns: 25% 25% 25% 25%;
  }

  .grid-table-header {
    grid-column-gap: 0px;
    grid-template-rows: auto;
    font-weight: 500;
  }

  .grid-table-body {
    grid-template-rows: auto auto auto;
  }

  .grid-table-body > div {
    text-align: center;
    height: 24px;
    padding: 2px 4px 2px 2px;
  }

  .ReactVirtualized__Grid {
    will-change: unset !important;
  }

  .grid-table-header div {
    text-align: center;
    background-color: #2f3134;
    padding: 4px;
  }

  .align-end {
    align-self: end;
  }

  .grid-table-body.odd > div {
    background: $dark-color;
  }

  .custom-scrollbars .track-vertical {
    opacity: 0;
    transition: opacity 200ms ease;
  }

  .custom-scrollbars:hover .track-vertical {
    opacity: 1;
  }

  .thumb-vertical {
    background-color: #aaa;
    border-radius: 6px;
    transition: background-color 0.2s linear, height 0.2s ease-in-out;
    -webkit-transition: background-color 0.2s linear, height 0.2s ease-in-out;
    height: 6px;
    /* there must be 'bottom' for ps__thumb-x */
    bottom: 2px;
    /* please don't change 'position' */
    position: absolute;
    opacity: 0.6;

    &:hover {
      opacity: 1;
      background-color: #999;
      width: 11px;
    }
  }

  .track-vertical {
    // may require some other styles here
    position: absolute;
    width: 6px;
    right: 2px;
    bottom: 2px;
    top: 2px;
    border-radius: 3px;
  }
}

.price-analystic {
  height: 100%;
  overflow: hidden;

  .card-panel-body {
    padding: 8px 12px;
    align-items: stretch;
    height: 100%;
    border: 1px solid $dark2-color;

    table {
      thead {
        text-align: center;
      }
    }
  }

  .scroll {
    height: calc(100%);
  }

  .div-table {
    padding: 0;
    display: table;
    width: 100%;

    .cell {
      display: table-cell;
      padding: 3px 0;
    }

    .progress-cell {
      width: 100%;
    }

    .row {
      display: table-row;
    }

    .heading {
      background-color: $dark2-color;
      display: table-header-group;
    }

    .cell,
    .head {
      display: table-cell;
      // padding: 3px 2px;
    }

    .heading {
      background-color: $dark2-color;
      display: table-header-group;
      font-weight: 500;
    }

    .foot {
      background-color: $dark2-color;
      display: table-footer-group;
      font-weight: 500;
    }

    .body {
      display: table-row-group;
    }
  }

  .progress-bar {
    position: relative;
    border-radius: 4px;
    display: flex;
    justify-content: space-between;
    background-color: $dark-color;
    color: $dark-color;
    font-weight: 500;
    font-size: 13px;
    line-height: 12px;
    min-height: 12px;
    width: calc(100% - 20px);
    left: 10px;

    .value {
      text-align: left;
      background-color: $dark-blue3;
      padding-left: 4px;
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
      color: transparent;
      width: 0px;
      -webkit-transition: width 0.6s ease-in-out;
      -moz-transition: width 0.6s ease-in-out;
      -o-transition: width 0.6s ease-in-out;
      transition: width 0.6s ease-in-out;
    }
  }
}
