@import '../helpers/variables';

.text-up {
  color: $up-color;
}

.text--gray {
  color: $gray-color;
}

.app-footer {
  display: flex;
  flex: 0 0 40px;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0 8px;

  color: $white-color;
  background: $panel-bg-dark;
  height: 32px;
  font-weight: 500;
  line-height: 16px;
  font-style: normal;
  font-size: 13px;
  z-index: 10000;

  .user-select__control {
    height: 24px;
    min-height: 24px;
  }

  .icon {
    width: 16px;
    height: 16px;
    object-fit: contain;
    background-color: $light3-color;
    margin-right: 4px;
  }

  .iGift {
    background-color: $active-color;
  }

  .iConnecting {
    background-color: $up-color !important;
    font-size: 13px;
  }

  .iDisconnect {
    background-color: $gray-color;
  }

  .footer-btn {
    padding: 4px 8px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    .icon {
      background-color: $light-color;
    }

    &:not(.highlight):hover {
      color: $light-color;
      background-color: $dark3-color;
      border-radius: 4px;

      .icon {
        background-color: $light-color;
      }
    }

    &:not(.highlight).active {
      color: $black-color;
      background-color: $active-color;
      border: none;
      outline: none;
      border-radius: 4px;

      .icon {
        background-color: $black-color;
      }
    }

    &.highlight {
      background-color: $dark2-color;
      color: $active-color;
      line-height: 20px;
      height: 28px;
      border-radius: 4px;
    }

    & + .footer-btn {
      margin-left: 8px;
    }
  }

  .btn-select-account {
    .user-select {
      &__control {
        width: auto;
      }
      &__single-value {
        padding: 2px 2px 2px 12px;
      }
      &__indicator {
        padding: 8px 0px 8px 8px;
        rotate: 180deg;
      }
      &__menu {
        position: absolute !important;
        top: auto !important;
        bottom: 100% !important;
      }
    }
    margin-left: 8px;
  }

  .footer-menu {
    span {
      font-size: 13px;
      font-weight: 400;
      font-style: normal;
      line-height: 16px;
      margin-left: 16px;

      &.item {
        width: 110px;
        height: 29px;
        justify-content: center;
        user-select: none;
      }

      &.active {
        border-radius: 4px;
        background-color: #589dc0;

        .text-content {
          color: $light-color;
        }
      }

      .lightning-fill {
        color: #282a36;
      }
    }
  }

  @media screen and (max-width: 1440px) {
    .text-copyright {
      display: none;
    }
  }

  @media screen and (min-width: 1441px) {
    .text-copyright {
      display: block;
    }
  }
}
