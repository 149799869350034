//====================================================
//  Animation
//====================================================

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(1.5rem);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translateY(-1.5rem);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes scaleUp {
  from {
    transform: scale(0);
  }

  to {
    transform: scale(1);
  }
}

@keyframes scaleDown {
  from {
    transform: scale(1);
  }

  to {
    transform: scale(0);
  }
}

@keyframes slideUp {
  from {
    transform: translateY(25vh);
  }

  to {
    transform: translateY(0);
  }
}

@keyframes slideDown {
  from {
    transform: translateY(-25vh);
  }

  to {
    transform: translateY(0);
  }
}

@keyframes slideLeft {
  from {
    transform: translateX(25vh);
  }

  to {
    transform: translateX(0);
  }
}

@keyframes slideRight {
  from {
    transform: translateX(-25vh);
  }

  to {
    transform: translateX(0);
  }
}

@keyframes flipIn {
  from {
    opacity: 0;
    transform: scaleY(0);
  }

  to {
    opacity: 1;
    transform: scaleY(1);
  }
}

@keyframes flipOut {
  from {
    opacity: 1;
    transform: scaleY(1);
  }

  to {
    opacity: 0;
    transform: scaleY(0);
  }
}

@keyframes rotateRight {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes rotateLeft {
  from {
    transform: rotate(360deg);
  }

  to {
    transform: rotate(0deg);
  }
}

@keyframes rotate90 {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(90deg);
  }
}

@keyframes flash {
  from {
    opacity: 1;
  }

  to {
    opacity: 0.25;
  }
}

@keyframes shake {
  15% {
    transform: translateX(0.5rem);
  }

  30% {
    transform: translateX(-0.4rem);
  }

  45% {
    transform: translateX(0.3rem);
  }

  60% {
    transform: translateX(-0.2rem);
  }

  75% {
    transform: translateX(0.1rem);
  }

  90% {
    transform: translateX(0);
  }

  90% {
    transform: translateX(0);
  }
}

@keyframes pulse-animation {
  0% {
    box-shadow: 0 0 0 0px $up-color;
  }
  100% {
    box-shadow: 0 0 0 10px rgba($up-color, 0);
  }
}

@keyframes pulse-animation-light {
  0% {
    box-shadow: 0 0 0 0px $up1-color;
  }
  100% {
    box-shadow: 0 0 0 10px rgba($up1-color, 0);
  }
}

@keyframes blink-new {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.25;
  }
  100% {
    opacity: 1;
  }
}

//====================================================
//  Animation Utility Classes
//====================================================

.fadeIn {
  animation: fadeIn 500ms;
}

.fadeOut {
  animation: fadeOut 500ms;
}

.fadeInUp {
  animation: fadeInUp 500ms;
}

.fadeInDown {
  animation: fadeInDown 500ms;
}

.slideUp {
  animation: slideUp 200ms ease-in-out;
}

.slideDown {
  animation: slideDown 200ms ease-in-out;
}

.slideRight {
  animation: slideRight 200ms ease-in-out;
}

.slideLeft {
  animation: slideLeft 200ms ease-in-out;
}

.scaleUp {
  animation: scaleUp 200ms ease-in-out;
}

.scaleDown {
  animation: scaleDown 200ms ease-in-out;
}

.flipIn {
  animation: flipIn 200ms cubic-bezier(0.5, -0.5, 0.5, 1.5);
}

.flipOut {
  animation: flipOut 200ms cubic-bezier(0.5, -0.5, 0.5, 1.5);
}

.rotateRight {
  animation: rotateRight 500ms;
}

.rotateLeft {
  animation: rotateLeft 500ms;
}

.flash {
  animation: flash 500ms 3;
}

.shake {
  animation: shake 200ms;
}

.spinner-infinite {
  animation: rotateRight 1s linear infinite;
}

.pulse {
  animation: pulse-animation;
  animation-duration: 2s;
  animation-iteration-count: 5;
}

.blink-new {
  animation: blink-new 2s infinite;
}

.light {
  .pulse {
    animation: pulse-animation-light;
    animation-duration: 2s;
    animation-iteration-count: 5;
  }
}
