.filter-datePicker {
  display: flex;
  align-items: center;
  padding: 4px 8px;
  border-radius: 4px;
  background-color: $dark-color;
  gap: 4px;
  height: 24px;

  &__from,
  &__to {
    color: $light-color;
    font-size: 13px;
    font-weight: 400;
    line-height: 20px;
  }

  input {
    background-color: transparent;
    color: $light-color;
    border: none;
    outline: none;
    width: 90px;
  }

  .icon {
    width: 12px;
    height: 12px;
    background-color: $gray-color;
  }
}
