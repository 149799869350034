.gen-openapi-key {
  background-color: $panel-bg-dark;
  &__head {
    justify-content: center;
    background-color: $dark2-color;
    display: flex;
    height: 32px;
    padding: 4px;
    align-items: center;
    align-self: stretch;

    .right-section {
      position: absolute;
      right: 0;
      top: 2px;
      height: 32px;
      width: 32px;
    }
  }
  &__body {
    display: flex;
    flex-direction: column;
    padding: 12px;
    gap: 12px;

    .btn-submit {
      display: flex;
      width: 100%;
      padding: 6px 12px;
      justify-content: center;
      align-items: center;
      gap: 6px;
      border-radius: 4px;
      background: $dark-blue3;
    }

    form {
      display: flex;
      flex-direction: column;
      gap: 12px;
      .form-item {
        display: flex;
        flex-direction: column;
        label {
          font-size: 13px;
          line-height: 20px;
        }
        input {
          padding: 6px 8px;
          gap: 4px;
          border-radius: 4px;
          background: $dark-color;
          border: none;
          color: $light-color;
          outline: none;
        }
      }
    }

    .container {
      display: grid;
      font-size: 13px;
      gap: 12px;
      grid-template-columns: 100px 1fr;

      .title {
      }

      .value {
        overflow-wrap: anywhere;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        text-overflow: ellipsis;
        -webkit-line-clamp: 4;
      }
    }
  }
}

.light {
  .gen-openapi-key {
    background-color: $white-color;
    &__head {
      background-color: $light-color;
    }
    &__body {
      display: flex;
      flex-direction: column;
      padding: 12px;
      gap: 12px;

      .btn-submit {
        background: $light-blue3;
      }

      form {
        input {
          background: $light-color;
          color: $dark3-color;
        }
      }
    }
  }
}
