%dwn {
  .dwm {
    display: flex;
    align-items: center;
    justify-content: center;

    &-main {
      display: flex;
      justify-content: center;
      gap: 24px;
      height: 100%;

      &--left {
        width: 468px;
      }

      &--right {
        width: 228px;
      }
    }

    &-form {
      margin-top: 12px;
      display: flex;
      gap: 12px;
      flex-direction: column;
      width: 100%;

      &-row {
        height: 32px;
        display: flex;
        align-items: center;

        > span,
        > input {
          flex: 1;
        }

        input {
          background-color: $dark-color;
          border: none;
          outline: none;
          height: 32px;
          color: $light-color;
          padding: 2px 8px;
          border-radius: 4px;
          font-size: 13px;
          line-height: 20px;
          font-weight: 400;
          margin-left: -8px;
          &::placeholder {
            color: $gray-color;
          }
        }

        .text--value {
          padding: 6px 8px;
        }
      }

      button {
        height: 32px;
      }
    }

    &-note {
      background-color: $dark2-color;
      border-radius: 4px;
      padding: 12px 8px;
      display: flex;
      flex-direction: column;
      gap: 12px;
      height: 100%;

      ul {
        display: flex;
        flex-direction: column;
        gap: 12px;
        margin: 0;
        padding-inline-start: 20px;
      }
    }

    &-tabs {
      display: flex;
      justify-content: center;
      background-color: $dark3-color;
      width: 468px;
      border-radius: 24px;
      height: 32px;
    }

    &-tab {
      padding: 6px 8px;
      border-radius: 24px;
      color: $light3-color;
      width: 50%;
      text-align: center;

      &.active {
        background-color: $active-color;
        color: $dark3-color;
      }
    }

    &-history {
      margin-top: 36px;
      height: calc(100% - 340px);

      &-header {
        display: flex;
        justify-content: space-between;
      }

      &-body {
        height: calc(100% - 24px);
      }

      &--right {
        display: flex;
        gap: 8px;
      }

      button {
        height: 24px;
      }
    }

    .input-error {
      // border: 1px solid rgba($color: $down-color, $alpha: .5);
      box-shadow: 0 0 5px rgba($color: $down-color, $alpha: 1);
    }
  }
}

.transaction-content {
  color: $light-color;
  padding: 12px;
  border-radius: 4px;
  width: calc(100vw - 234px);
  height: calc(100vh - 94px);
  position: relative;

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    width: 100%;
  }

  &__title {
    display: flex;
    gap: 16px;
  }

  .light-color {
    color: $light-color !important;
  }

  .text {
    &-title {
      font-size: 16px;
      line-height: 24px;
      font-weight: 500;
    }

    &-name {
      font-size: 13px;
      line-height: 20px;
      font-weight: 500;
    }

    &--light {
      color: $light-color;
    }

    &--gray {
      color: $gray-color;
    }

    &--light3 {
      color: $light3-color;
    }

    &--active {
      color: $active-color;
    }

    &--success {
      color: $up-color;
    }

    &--fail {
      color: $down-color;
    }
  }

  .mt-24 {
    margin-top: 24px;
  }

  .mt-36 {
    margin-top: 36px;
  }

  .w-32p {
    width: 32px;
  }

  .w-100p {
    min-width: 100px;
  }

  .w-150p {
    min-width: 150px;
  }

  .transaction-filters {
    display: flex;
    align-items: center;
    gap: 12px;

    &__select {
      min-width: 150px;
    }
  }

  .filter-control-select {
    font-size: 13px;
    font-weight: 500;
    width: 100%;
    padding: 0px 12px;

    &__control {
      background-color: $dark-color;
      height: 24px;
      min-height: 24px;
      outline: none;
      border: none;
      box-shadow: none;
    }

    &__menu {
      background-color: $dark-color;
      width: 100%;
      border-top-left-radius: 5;
      border-top-right-radius: 5;
      z-index: 2;
      position: absolute;
      margin-top: 5px;
    }

    &__single-value {
      font-size: 13px;
      padding: 2px;
      color: $light-color;
    }

    &__option {
      color: $light1-color;
      background-color: $dark-color;
      padding: 5px 8px;
      font-size: 13px;
      text-align: start;

      &--is-selected {
        color: #e2e2e3;
        background-color: $dark4-color;
      }

      &:hover {
        background-color: $dark3-color;
      }
    }

    &__value-container,
    &__indicators {
      height: 24px;
    }

    &__indicator-separator {
      display: none;
    }

    &__placeholder {
      color: $light-color;
    }
  }

  .card {
    border: none;
    background-color: transparent;

    .card-body {
      padding: 0;
      background-color: #2f3134;
      border: none;
    }
  }

  .personal-assets-body {
    max-height: 100%;
  }

  .reload-button {
    background-color: $dark3-color;
    border-radius: 4px;
    padding: 4px 16px;
    height: 24px;

    .icon {
      background-color: transparent;
    }

    .iReload {
      background-color: $light-color;
      width: 12px;
    }
  }

  .transfer-button {
    height: 24px;
    align-items: center;
  }

  .stock-internal-transfer {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    gap: 16px;

    .sit {
      display: flex;
      flex-direction: column;
      gap: 12px;

      &__left {
        flex: 1;
      }

      &__right {
        flex: 1;
      }

      &-keyvalue {
        display: flex;
        gap: 4px;
      }

      &-input {
        input {
          width: 100%;
          height: 100%;
          border: none;
          border-radius: 4px;
          background-color: rgba($color: $light-color, $alpha: 0.2);
          outline: none;
          box-shadow: none;
          color: $light-color;
          padding-left: 4px;
          padding-right: 60px;
          position: relative;

          &::placeholder {
            color: $gray-color;
          }
        }

        &__overlay {
          position: absolute;
          right: 5px;
          top: 50%;
          transform: translateY(-50%);

          &:hover {
            text-decoration: underline;
            cursor: pointer;
          }
        }
      }

      &-header {
        display: flex;
        width: 100%;
        justify-content: flex-end;
        align-items: center;
        gap: 12px;

        &__left {
          display: flex;
          gap: 12px;
        }

        &__right {
          display: flex;
          gap: 12px;
        }
      }

      &-body {
        display: flex;
        max-height: 300px;
        width: 100%;
      }

      &-footer {
        margin-top: 12px;
        display: flex;
        justify-content: space-between;
        gap: 12px;
        height: 32px;

        .btn--primary {
          width: 100%;
        }

        .btn--reset {
          min-width: 100px;
        }

        .btn--authen {
          font-weight: 600;
        }
      }
    }

    .transaction-filters__select {
      width: 100%;
    }
  }

  .stock-internal-transfer-history {
    display: flex;
    flex-direction: column;
    height: calc(100% - 360px);
    overflow: hidden;

    .history-body {
      height: 100%;
      overflow: hidden;
    }
  }

  .cash-advance {
    .btn {
      padding: 2px 12px;
    }
    .btn-clear {
      background-color: $dark3-color;
    }
  }

  .cash-internal-transfer-container {
    .cash-transfer-block {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      .cash-transfer-account {
        display: flex;
        align-items: top;
        justify-content: space-between;

        .filter-control-select {
          &__control {
            height: 24px;
          }

          &__value-container {
            height: 24px;
          }

          &__indicators {
            height: 24px;
          }
        }

        .btn--refresh {
          height: 24px;
        }
      }
      .account-detail {
        display: flex;
        flex-direction: column;
        width: 100%;

        .row {
          height: 40px;
          border-bottom: 1px solid $dark2-color;
          font-weight: 400;
          font-size: 13px;
          margin: 0px;
          padding: 0px;

          span {
            height: 32px;
            line-height: 32px;
          }
        }

        .row-hug {
          height: 36px !important;
          border-bottom: 1px solid $dark2-color;
        }
      }
    }

    .cashTransfer-history {
      display: flex;
      flex-direction: column;
      height: calc(100% - 300px);
      overflow: hidden;

      .history-body {
        height: 100%;
        overflow: hidden;
      }
    }
  }

  .iTrading {
    margin-top: 12px;
    transform: rotate(-45deg);
    background-color: $light3-color;
  }

  .iRefresh {
    transform: rotate(0deg);
    background-color: $light-color;
  }

  .btn--refresh {
    cursor: pointer;
    justify-content: center;
    align-items: center;
    width: 48px;
    height: 24px;
    background-color: $dark3-color;
    border-radius: 4px;
  }

  .filter-control-calendar {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    input {
      height: 24px;
      background-color: $dark-color;
      border: none;
      outline: none;
      border-radius: 4px;
      color: $light-color;
      padding: 0 8px;
      width: 100%;
    }

    .input-group-prepend {
      height: 24px;
    }

    .form-hisMargin {
      justify-content: center;
      align-items: center;

      .input-group {
        margin-right: 12px;
        background-color: $dark-color;
        color: $light-color;

        .input-group-text {
          color: $gray-color;
          border: none;

          path {
            fill: $gray-color;
          }
        }
      }
    }
  }

  .bank-hold-container {
    padding: 0px 12px;
    height: 100%;

    .filter-control-select {
      width: 175px;
      height: 24px;

      &__indicator-separator {
        display: none;
      }
    }

    table {
      th {
        padding-left: 8px;
        padding-right: 8px;
        font-size: 13px;
        font-weight: 500;
        line-height: 20px;
      }
    }

    .bank-hold {
      margin: 36px 0px;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;

      .col {
        padding: 0px;
      }

      .bank-hold-content {
        min-width: 902px;

        .list-group {
          .txt-content {
            color: $light3-color;
          }
        }
      }

      .list-group-item {
        background-color: unset;
        padding: 10px 0px;

        &:not(:last-child) {
          border-bottom: 1px solid $dark2-color;
        }
      }
    }

    .form-control {
      margin: 0px !important;
    }

    .form-input {
      height: 28px;
    }

    input {
      -webkit-box-shadow: 0 0 0 30px $dark-color inset !important;
      -webkit-text-fill-color: #f5f5f5 !important;
    }
  }

  .form-trade {
    justify-content: space-between !important;

    .form-control {
      display: block;
      width: 100%;
      font-weight: 400;
      font-size: 14;
      line-height: 1.5;
      background-color: $dark-color;
      background-clip: padding-box;
      border: 0px solid $dark-color;
      border-radius: 0.25rem;
      border-color: unset !important;
      color: $light-color;
    }

    .transfer-amount {
      display: flex;
      width: 100%;
      justify-content: space-between;

      .transfer-amount-label {
        width: max-content;
        height: 32px;
        padding: 6px 8px 6px 0px;
        gap: 4px;
        font-weight: 400;
        font-size: 13px;
        line-height: 20px;
      }

      .transfer-amount-input {
        padding: 6px 8px;

        input {
          gap: 4px;
          width: 312.5px;
          height: 32px;
          background: $dark-color;
          border-radius: 4px;
        }
      }

      .transfer-amount-button {
        padding: 6px 16px;

        button {
          width: 312.5px;
          height: 32px;
          border-radius: 4px;
          border: 0px;
        }
      }
    }
  }

  .right-infor {
    display: flex;
    flex-direction: column;

    &__header {
      display: flex;
      justify-content: space-between;
    }

    &__footer {
      display: flex;
      justify-content: end;
      align-items: center;
      gap: 4px;
      margin-top: 12px;
    }

    &-history {
      .transaction-filters {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;

        .input-text-search {
          input {
            height: 24px;
            text-transform: uppercase;
          }
        }
      }

      .gap-4 {
        gap: 4px;
      }
    }
  }

  .statement-container {
    table {
      th {
        padding-left: 8px;
        padding-right: 8px;
        font-size: 13px;
        font-weight: 500;
        line-height: 20px;
      }

      tr {
        height: 32px;

        td {
          line-height: 20px;
          padding: 0px 8px;
        }
      }
    }

    .input-text-search {
      input {
        background-color: $dark-color;
        border-radius: 24px;
        border: 1px solid $dark-color;
        color: $light-color;
        padding: 0px 12px 0px 28px;
        height: 24px;
        width: 102px;
        outline: none;
      }

      .icon {
        width: 12px;
        height: 12px;
      }

      span {
        position: absolute;
        margin-left: 8px;
      }
    }

    .btn-icon {
      height: 24px !important;
    }
  }

  .statement {
    &__filters {
      display: flex;

      .btn,
      .btn-icon,
      .transaction-filters__select,
      .filter-datePicker {
        height: 24px;
      }

      .input-text-search {
        line-height: 24px;
        height: 24px;
      }

      .filter-control-select {
        padding: 0px 4px 0px 0px !important;
        width: 126px;
        height: 24px !important;

        &__control {
          height: 24px !important;
          min-height: 24px;
        }
      }
    }
  }

  .cash-statement {
    .scrollbar-container {
      .table-container {
        margin-top: 8px !important;
      }
    }
  }

  .executed-gainloss {
    .scrollbar-container {
      .table-container {
        margin-top: 8px !important;
        table {
          thead {
            tr {
              td {
                border-color: $dark2-color;
                border-bottom: 1px solid $dark2-color;
              }
            }
          }
        }
      }
    }
  }

  .input-text-search {
    input {
      width: 120px;
      background-color: $dark-color;
      border-radius: 4px;
      border: 1px solid $dark-color;
      color: $light-color;
      padding: 1px 12px 1px 28px;
      height: 24px;

      &:focus-visible {
        outline: none;
      }
    }

    span {
      position: absolute;
      margin-left: 8px;
    }
  }

  .filter-datePicker {
    min-height: 24px !important;
  }

  .mr-12 {
    margin-right: 12px;
  }

  .h-24 {
    height: 24px;
  }

  .light-3 {
    color: $light3-color;
  }

  .light {
    color: $light-color;
  }

  .margin-list {
    .table-actions {
      display: flex;
      gap: 4px;
      flex-direction: row;
      justify-content: center;
    }
  }

  .btn-export {
    background-color: rgba($light-color, 0.2);
    width: max-content;

    .iExport {
      margin-left: -6px;
      margin-right: 6px;
      background-color: $light-color;
    }

    &:hover {
      background-color: rgba($dark3-color, 0.2);
      color: $dark3-color;

      .iExport {
        background-color: $dark3-color;
      }
    }
  }

  .conditional-order-statement {
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    &__header {
      margin-top: 36px;
      display: flex;
      justify-content: space-between;
    }

    &__body {
      margin-top: 12px;
    }

    &__filters {
      display: flex;
      gap: 4px;

      .btn,
      .btn-icon,
      .transaction-filters__select,
      .filter-datePicker {
        height: 24px;

        .filter-control-select {
          &__control {
            height: 24px;
          }
        }
      }
    }

    .text {
      &-name {
        color: $light-color;
        font-style: normal;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
      }

      &-title {
        color: $light-color;
        font-style: normal;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
      }

      &-desc {
        color: $light3-color;
        font-style: normal;
        font-size: 13px;
        font-weight: 400;
        line-height: 20px;

        &--bigger {
          font-size: 16px;
          font-weight: 500;
          line-height: 24px;
        }
      }

      &-value {
        color: $up-color;
        font-style: normal;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
      }

      &-highlight {
        color: $active-color;
      }

      &-warning {
        color: $active-color !important;
      }

      &-light-3 {
        color: $light3-color;
      }
    }
  }

  // deposite-withdraw for margin (dwm)

  @extend %dwn;

  .deposite-withdraw {
    display: flex;
    flex-direction: column;
    gap: 12px;
    height: calc(100vh - 125px);

    &-data {
      table {
        td,
        th {
          padding: 4px 12px;
        }
      }
    }

    .text {
      &-name {
        color: $light-color;
        font-style: normal;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
      }

      &-title {
        color: $light-color;
        font-style: normal;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
      }

      &-desc {
        color: $light3-color;
        font-style: normal;
        font-size: 13px;
        font-weight: 400;
        line-height: 20px;

        &--bigger {
          font-size: 16px;
          font-weight: 500;
          line-height: 24px;
        }
      }

      &-value {
        color: $up-color;
        font-style: normal;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
      }

      &-highlight {
        color: $active-color;
      }

      &-warning {
        color: $active-color !important;
      }

      &--light-3 {
        color: $light3-color;
      }
    }

    .gap-4 {
      gap: 4px;
    }

    .bg-info--custom {
      background-color: $dark-blue3;
    }

    .bg-warning--custom {
      background-color: $active-color;
    }

    .icon {
      width: 16px;
      height: 16px;
      object-fit: contain;
    }

    .iMore {
      cursor: pointer;
    }

    table {
      tr {
        height: 32px;
      }
    }

    &-header {
      height: 56px;
      padding: 12px;
      background-color: $dark1-color;
      border-radius: 4px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      &-metric {
        display: flex;
        flex-direction: row;
        gap: 6px;
        align-items: center;
      }

      &__left {
        display: flex;
        flex-direction: row;
        gap: 40px;
      }

      &__right {
        color: $light-color;
        display: flex;
        justify-content: center;
        align-items: center;

        .btn {
          height: 32px;
          border-radius: 16px;
          padding: 6px 16px;
          gap: 4px;

          font-size: 13px;
          font-weight: 500;
          line-height: 20px;
        }
      }
    }

    &-main {
      height: 100%;

      &-header {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        gap: 8px;
        padding: 8px;
        background-color: $dark2-color;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;

        &__left {
          gap: 8px;
          display: flex;
          align-items: center;
        }
      }

      &-body {
        display: flex;
        flex-direction: column;
        color: $light-color;
        background-color: $dark1-color;
        padding: 12px;
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
        align-items: stretch;
        height: calc(100% - 35px);
      }

      &-tab {
        color: $light3-color;
        height: 24px;
        padding: 2px 8px;

        font-size: 13px;
        font-weight: 500;
        line-height: 20px;
        text-align: center;

        &.active {
          color: $dark3-color;
          background-color: $active-color;
          border-radius: 16px;
        }

        &:not(.active):hover {
          color: $light-color;
        }
      }
    }

    &-data {
      &__header {
        margin-top: 12px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
      }
    }

    &-main-bankGroup {
      display: flex;
      flex-direction: column;
      gap: 12px;
      margin-top: 12px;

      &__body {
        display: flex;
        flex-direction: row;
        column-gap: 12px;
      }

      &__items {
        gap: 12px;
        display: flex;
        flex-direction: column;
        flex: 7;
      }

      &__content {
        display: flex;
        flex-direction: column;
        gap: 24px;
        padding: 12px;
        flex: 4;
        background-color: $dark-color;
        height: 100%;
        border-radius: 4px;

        .multiple {
          display: flex;
          flex-direction: column;
          gap: 12px;
        }
      }
    }

    &-main-bankItem {
      display: flex;
      flex-direction: row;
      gap: 12px;
      padding: 12px;
      background-color: $dark-color;
      border-radius: 4px;

      .bank-infor {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 12px;
        flex: 3;

        .bank-logo {
          min-width: 40px;
          min-height: 40px;
          width: 40px;
          height: 40px;
          border-radius: 20px;
          background-color: $light-color;
          background-image: url('../../../img/icons/utilities/nologo.svg');
          background-position: center;
          background-repeat: no-repeat;
          background-size: contain;
        }

        .bank-desc {
          display: flex;
          flex-direction: column;
          font-weight: 500;
        }
      }

      .bank-beneficiary {
        display: flex;
        flex-direction: column;
        gap: 4px;
        flex: 4;
      }

      .account-beneficiary {
        font-weight: 500;
        display: flex;
        flex-direction: row;
        gap: 12px;
        flex: 3;
        .btn {
          &.active {
            color: $dark3-color;
            background-color: $active-color;
          }
        }
      }

      .bank-qr {
        display: flex;
        gap: 24px;
        font-size: 13px;
        font-weight: 500;
        background-color: $dark2-color;
        padding: 12px;
        width: 100%;
        .qr-img {
          background-size: cover;
          min-width: 168px !important;
          height: 168px;
          background-color: #e2e2e3;
          border-radius: 4px;
        }
        .qr-infor {
          display: flex;
          flex-direction: column;
          gap: 12px;
          .btn {
            padding: 6px 16px;
            width: fit-content;
          }
        }
      }

      &-showmore {
        background-color: $dark-color;
        height: 44px;
        color: $light3-color;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 4px;
        cursor: pointer;

        font-size: 13px;
        font-weight: 400;
        line-height: 20px;
        text-align: center;
      }
    }
  }
}

.ReactModal__Body--open {
  @extend %dwn;
}
