@import '../helpers/variables';

@media only screen and (min-width: 768px) {
  .app-header {
    &__nav {
      max-width: calc(100vw - 116px);
    }
  }
}

.app-header.navbar {
  background-color: $panel-bg-dark;
  height: 56px;
  padding: 8px;

  .icon {
    width: 16px;
    height: 16px;
    object-fit: contain;
    color: $light-color;
    background-color: $light3-color;
  }

  a.button-link {
    display: flex;
    color: $light3-color;
    /* font-family: 'Open Sans'; */
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;

    &:hover {
      color: $light1-color;
      text-decoration: none;

      .icon {
        background-color: $light1-color;
      }
    }
  }

  a.nav-link {
    display: flex;
    color: $light3-color;
    /* font-family: 'Open Sans'; */
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    padding: 11px;

    &.active {
      color: $active-color;

      .icon {
        background-color: $active-color;
      }
    }

    &.active::after {
      content: '';
      display: block;
      position: absolute;
      bottom: 0;
      width: 50%;
      left: 25%;
      color: $active-color;
      border-bottom: 2px solid $active-color;
    }

    &:not(.active):hover {
      color: $light1-color;

      .icon {
        background-color: $light1-color;
      }
    }
  }

  .nav-right {
    .nav-item {
      padding: 8px;
      position: relative;
      margin: 4px;
      border-radius: 4px;

      &:hover {
        color: $light-color;
        background-color: rgba($color: $light-color, $alpha: 0.2);
        .icon {
          background-color: $light1-color;
        }
      }
      & + .nav-item {
        margin-left: 0px;
      }
    }
  }

  .nav-item {
    position: relative;

    &:hover {
      color: $light1-color;

      .icon {
        background-color: $light1-color;
      }
    }

    & + .nav-item {
      margin-left: 16px;
      @media only screen and (max-width: 1600px) {
        margin-left: 4px;
      }
    }

    .plus {
      width: 6px;
      height: 6px;
      border-radius: 50%;
      position: absolute;
      right: 6px;
      top: 2px;
      background-color: $active-color;

      &.active {
        background-color: $active-color;
      }

      &.i {
        background-color: $up-color;
      }

      &.d {
        background-color: $down-color;
      }
    }
  }

  .header-btn {
    font-size: 13px;
    font-weight: 500;
    line-height: 16px;
    font-style: normal;
    color: $light3-color;

    background-color: #05090b;
    border: none;
    padding: 5px 8px;
    height: 32px;
    border-radius: 4px;

    &:hover {
      color: $light-color;

      .icon {
        background-color: $light-color;
      }
    }

    &.btn-login {
      background-color: #05090b;
      margin-left: 4px;
    }

    &.btn-logined {
      background-color: #05090b;
      margin-left: 18px;
    }
  }

  .trans {
    background-color: transparent;
  }

  .special-badge {
    position: absolute;
    top: 4px;
    right: -8px;
    background: #d32f2f;
    color: $light-color;
    border-radius: 2px;
    font-size: 10px;
    line-height: 1.5;
    padding: 1px 6px;
    text-align: center;
  }
}

.light {
  .app-header.navbar {
    .header-btn {
      &:hover {
        color: $light-color;
      }
    }
    .icon {
      color: $light-color;
      background-color: $light-blue2;
    }

    a.nav-link {
      color: $light-blue2;
      /* font-family: 'Open Sans'; */

      &.active {
        color: $active1-color;

        .icon {
          background-color: $active1-color;
        }
      }

      &.active::after {
        color: $active1-color;
        border-bottom: 2px solid $active1-color;
      }

      &:not(.active):hover {
        color: $light1-color;

        .icon {
          background-color: $light1-color;
        }
      }
    }

    .nav-item {
      .plus {
        background-color: $active1-color;

        &.active {
          background-color: $active1-color;
        }

        &.i {
          background-color: $up1-color;
        }

        &.d {
          background-color: $down1-color;
        }
      }
    }
  }
}
