@import '../helpers/variables';

table {
  border-collapse: collapse;
}

.box-shadown {
  box-shadow: -8px 8px 4px rgba(0, 0, 0, 0.6);
  -moz-box-shadow: -8px 8px 4px rgba(0, 0, 0, 0.6);
  -webkit-box-shadow: -8px 8px 4px rgba(0, 0, 0, 0.6);
  -o-box-shadow: -8px 8px 4px rgba(0, 0, 0, 0.6);
}

.arrow-light {
  .arrow::after {
    border-bottom-color: $light-color !important;
  }
}

.arrow-dark {
  .arrow::after {
    border-bottom-color: $dark1-color !important;
  }
}

.table {
  width: 100%;
  color: $light-color;
  margin-bottom: 0;
  border: 1px solid $dark2-color;

  thead th {
    border: 1px solid $dark2-color;
    color: $light3-color;
  }

  .table-bordered {
    tbody {
      tr:hover {
        background-color: $dark3-color !important;
      }
    }

    td {
      -webkit-border-image: none;
      border-image: none;
      -webkit-border-image: initial;
      border-image: initial;
      background: $bg-dark;
      border: 1px solid $dark2-color;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
    }

    & + .tbl-list {
      th {
        background-color: #2f3146;
        height: 35px;
        border: 1px solid #42465b;
      }

      tbody {
        tr:nth-child(2n) td {
          background-color: #373a4d;
        }

        tr:nth-child(2n) td.high-light,
        tr:nth-child(2n) td.high-light-down {
          background-color: #3b3e55;
        }

        tr:hover {
          td,
          td.high-light,
          td.high-light-down {
            background-color: #474e7c;
          }

          td.no-hover {
            pointer-events: none;
            background-color: transparent;
          }
        }
      }
    }

    & + .tbl-detail {
      th {
        background-color: #2f3146;
        border: 1px solid #42465b;
      }

      tr.tbl-detail-head {
        th {
          background-color: #20222f;
          border: 1px solid #42465b;
          height: 45px;
        }
      }

      tbody {
        tr:nth-child(2n) td {
          background-color: #3d4054;
        }

        tr:nth-child(2n + 1) td {
          background-color: #2f3146;
        }

        tr:hover td {
          background-color: #474e7c;
        }
      }
    }
  }
  tfoot {
    tr {
      border: 1px solid $dark2-color;
    }
  }
}

.panel-order .table-bordered,
.panel-order .table-bordered td {
  background: $light-color;
  border: 1px solid #c4c9d0;
  color: #333333;
}

.panel-cash-advance {
  table {
    table-layout: fixed;
  }
  .selCashOut {
    width: 75%;

    .filter-control-select__control {
      width: 100%;
    }
  }
}
.panel-cash-advance .table-bordered,
.panel-cash-advance .table-bordered td,
.panel-cash-advance .table-bordered th {
  padding: 8px 12px;
}

.panel-order .table-bordered td.d-flex {
  border: none;
  height: 27px;
  align-items: center;
}

tr .d-fit-content {
  width: 1%;
}

.panel-order .table-bordered tr:not(:first-child) td.d-flex {
  border-top: 1px solid #c4c9d0;
}

.panel-order .table-bordered tbody tr:hover td {
  background: #dadee3;
}

.table-fix,
.table.banggia {
  table-layout: fixed;
}

.table th,
.table thead th {
  font-weight: 500;
}

.table thead th {
  vertical-align: middle;
  text-align: center;
}

.table-bordered th {
  border: 1px solid #1a1d1f;

  -webkit-border-image: none;
  border-image: none;
  -webkit-border-image: initial;
  border-image: initial;
  // background: #1a1d1f;
  background-color: rgb(26, 29, 31);
}

.panel-order .table-bordered th {
  border: 1px solid #c4c9d0;
  background: #dadee3;
  color: #333333;
}

.table td,
.table th {
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;

  padding: 0.25rem;
  font-size: 13px;
  vertical-align: middle;
  background-clip: padding-box;
  border: 1px solid $dark2-color;
  border-top: none;
  border-bottom: none;
}

.table tbody td.high-light,
.table thead th.high-light {
  background-color: $dark2-color;
}

.table tbody tr:hover td {
  background-color: $dark3-color;
}

.table tbody tr:nth-child(even) {
  background: $dark1-color;
}

.table tbody tr:nth-child(odd) {
  background: $dark-color;
}

.scroll420 {
  height: calc(100vh - 420px) !important;
}

.table.table-no-bordered th,
.table.table-no-bordered td {
  border: none;
  color: #666666;
  height: 25px;
}

.ordStt_N,
.page-asset .card-body .table.table-no-bordered td {
  color: #d4d4d4;
}

.page-asset .card-body .table.table-no-bordered td:not(.high-light) {
  background-color: #20222f;
}

.derivative {
  .table {
    td {
      font-size: 13px;
      padding: 2px 6px;
      line-height: 20px;
    }
    th {
      font-size: 13px;
      padding: 2px 6px;
      line-height: 20px;
    }
  }

  .price-table {
    td,
    th {
      font-size: 11px;
    }

    th {
      user-select: none;
    }
  }
}

.cw-table {
  &.table {
    td,
    th {
      font-size: 11.5px;
    }
  }

  &.table {
    th {
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

.group-table {
  &.table {
    td,
    th {
      font-size: 12px;
    }
  }
}

.watchlist-table,
.watchlist-pin-table {
  &.table {
    td,
    th {
      font-size: 12px;
    }
  }
}
