%common {
  .bg-down-20 {
    background-color: rgba($color: $down-color, $alpha: 0.2);
  }

  .bg-up-20 {
    background-color: rgba($color: $up-color, $alpha: 0.2);
  }

  .bg-ref-20 {
    background-color: rgba($color: $ref-color, $alpha: 0.2);
  }

  .bg-ceil-20 {
    background-color: rgba($color: $ceil-color, $alpha: 0.2);
  }

  .bg-floor-20 {
    background-color: rgba($color: $floor-color, $alpha: 0.2);
  }

  .bg-dark1 {
    background-color: $dark1-color !important;
  }

  .bg-dark2 {
    background-color: $dark2-color !important;
  }

  .bg-dark3 {
    background-color: $dark3-color !important;
  }

  .bg-transparent {
    background-color: transparent !important;
  }

  .bg-yellow20 {
    background-color: #ffe70b33 !important;
  }
}

.dark {
  @extend %text;
  @extend %common;
  .ordStt_E,
  .ordStt_6,
  .ordStt_9,
  .ordStt_E,
  .ordStt_X,
  .txt-red,
  .d {
    color: $down-color !important;
  }

  .c,
  .txt-gia-tran,
  .ceiling {
    color: $ceil-color !important;
  }

  .r,
  .e,
  .txt-gia-tc,
  .ordStt_P,
  .ordStt_N,
  .ordStt_R,
  .ordStt_5,
  .ordStt_3,
  .ordStt_2,
  .ordStt_1,
  .ordStt_8 {
    color: $ref-color !important;
  }

  .f,
  .floor,
  .txt-gia-san,
  .ordStt_I,
  .ordStt_7 {
    color: $floor-color !important;
  }

  .i,
  .txt-lime,
  .g,
  .ordStt_F,
  .ordStt_A,
  .ordStt_4 {
    color: $up-color !important;
  }

  .c.active {
    background: $ceil-color !important;
    color: $white-color !important;
  }

  .d.active,
  .bg-red {
    background: $down-color !important;
    color: $white-color !important;
  }

  .i.active,
  .bg-green {
    background: $up-color !important;
    color: $dark3-color !important;
  }

  .e.active,
  .bg-tc {
    background: $ref-color !important;
    color: $white-color !important;
  }

  .text {
    &--light {
      color: $light-color !important;
    }

    &--light3 {
      color: $light3-color !important;
    }

    &--active {
      color: $active-color;
    }

    &--success {
      color: $up-color;
    }

    &--fail {
      color: $down-color;
    }

    &--gray {
      color: $gray-color;
    }
  }

  .text-white {
    color: $white-color !important;
  }

  .text-link {
    color: $dark-blue;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }

  /**
* ! react auto suggest
*/
  .react-autosuggest__suggestions-container--open {
    background-color: #2f3134;
  }

  .react-autosuggest__suggestions {
    background-color: $dark1-color;
    &-list {
      .stockSuggest {
        &__symbol {
          color: $light-color;
        }
        &__exchange {
          background-color: $dark-color;
          color: $gray-color;
        }
        &__right {
          color: $light3-color;
        }
      }
    }
  }

  .react-autosuggest__suggestion--highlighted {
    background-color: #585a5d;
  }

  .highcharts-xaxis .highcharts-axis-line {
    stroke: $dark4-color;
  }

  .iTooltip {
    background-color: $dark-blue3 !important;
  }
}

.modal-open {
  .dark {
    @extend %text;
    @extend %common;

    .login-logo {
      background-color: $white-color;
    }

    &.auth-content {
      .iLogo {
        background-color: $light-color;
      }
      .list-group {
        li {
          background-color: #f8f8f8;
          border-radius: 8px;
          margin-bottom: 5px;
          padding: 5px 10px;

          &:hover {
            background-color: #0091ff;
            color: $white-color;

            svg:first-of-type path {
              fill: $white-color;
            }
          }

          &:active {
            svg path {
              fill: #ffffff;
            }
          }
        }
      }
    }

    &.modal-content {
      background-color: $dark1-color;
      .confirm-order-body {
        .content-read {
          color: $light3-color;
        }
      }
    }
    .iTooltip {
      background-color: $dark-blue3 !important;
    }

    // .icon {
    //   background-color: $light-color; // icon defaul color
    // }
  }
}

.ReactModal__Body--open {
  .dark {
    @extend %text;
    @extend %common;

    &.stock-detail-modal,
    &.contract-detail-modal {
      @keyframes blink {
        0% {
          background-color: rgba($color: $dark3-color, $alpha: 0.1);
        }

        50% {
          background-color: rgba($color: $dark2-color, $alpha: 0.2);
        }

        100% {
          background-color: rgba($color: $dark2-color, $alpha: 0.3);
        }
      }

      &.condition-detail-modal {
        @keyframes blink {
          0% {
            background-color: rgba($color: $dark3-color, $alpha: 0.1);
          }

          50% {
            background-color: rgba($color: $dark2-color, $alpha: 0.2);
          }

          100% {
            background-color: rgba($color: $dark2-color, $alpha: 0.3);
          }
        }
      }
      @-webkit-keyframes blink {
        0% {
          background-color: rgba($color: $dark3-color, $alpha: 0.1);
        }

        50% {
          background-color: rgba($color: $dark2-color, $alpha: 0.2);
        }

        100% {
          background-color: rgba($color: $dark2-color, $alpha: 0.3);
        }
      }
    }
    .iTooltip {
      background-color: $dark-blue3 !important;
    }

    // .icon {
    //   background-color: $light-color; // icon defaul color
    // }
  }
}
