.contract-detail-modal {
  background-color: $dark-color;
  color: $light-color;
  width: 100%;
  height: calc(100% - 40px);
  border: none;
  max-width: 100vw;
  margin-bottom: 0;
  margin-top: 30px;
  position: absolute;
  bottom: 40px;
  overflow: hidden;
  z-index: 3;
  animation: slideDown 100ms ease;

  &.light {
    background-color: $white-color;

    .price-list {
      .price-list-step {
        &__buy,
        &__sell {
          background-color: $light-blue;
          color: $dark2-color;
        }
      }
    }

    .match-analystic {
      .grid-table-header {
        div {
          background-color: transparent;
        }
      }

      .grid-table-body {
        &.odd {
          div {
            background-color: $light-blue;
          }
        }
      }
    }

    .price-analystic {
      .progress-bar {
        background-color: $light-blue;
      }
    }

    .derivative-order {
      .icon {
        background-color: $light3-color;
      }
    }

    .iClose {
      background-color: $dark3-color;
    }
  }
}
.price-chart-derivative {
  height: calc(100vh - 112px);
}

.popover-derivative-order {
  .arrow {
    display: none;
  }
}

.condition-detail-modal-dialog {
  display: flex;
  justify-content: center;
}

.tnc-detail-modal {
  width: 800px !important;
  height: 80vh !important;
}
.condition-detail-border-top {
  border-top: 1px dashed $dark3-color;
}
.condition-detail-border-bottom {
  border-bottom: 1px dashed $dark3-color;
}
.condition-detail-modal {
  width: 352px;
  background-color: $dark3-color;
  color: $light-color;
  border: none;
  max-width: 100vw;
  position: absolute;
  overflow: hidden;
  animation: slideDown 100ms ease;
  .condition-detail-modal-header {
    background-color: $dark2-color;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .condition-detail-modal-body {
    width: 100%;
    .btn--primary {
      height: 32px;
      width: 100%;
    }
    .cancel {
      height: 32px;
      width: 100%;
    }
    .p-12px {
      padding: 12px;
    }
    .condition-detail-modal-content {
      display: flex;
      flex-direction: column;
      gap: 6px;
    }
  }
}

.popover-multiple-layout {
  .arrow {
    display: none;
  }

  .layout-panel {
    display: grid;
    padding: 12px;
    gap: 8px;
    border-radius: 4px;
    background-color: $dark1-color;

    label {
      color: $light-color;
      font-size: 13px;
      font-weight: 500;
      line-height: 20px;
    }

    .menu-tabs {
      display: flex;
      cursor: pointer;
      gap: 8px;

      &__tab {
        display: flex;

        &:hover {
          .icon {
            background-color: $light-color;
            transform: rotate(5deg) scale(1.2);
          }
        }
      }

      .active {
        .icon {
          background-color: $light-color;
        }
      }
    }
  }
}

.contract-detail-content {
  @extend %text;
  display: flex;
  flex-direction: column;
  padding: 6px 12px;
  height: 100%;

  &__scroll {
    max-height: calc(100vh - 64px);
    width: calc(100% + 8px);
    overflow-y: auto;
    -webkit-overflow-scrolling: auto;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    height: 32px;

    .market-index {
      display: flex;
      margin-bottom: 0px;
      justify-content: center;
      gap: 32px;

      .icon {
        width: 8px;
        height: 8px;
      }

      .iCeil2 {
        background-color: $ceil-color !important;
      }

      .iRef2 {
        background-color: $ref-color !important;
      }

      .iFloor2 {
        background-color: $floor-color !important;
      }
    }

    .btn {
      &--loginToOrder {
        width: 320px;
        height: 32px;
        padding: 6px 12px !important;
        border: 4px;
      }

      &--close {
        width: 24px;
        height: 24px;
        border-radius: 4px;

        &:hover {
          background-color: $light-color;

          .icon {
            background-color: $dark3-color;
          }
        }
      }
    }

    .iClose {
      width: 16px;
      height: 16px;
      background-color: $light1-color;
    }

    .iLayout {
      width: 16px;
      height: 16px;
      background-color: $light1-color;
    }
  }

  &__body {
    display: flex;
    // height: calc(100% - 40px);
    padding: 12px 0;
    gap: 12px;

    &--left {
      flex: 5;
    }

    &--right {
      display: flex;
      flex-direction: column;
      flex: 2;
    }

    &--left2 {
      flex: 1;
    }

    &--right2 {
      display: flex;
      flex-direction: column;
      //width: 822px;
      // flex: 1;
    }

    &--top {
      display: flex;
      flex-direction: column;
      // flex: 5;
      min-height: 100% !important;
      //overflow: hidden;
      .row1 {
        height: 500px;
        min-height: 500px;
      }
      .row2 {
        height: 140px;
        min-height: 140px;
        margin-top: 12px;
      }
    }

    &--bottom {
      margin-top: 12px;
      display: flex;
      flex-direction: column;
      // flex: 2;
      height: calc(35% - 12px) !important;
    }
  }

  .panel {
    $self: &;

    & + #{$self} {
      margin-top: 12px;
    }

    &-body {
      background-color: $dark1-color;
    }
  }

  .gap-24 {
    gap: 24px;
  }

  .gap-4 {
    gap: 4px;
  }

  .gap-36 {
    gap: 36px;
  }

  .gap-12 {
    gap: 12px;
  }

  .price-list {
    padding: 6px 12px;

    &__title {
      margin-bottom: 6px;
    }

    .price-list-step {
      height: 24px;

      .price {
        top: 5px;
        border-radius: 2px;
        padding-left: 4px;
        padding-right: 4px;
      }
    }
  }

  .match-analystic {
    .grid-table-header {
      height: 28px;
      font-size: 13px;
      line-height: 20px;
      font-weight: 500;
      font-style: normal;

      div {
        background-color: transparent;
        font-size: 13px;
        line-height: 20px;
      }
    }

    .grid-table-body {
      // grid-template-rows: auto auto auto;
      // grid-template-columns: 25% 25% 25% 25%;
    }

    .grid-table-rows {
      display: flex;
      flex-direction: column;
      width: 100%;
      padding: 4px 12px;
    }

    .grid-table-row {
      display: flex;

      &.odd {
        background-color: $dark-color;
      }

      div {
        flex: 1;
        font-size: 13px;
        line-height: 20px;
        font-weight: 400;
      }
    }
  }

  .price-analystic {
    padding: 12px;
    font-size: 13px;
    line-height: 20px;
  }

  .derivative-order {
    &__quick {
      padding: 12px;

      .symbol {
        padding: 8px 0 0px 0;
      }
    }
  }
}

.market {
  display: flex;
  gap: 32px;

  &__index {
    display: flex;
    align-items: end;
    gap: 24px;
    height: 28px;

    &-changes {
      display: flex;
    }

    &-prices {
      display: flex;
      gap: 12px;
      height: 24px;
      align-items: end;
    }

    &-statistic {
      display: flex;
      gap: 24px;
      height: 24px;
      align-items: end;
    }
  }

  .icon {
    width: 8px;
    height: 8px;
  }

  .iCeil2 {
    background-color: $ceil-color !important;
  }

  .iRef2 {
    background-color: $ref-color !important;
  }

  .iFloor2 {
    background-color: $floor-color !important;
  }
}
