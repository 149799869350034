.noti-popup {
  @extend %text;
  display: flex;
  flex-direction: column;

  &__header {
    display: flex;
    justify-content: space-between;
    background-color: $dark2-color !important;
  }

  &__body {
    background-color: $dark1-color !important;

    .bg-icon-noti {
      height: 80px;
      width: 80px;
      background-color: rgba($color: $down-color, $alpha: 0.2);
    }
  }
}

.light .noti-popup {
  @extend %text;
  &__header {
    background-color: $light-color !important;
  }

  &__body {
    background-color: $white-color !important;

    .bg-icon-noti {
      background-color: rgba($color: $down1-color, $alpha: 0.2);
    }
  }
}
