.icon {
  background-color: $gray-color;
  width: 16px;
  height: 16px;
  object-fit: contain;

  &.r {
    background-color: $ref-color !important;
  }

  &.f {
    background-color: $floor-color !important;
  }

  &.c {
    background-color: $ceil-color !important;
  }

  &.i {
    background-color: $up-color !important;
  }

  &.d {
    background-color: $down-color !important;
  }

  &.light {
    background-color: $light-color !important;
  }

  &.light-blue3 {
    background-color: $light-blue3 !important;
  }

  &.gray {
    background-color: $gray-color !important;
  }

  &.blue-3 {
    background-color: $dark-blue3 !important;
  }

  &.blue4 {
    background-color: $light-blue4 !important;
  }

  &.no-hover:hover {
    color: inherit;
    background-color: inherit;
    border-color: inherit;
  }

  &--xs {
    width: 0.25rem;
    height: 0.25rem;
  }

  &--sm {
    width: 0.5rem;
    height: 0.5rem;
  }

  &--md {
    width: 1rem;
    height: 1rem;
  }

  &--lg {
    width: 2rem;
    height: 2rem;
  }

  &--xs {
    width: 4px;
    height: 4px;
  }

  &--light-blue3 {
    background-color: $light-blue3 !important;
  }
}

.iAsset {
  mask: url('../../img/icons/header/Asset.svg') no-repeat center / contain;
  -webkit-mask: url('../../img/icons/header/Asset.svg') no-repeat center /
    contain;
}

.iAsset1 {
  mask: url('../../img/icons/rightMenu/Asset.svg') no-repeat center / contain;
  -webkit-mask: url('../../img/icons/rightMenu/Asset.svg') no-repeat center /
    contain;
}

.iMargin {
  mask: url('../../img/icons/personal/Margin.svg') no-repeat center / contain;
  -webkit-mask: url('../../img/icons/personal/Margin.svg') no-repeat center /
    contain;
}

.iPayment {
  mask: url('../../img/icons/personal/Payment.svg') no-repeat center / contain;
  -webkit-mask: url('../../img/icons/personal/Payment.svg') no-repeat center /
    contain;
}

.iStatement {
  mask: url('../../img/icons/personal/Statement.svg') no-repeat center / contain;
  -webkit-mask: url('../../img/icons/personal/Statement.svg') no-repeat center /
    contain;
}

.iAnalysis {
  mask: url('../../img/icons/personal/Analysis.svg') no-repeat center / contain;
  -webkit-mask: url('../../img/icons/personal/Analysis.svg') no-repeat center /
    contain;
}

.iDetailList {
  mask: url('../../img/icons/personal/DetailList.svg') no-repeat center /
    contain;
  -webkit-mask: url('../../img/icons/personal/DetailList.svg') no-repeat center /
    contain;
}

.iAccount {
  mask: url('../../img/icons/personal/Account.svg') no-repeat center / contain;
  -webkit-mask: url('../../img/icons/personal/Account.svg') no-repeat center /
    contain;
}

.iSetting {
  mask: url('../../img/icons/personal/Setting.svg') no-repeat center / contain;
  -webkit-mask: url('../../img/icons/personal/Setting.svg') no-repeat center /
    contain;
}

.iUp {
  mask: url('../../img/icons/price/Up.svg') no-repeat center / contain;
  -webkit-mask: url('../../img/icons/price/Up.svg') no-repeat center / contain;
}

.iUp2 {
  mask: url('../../img/icons/personal/Up.svg') no-repeat center / contain;
  -webkit-mask: url('../../img/icons/personal/Up.svg') no-repeat center /
    contain;
}

.iArrowUp {
  mask: url('../../img/arrowUp.svg') no-repeat center / contain;
  -webkit-mask: url('../../img/arrowUp.svg') no-repeat center / contain;
}

.iArrowDown {
  mask: url('../../img/arrowDown.svg') no-repeat center / contain;
  -webkit-mask: url('../../img/arrowDown.svg') no-repeat center / contain;
}

.iCeil {
  mask: url('../../img/icons/price/Ceil.svg') no-repeat center / contain;
  -webkit-mask: url('../../img/icons/price/Ceil.svg') no-repeat center / contain;
}

.iRef {
  mask: url('../../img/icons/price/Ref.svg') no-repeat center / contain;
  -webkit-mask: url('../../img/icons/price/Ref.svg') no-repeat center / contain;
}

.iCeil2 {
  mask: url('../../img/icons/price/Ceil2.svg') no-repeat center / contain;
  -webkit-mask: url('../../img/icons/price/Ceil2.svg') no-repeat center /
    contain;
}

.iRef2 {
  mask: url('../../img/icons/price/Ref2.svg') no-repeat center / contain;
  -webkit-mask: url('../../img/icons/price/Ref2.svg') no-repeat center / contain;
}

.iFloor2 {
  mask: url('../../img/icons/price/Floor2.svg') no-repeat center / contain;
  -webkit-mask: url('../../img/icons/price/Floor2.svg') no-repeat center /
    contain;
}

.iDown {
  mask: url('../../img/icons/price/Down.svg') no-repeat center / contain;
  -webkit-mask: url('../../img/icons/price/Down.svg') no-repeat center / contain;
}

.iDown2 {
  mask: url('../../img/icons/price/Down.svg') no-repeat center / contain;
  -webkit-mask: url('../../img/icons/price/Down2.svg') no-repeat center /
    contain;
}

.iFloor {
  mask: url('../../img/icons/price/Floor.svg') no-repeat center / contain;
  -webkit-mask: url('../../img/icons/price/Floor.svg') no-repeat center /
    contain;
}

.iClose {
  mask: url('../../img/icons/price/Close.svg') no-repeat center / contain;
  -webkit-mask: url('../../img/icons/price/Close.svg') no-repeat center /
    contain;
}

.iConvert {
  mask: url('../../img/icons/price/Convert.svg') no-repeat center / contain;
  -webkit-mask: url('../../img/icons/price/Convert.svg') no-repeat center /
    contain;
}

.iMoney {
  mask: url('../../img/icons/price/Money.svg') no-repeat center / contain;
  -webkit-mask: url('../../img/icons/price/Money.svg') no-repeat center /
    contain;
}

.iMoney1 {
  mask: url('../../img/icons/Money.svg') no-repeat center / contain;
  -webkit-mask: url('../../img/icons/Money.svg') no-repeat center / contain;
}

.iVolume {
  mask: url('../../img/icons/price/Volume.svg') no-repeat center / contain;
  -webkit-mask: url('../../img/icons/price/Volume.svg') no-repeat center /
    contain;
}

.iNext {
  mask: url('../../img/icons/price/Next.svg') no-repeat center / contain;
  -webkit-mask: url('../../img/icons/price/Next.svg') no-repeat center / contain;
}

.iPrevious {
  mask: url('../../img/icons/price/Back.svg') no-repeat center / contain;
  -webkit-mask: url('../../img/icons/price/Back.svg') no-repeat center / contain;
}

.iSmall {
  mask: url('../../img/icons/price/Small.svg') no-repeat center / contain;
  -webkit-mask: url('../../img/icons/price/Small.svg') no-repeat center /
    contain;
}

.iLarge {
  mask: url('../../img/icons/price/Large.svg') no-repeat center / contain;
  -webkit-mask: url('../../img/icons/price/Large.svg') no-repeat center /
    contain;
}

.iOrder {
  mask: url('../../img/icons/footer/Quick_Order.svg') no-repeat center / contain;
  -webkit-mask: url('../../img/icons/footer/Quick_Order.svg') no-repeat center /
    contain;
}

.iOrderList {
  mask: url('../../img/icons/footer/Orders_list.svg') no-repeat center / contain;
  -webkit-mask: url('../../img/icons/footer/Orders_list.svg') no-repeat center /
    contain;
}

.iBack {
  mask: url('../../img/icons/price/Back.svg') no-repeat center / contain;
  -webkit-mask: url('../../img/icons/price/Back.svg') no-repeat center / contain;
}

.iNext {
  mask: url('../../img/icons/auth/Next.svg') no-repeat center / contain;
  -webkit-mask: url('../../img/icons/auth/Next.svg') no-repeat center / contain;
}

.iCancel {
  mask: url('../../img/icons/price/Close.svg') no-repeat center / contain;
  -webkit-mask: url('../../img/icons/price/Close.svg') no-repeat center /
    contain;
}

.iEdit {
  mask: url('../../img/icons/price/Edit.svg') no-repeat center / contain;
  -webkit-mask: url('../../img/icons/price/Edit.svg') no-repeat center / contain;
}

.iGift {
  mask: url('../../img/icons/footer/Gift.svg') no-repeat center / contain;
  -webkit-mask: url('../../img/icons/footer/Gift.svg') no-repeat center /
    contain;
}

.iConnecting {
  mask: url('../../img/icons/footer/Connecting.svg') no-repeat center / contain;
  -webkit-mask: url('../../img/icons/footer/Connecting.svg') no-repeat center /
    contain;
}

.iDisconnect {
  mask: url('../../img/icons/footer/Connecting.svg') no-repeat center / contain;
  -webkit-mask: url('../../img/icons/footer/Connecting.svg') no-repeat center /
    contain;
}

.iBond {
  mask: url('../../img/icons/bond/Bond.svg') no-repeat center / contain;
  -webkit-mask: url('../../img/icons/bond/Bond.svg') no-repeat center / contain;
}

.iNews {
  mask: url('../../img/icons/header/News.svg') no-repeat center / contain;
  -webkit-mask: url('../../img/icons/header/News.svg') no-repeat center /
    contain;
}

.iPersonal {
  mask: url('../../img/icons/header/Personal.svg') no-repeat center / contain;
  -webkit-mask: url('../../img/icons/header/Personal.svg') no-repeat center /
    contain;
}

.iTrading {
  mask: url('../../img/icons/header/Trading.svg') no-repeat center / contain;
  -webkit-mask: url('../../img/icons/header/Trading.svg') no-repeat center /
    contain;
}

.iUtilities {
  mask: url('../../img/icons/header/Utilities.svg') no-repeat center / contain;
  -webkit-mask: url('../../img/icons/header/Utilities.svg') no-repeat center /
    contain;
}

.iPriceboard {
  mask: url('../../img/icons/header/Dashboard.svg') no-repeat center / contain;
  -webkit-mask: url('../../img/icons/header/Dashboard.svg') no-repeat center /
    contain;
}

.iBell {
  mask: url('../../img/icons/header/Notification.svg') no-repeat center /
    contain;
  -webkit-mask: url('../../img/icons/header/Notification.svg') no-repeat center /
    contain;
}

.iAttachment {
  mask: url('../../img/icons/header/Attachment.svg') no-repeat center / contain;
  -webkit-mask: url('../../img/icons/header/Attachment.svg') no-repeat center /
    contain;
}

.iHelp {
  mask: url('../../img/icons/header/Help.svg') no-repeat center / contain;
  -webkit-mask: url('../../img/icons/header/Help.svg') no-repeat center /
    contain;
}

.iHomepage {
  mask: url('../../img/icons/rightMenu/Home.svg') no-repeat center / contain;
  -webkit-mask: url('../../img/icons/rightMenu/Home.svg') no-repeat center /
    contain;
}

.iLayout {
  mask: url('../../img/icons/rightMenu/Layout.svg') no-repeat center / contain;
  -webkit-mask: url('../../img/icons/rightMenu/Layout.svg') no-repeat center /
    contain;
}

.iLayout1 {
  mask: url('../../img/icons/rightMenu/Layout1.svg') no-repeat center / contain;
  -webkit-mask: url('../../img/icons/rightMenu/Layout1.svg') no-repeat center /
    contain;
}

.iLayout2 {
  mask: url('../../img/icons/rightMenu/Layout2.svg') no-repeat center / contain;
  -webkit-mask: url('../../img/icons/rightMenu/Layout2.svg') no-repeat center /
    contain;
}

.iLayout2V {
  mask: url('../../img/icons/rightMenu/Layout2V.svg') no-repeat center / contain;
  -webkit-mask: url('../../img/icons/rightMenu/Layout2V.svg') no-repeat center /
    contain;
}

.iVideo {
  mask: url('../../img/icons/rightMenu/Video.svg') no-repeat center / contain;
  -webkit-mask: url('../../img/icons/rightMenu/Video.svg') no-repeat center /
    contain;
}

.iSearch {
  mask: url('../../img/icons/header/Search.svg') no-repeat center / contain;
  -webkit-mask: url('../../img/icons/header/Search.svg') no-repeat center /
    contain;
}

.iPlay {
  mask: url('../../img/icons/header/Play.svg') no-repeat center / contain;
  -webkit-mask: url('../../img/icons/header/Play.svg') no-repeat center /
    contain;
}

.iCheck {
  mask: url('../../img/icons/price/Check.svg') no-repeat center / contain;
  -webkit-mask: url('../../img/icons/price/Check.svg') no-repeat center /
    contain;
}

.iStar {
  mask: url('../../img/icons/bond/Star.svg') no-repeat center / contain;
  -webkit-mask: url('../../img/icons/bond/Star.svg') no-repeat center / contain;
}

.iStar-outline {
  mask: url('../../img/icons/Star.svg') no-repeat center / contain;
  -webkit-mask: url('../../img/icons/Star.svg') no-repeat center / contain;
}

.iNotification {
  mask: url('../../img/icons/price/Notification.svg') no-repeat center / contain;
  -webkit-mask: url('../../img/icons/price/Notification.svg') no-repeat center /
    contain;
}

.iInfor {
  mask: url('../../img/icons/price/Info.svg') no-repeat center / contain;
  -webkit-mask: url('../../img/icons/price/Info.svg') no-repeat center / contain;
}

.iLine {
  mask: url('../../img/icons/price/line.svg') no-repeat center / contain;
  -webkit-mask: url('../../img/icons/price/line.svg') no-repeat center / contain;
}

.iBin {
  mask: url('../../img/icons/price/Bin.svg') no-repeat center / contain;
  -webkit-mask: url('../../img/icons/price/Bin.svg') no-repeat center / contain;
}

.iAdd {
  mask: url('../../img/icons/price/Plus.svg') no-repeat center / contain;
  -webkit-mask: url('../../img/icons/price/Plus.svg') no-repeat center / contain;
}

.iRefresh {
  mask: url('../../img/icons/price/Refresh.svg') no-repeat center / contain;
  -webkit-mask: url('../../img/icons/price/Refresh.svg') no-repeat center /
    contain;
}

.iCalendar {
  mask: url('../../img/icons/login/Calendar.svg') no-repeat center / contain;
  -webkit-mask: url('../../img/icons/login/Calendar.svg') no-repeat center /
    contain;
}

.iPhone {
  mask: url('../../img/icons/login/Phone.svg') no-repeat center / contain;
  -webkit-mask: url('../../img/icons/login/Phone.svg') no-repeat center /
    contain;
}

.iEmail {
  mask: url('../../img/icons/personal/Email.svg') no-repeat center / contain;
  -webkit-mask: url('../../img/icons/personal/Email.svg') no-repeat center /
    contain;
}

.iLocation {
  mask: url('../../img/icons/personal/Location.svg') no-repeat center / contain;
  -webkit-mask: url('../../img/icons/personal/Location.svg') no-repeat center /
    contain;
}

.iMail {
  mask: url('../../img/icons/price/Email.svg') no-repeat center / contain;
  -webkit-mask: url('../../img/icons/price/Email.svg') no-repeat center /
    contain;
}

.iReload {
  mask: url('../../img/icons/personal/Refresh.svg') no-repeat center / contain;
  -webkit-mask: url('../../img/icons/personal/Refresh.svg') no-repeat center /
    contain;
}

.iAttach {
  mask: url('../../img/icons/personal/Attachment.svg') no-repeat center /
    contain;
  -webkit-mask: url('../../img/icons/personal/Attachment.svg') no-repeat center /
    contain;
}

.iUser {
  mask: url('../../img/icons/personal/User.svg') no-repeat center / contain;
  -webkit-mask: url('../../img/icons/personal/User.svg') no-repeat center /
    contain;
}

.iUsername {
  mask: url('../../img/icons/login/User.svg') no-repeat center / contain;
  -webkit-mask: url('../../img/icons/login/User.svg') no-repeat center / contain;
}

.iLock {
  mask: url('../../img/icons/login/Lock.svg') no-repeat center / contain;
  -webkit-mask: url('../../img/icons/login/Lock.svg') no-repeat center / contain;
}

.iEye {
  mask: url('../../img/icons/login/Eye_show.svg') no-repeat center / contain;
  -webkit-mask: url('../../img/icons/login/Eye_show.svg') no-repeat center /
    contain;
}

.iEyeHide {
  mask: url('../../img/icons/login/Eye_hide.svg') no-repeat center / contain;
  -webkit-mask: url('../../img/icons/login/Eye_hide.svg') no-repeat center /
    contain;
}

.iDownload {
  mask: url('../../img/icons/bond/Download.svg') no-repeat center / contain;
  -webkit-mask: url('../../img/icons/bond/Download.svg') no-repeat center /
    contain;
}

.iWarming {
  mask: url('../../img/icons/rightMenu/Warning.svg') no-repeat center / contain;
  -webkit-mask: url('../../img/icons/rightMenu/Warning.svg') no-repeat center /
    contain;
}

.iCashAdvance {
  mask: url('../../img/icons/rightMenu/CashAdvance.svg') no-repeat center /
    contain;
  -webkit-mask: url('../../img/icons/rightMenu/CashAdvance.svg') no-repeat
    center / contain;
}

.iStockTransfer {
  mask: url('../../img/icons/rightMenu/StockTransfer.svg') no-repeat center /
    contain;
  -webkit-mask: url('../../img/icons/rightMenu/StockTransfer.svg') no-repeat
    center / contain;
}

.iCashTransfer {
  mask: url('../../img/icons/rightMenu/CashTransfer.svg') no-repeat center /
    contain;
  -webkit-mask: url('../../img/icons/rightMenu/CashTransfer.svg') no-repeat
    center / contain;
}

.iMarginDebt {
  mask: url('../../img/icons/rightMenu/MarginDebt.svg') no-repeat center /
    contain;
  -webkit-mask: url('../../img/icons/rightMenu/MarginDebt.svg') no-repeat center /
    contain;
}

.iLogout {
  mask: url('../../img/icons/rightMenu/Logout.svg') no-repeat center / contain;
  -webkit-mask: url('../../img/icons/rightMenu/Logout.svg') no-repeat center /
    contain;
}

.iThemeLight {
  mask: url('../../img/icons/header/Lightmode.svg') no-repeat center / contain;
  -webkit-mask: url('../../img/icons/header/Lightmode.svg') no-repeat center /
    contain;
}

.iThemeDark {
  mask: url('../../img/icons/header/BrightMode.svg') no-repeat center / contain;
  -webkit-mask: url('../../img/icons/header/BrightMode.svg') no-repeat center /
    contain;
}

.iMore {
  mask: url('../../img/icons/utilities/Down.svg') no-repeat center / contain;
  -webkit-mask: url('../../img/icons/utilities/Down.svg') no-repeat center /
    contain;
}

.iSmartotp {
  mask: url('../../img/icons/auth/Smart.svg') no-repeat center / contain;
  -webkit-mask: url('../../img/icons/auth/Smart.svg') no-repeat center / contain;
}

.iSmsotp {
  mask: url('../../img/icons/auth/SMS.svg') no-repeat center / contain;
  -webkit-mask: url('../../img/icons/auth/SMS.svg') no-repeat center / contain;
}

.iMatrix {
  mask: url('../../img/icons/auth/Matrix.svg') no-repeat center / contain;
  -webkit-mask: url('../../img/icons/auth/Matrix.svg') no-repeat center /
    contain;
}

.iCA {
  mask: url('../../img/icons/auth/CA.svg') no-repeat center / contain;
  -webkit-mask: url('../../img/icons/auth/CA.svg') no-repeat center / contain;
}

.iResend {
  mask: url('../../img/icons/auth/Refresh.svg') no-repeat center / contain;
  -webkit-mask: url('../../img/icons/auth/Refresh.svg') no-repeat center /
    contain;
}

.iZoomOut {
  mask: url('../../img/icons/price/Zoomout.svg') no-repeat center / contain;
  -webkit-mask: url('../../img/icons/price/Zoomout.svg') no-repeat center /
    contain;
}

.iZoomIn {
  mask: url('../../img/icons/price/Zoomin.svg') no-repeat center / contain;
  -webkit-mask: url('../../img/icons/price/Zoomin.svg') no-repeat center /
    contain;
}

.iTransaction {
  mask: url('../../img/icons/header/Transaction.svg') no-repeat center / contain;
  -webkit-mask: url('../../img/icons/header/Transaction.svg') no-repeat center /
    contain;
}

.iUnion {
  mask: url('../../img/icons/personal/Union.svg') no-repeat center / contain;
  -webkit-mask: url('../../img/icons/personal/Union.svg') no-repeat center /
    contain;
}

.iCard {
  mask: url('../../img/icons/personal/Card.svg') no-repeat center / contain;
  -webkit-mask: url('../../img/icons/personal/Card.svg') no-repeat center /
    contain;
}

.iTransfer {
  mask: url('../../img/icons/personal/Transfer.svg') no-repeat center / contain;
  -webkit-mask: url('../../img/icons/personal/Transfer.svg') no-repeat center /
    contain;
}

.iExport {
  mask: url('../../img/icons/personal/Export.svg') no-repeat center / contain;
  -webkit-mask: url('../../img/icons/personal/Export.svg') no-repeat center /
    contain;
}

.iImport {
  mask: url('../../img/icons/personal/Import.svg') no-repeat center / contain;
  -webkit-mask: url('../../img/icons/personal/Import.svg') no-repeat center /
    contain;
}

.iKey {
  mask: url('../../img/icons/personal/Key.svg') no-repeat center / contain;
  -webkit-mask: url('../../img/icons/personal/Key.svg') no-repeat center /
    contain;
}

.iIn {
  mask: url('../../img/icons/personal/In.svg') no-repeat center / contain;
  -webkit-mask: url('../../img/icons/personal/In.svg') no-repeat center /
    contain;
}

.iOut {
  mask: url('../../img/icons/personal/Out.svg') no-repeat center / contain;
  -webkit-mask: url('../../img/icons/personal/Out.svg') no-repeat center /
    contain;
}

.iTrash {
  mask: url('../../img/icons/auth/iTrash.svg') no-repeat center / contain;
  -webkit-mask: url('../../img/icons/auth/iTrash.svg') no-repeat center /
    contain;
}

.iBank {
  mask: url('../../img/icons/auth/Bank.svg') no-repeat center / contain;
  -webkit-mask: url('../../img/icons/auth/Bank.svg') no-repeat center / contain;
}

.iBranch {
  mask: url('../../img/icons/auth/Branch.svg') no-repeat center / contain;
  -webkit-mask: url('../../img/icons/auth/Branch.svg') no-repeat center /
    contain;
}

.iNumberSize {
  mask: url('../../img/icons/auth/NumberSize.svg') no-repeat center / contain;
  -webkit-mask: url('../../img/icons/auth/NumberSize.svg') no-repeat center /
    contain;
}

.iBankCard {
  mask: url('../../img/icons/header/BankCard.svg') no-repeat center / contain;
  -webkit-mask: url('../../img/icons/header/BankCard.svg') no-repeat center /
    contain;
}

.iUserAuth {
  mask: url('../../img/icons/header/UserAuth.svg') no-repeat center / contain;
  -webkit-mask: url('../../img/icons/header/UserAuth.svg') no-repeat center /
    contain;
}

.iLock {
  mask: url('../../img/icons/header/Lock.svg') no-repeat center / contain;
  -webkit-mask: url('../../img/icons/header/Lock.svg') no-repeat center /
    contain;
}

.iSuccess {
  mask: url('../../img/icons/header/Success.svg') no-repeat center / contain;
  -webkit-mask: url('../../img/icons/header/Success.svg') no-repeat center /
    contain;
}

.iRebalance {
  mask: url('../../img/icons/header/Rebalance.svg') no-repeat center / contain;
  -webkit-mask: url('../../img/icons/header/Rebalance.svg') no-repeat center /
    contain;
}

.iFail {
  mask: url('../../img/icons/header/Fail.svg') no-repeat center / contain;
  -webkit-mask: url('../../img/icons/header/Fail.svg') no-repeat center /
    contain;
}

.iNotice {
  mask: url('../../img/icons/header/Notice.svg') no-repeat center / contain;
  -webkit-mask: url('../../img/icons/header/Notice.svg') no-repeat center /
    contain;
}

.iCall {
  mask: url('../../img/icons/utilities/Call.svg') no-repeat center / contain;
  -webkit-mask: url('../../img/icons/utilities/Call.svg') no-repeat center /
    contain;
}

.iZoomFull {
  mask: url('../../img/icons/utilities/zoomfull.svg') no-repeat center / contain;
  -webkit-mask: url('../../img/icons/utilities/zoomfull.svg') no-repeat center /
    contain;
}

.iChart {
  mask: url('../../img/icons/utilities/chart.svg') no-repeat center / contain;
  -webkit-mask: url('../../img/icons/utilities/chart.svg') no-repeat center /
    contain;
}

.iChart1 {
  mask: url('../../img/icons/utilities/chart1.svg') no-repeat center / contain;
  -webkit-mask: url('../../img/icons/utilities/chart1.svg') no-repeat center /
    contain;
}

.iPieChart {
  mask: url('../../img/icons/pieChart.svg') no-repeat center / contain;
  -webkit-mask: url('../../img/icons/pieChart.svg') no-repeat center / contain;
}

.iZoomFull2 {
  mask: url('../../img/icons/utilities/zoomfull2.svg') no-repeat center /
    contain;
  -webkit-mask: url('../../img/icons/utilities/zoomfull2.svg') no-repeat center /
    contain;
}

.iZoomFull3 {
  mask: url('../../img/icons/utilities/expand.svg') no-repeat center / contain;
  -webkit-mask: url('../../img/icons/utilities/expand.svg') no-repeat center /
    contain;
}

.iExternalLink {
  mask: url('../../img/icons/utilities/externalLink.svg') no-repeat center /
    contain;
  -webkit-mask: url('../../img/icons/utilities/externalLink.svg') no-repeat
    center / contain;
}

.iLogo {
  mask: url('../../img/icons/login/Logo.svg');
  -webkit-mask: url('../../img/icons/login/Logo.svg') no-repeat center / contain;
}

.iLogo {
  mask: url('../../img/icons/login/Logo.svg');
  -webkit-mask: url('../../img/icons/login/Logo.svg') no-repeat center / contain;
}

.iFeedback {
  mask: url('../../img/icons/header/Feedback.svg');
  -webkit-mask: url('../../img/icons/header/Feedback.svg') no-repeat center /
    contain;
}

.iPinefolio {
  mask: url('../../img/icons/pinefolio/Pinefolio.svg');
  -webkit-mask: url('../../img/icons/pinefolio/Pinefolio.svg') no-repeat center /
    contain;
}

.iPinefolioChart {
  mask: url('../../img/icons/pinefolio/Chart.svg');
  -webkit-mask: url('../../img/icons/pinefolio/Chart.svg') no-repeat center /
    contain;
}

.iMinus {
  mask: url('../../img/icons/pinefolio/Minus.svg');
  -webkit-mask: url('../../img/icons/pinefolio/Minus.svg') no-repeat center /
    contain;
}

.iPlus {
  mask: url('../../img/icons/pinefolio/Plus.svg');
  -webkit-mask: url('../../img/icons/pinefolio/Plus.svg') no-repeat center /
    contain;
}

.iWarning {
  mask: url('../../img/icons/pinefolio/Warning.svg');
  -webkit-mask: url('../../img/icons/pinefolio/Warning.svg') no-repeat center /
    contain;
}

.iEmpty {
  mask: url('../../img/icons/pinefolio/Empty.svg');
  -webkit-mask: url('../../img/icons/pinefolio/Empty.svg') no-repeat center /
    contain;
}

.iCafeF {
  mask: url('../../img/icons/marginCompetition/cafef.svg') no-repeat center /
    contain;
  -webkit-mask: url('../../img/icons/marginCompetition/cafef.svg') no-repeat
    center / contain;
}

.iMrBanner {
  mask: url('../../img/icons/marginCompetition/banner.svg') no-repeat center /
    contain;
  -webkit-mask: url('../../img/icons/marginCompetition/banner.svg') no-repeat
    center / contain;
}

.iPrize {
  mask: url('../../img/icons/marginCompetition/prize.svg') no-repeat center /
    contain;
  -webkit-mask: url('../../img/icons/marginCompetition/prize.svg') no-repeat
    center / contain;
}

.iPrize2 {
  mask: url('../../img/icons/marginCompetition/prize2.svg') no-repeat center /
    contain;
  -webkit-mask: url('../../img/icons/marginCompetition/prize2.svg') no-repeat
    center / contain;
}

.iWhale {
  mask: url('../../img/icons/marginCompetition/whale.svg') no-repeat center /
    contain;
  -webkit-mask: url('../../img/icons/marginCompetition/whale.svg') no-repeat
    center / contain;
}

.iKoi {
  mask: url('../../img/icons/marginCompetition/koi.svg') no-repeat center /
    contain;
  -webkit-mask: url('../../img/icons/marginCompetition/koi.svg') no-repeat
    center / contain;
}

.iCarp {
  mask: url('../../img/icons/marginCompetition/carp.svg') no-repeat center /
    contain;
  -webkit-mask: url('../../img/icons/marginCompetition/carp.svg') no-repeat
    center / contain;
}

.iFair {
  mask: url('../../img/icons/marginCompetition/fair.svg') no-repeat center /
    contain;
  -webkit-mask: url('../../img/icons/marginCompetition/fair.svg') no-repeat
    center / contain;
}

.iDiversified {
  mask: url('../../img/icons/marginCompetition/diversified.svg') no-repeat
    center / contain;
  -webkit-mask: url('../../img/icons/marginCompetition/diversified.svg')
    no-repeat center / contain;
}

.iEazy {
  mask: url('../../img/icons/marginCompetition/eazy.svg') no-repeat center /
    contain;
  -webkit-mask: url('../../img/icons/marginCompetition/eazy.svg') no-repeat
    center / contain;
}

.iTotalAward {
  mask: url('../../img/icons/marginCompetition/totalAward.svg') no-repeat center /
    contain;
  -webkit-mask: url('../../img/icons/marginCompetition/totalAward.svg')
    no-repeat center / contain;
}

.iTotalAward {
  mask: url('../../img/icons/marginCompetition/totalAward.svg') no-repeat center /
    contain;
  -webkit-mask: url('../../img/icons/marginCompetition/totalAward.svg')
    no-repeat center / contain;
}

.iTotalAward {
  mask: url('../../img/icons/marginCompetition/totalAward.svg') no-repeat center /
    contain;
  -webkit-mask: url('../../img/icons/marginCompetition/totalAward.svg')
    no-repeat center / contain;
}

.iZeroFee {
  mask: url('../../img/icons/marginCompetition/zeroFee.svg') no-repeat center /
    contain;
  -webkit-mask: url('../../img/icons/marginCompetition/zeroFee.svg') no-repeat
    center / contain;
}

.iMargin9ps {
  mask: url('../../img/icons/marginCompetition/margin9ps.svg') no-repeat center /
    contain;
  -webkit-mask: url('../../img/icons/marginCompetition/margin9ps.svg') no-repeat
    center / contain;
}

.iDerivative {
  mask: url('../../img/icons/header/Derivative.svg') no-repeat center / contain;
  -webkit-mask: url('../../img/icons/header/Derivative.svg') no-repeat center /
    contain;
}

.iEquity {
  mask: url('../../img/icons/header/Stock.svg') no-repeat center / contain;
  -webkit-mask: url('../../img/icons/header/Stock.svg') no-repeat center /
    contain;
}

.iVN {
  mask: url('../../img/icons/footer/VN.svg') no-repeat center / contain;
  -webkit-mask: url('../../img/icons/footer/VN.svg') no-repeat center / contain;
  background-image: url('../../img/icons/footer/VN.svg');
}

.iEN {
  mask: url('../../img/icons/footer/EN.svg') no-repeat center / contain;
  -webkit-mask: url('../../img/icons/footer/EN.svg') no-repeat center / contain;
  background-image: url('../../img/icons/footer/EN.svg');
}

.iNone {
  mask: none;
  -webkit-mask: none;
  background-color: transparent !important;
}

.iPartner {
  mask: url('../../img/icons/rightMenu/Partner.svg') no-repeat center / contain;
  -webkit-mask: url('../../img/icons/rightMenu/Partner.svg') no-repeat center /
    contain;
}

.iNumber {
  mask: url('../../img/icons/personal/Number.svg') no-repeat center / contain;
  -webkit-mask: url('../../img/icons/personal/Number.svg') no-repeat center /
    contain;
}

.iCopy {
  mask: url('../../img/icons/rightMenu/Copy.svg') no-repeat center / contain;
  -webkit-mask: url('../../img/icons/rightMenu/Copy.svg') no-repeat center /
    contain;
}

.iHambuger {
  mask: url('../../img/icons/header/hamburger.svg') no-repeat center / contain;
  -webkit-mask: url('../../img/icons/header/hamburger.svg') no-repeat center /
    contain;
}

.iReset {
  background-color: url('../../../assets/img/icons/price/Refresh.svg');
  -webkit-mask: url('../../../assets/img/icons/price/Refresh.svg') no-repeat
    center / contain;
}

.i3Dots {
  background-color: url('../../../assets/img/icons/3dots.svg');
  -webkit-mask: url('../../../assets/img/icons/3dots.svg') no-repeat center /
    contain;
}

.iAlert {
  background-color: url('../../../assets/img/icons/Alertsvg.svg');
  -webkit-mask: url('../../../assets/img/icons/Alertsvg.svg') no-repeat center /
    contain;
}
.iDocumentLink1 {
  background-color: url('../../../assets/img/document_link.svg');
  -webkit-mask: url('../../../assets/img/document_link.svg') no-repeat center /
    contain;
}

.iDocumentLink {
  background-color: url('../../../assets/img/link.svg');
  -webkit-mask: url('../../../assets/img/link.svg') no-repeat center / contain;
}

.iSpinner {
  background-color: url('../../../assets/img/spinner.svg');
  -webkit-mask: url('../../../assets/img/spinner.svg') no-repeat center /
    contain;
}

.iZalo {
  background-color: url('../../../assets/img/zalo.svg');
  -webkit-mask: url('../../../assets/img/zalo.svg') no-repeat center / contain;
}

.iIdea {
  background-color: url('../../../assets/img/idea.svg');
  -webkit-mask: url('../../../assets/img/idea.svg') no-repeat center / contain;
}

.iChat {
  background-color: url('../../../assets/img/chat.svg');
  -webkit-mask: url('../../../assets/img/chat.svg') no-repeat center / contain;
}

.iP {
  background-color: url('../../../assets/img/p.svg');
  -webkit-mask: url('../../../assets/img/p.svg') no-repeat center / contain;
}

.iDrvxNoti {
  background-color: url('../../../assets/img/icons/DerivativeNoti.svg');
  -webkit-mask: url('../../../assets/img/icons/DerivativeNoti.svg') no-repeat
    center / contain;
}

.iNoData {
  background-color: url('../../../assets/img/icons/noData.svg');
  -webkit-mask: url('../../../assets/img/icons/noData.svg') no-repeat center /
    contain;
}

.iNoMoney {
  background-color: url('../../../assets/img/icons/noMoney.svg');
  -webkit-mask: url('../../../assets/img/icons/noMoney.svg') no-repeat center /
    contain;
}

.iNoOrder {
  background-color: url('../../../assets/img/icons/noOrder.svg');
  -webkit-mask: url('../../../assets/img/icons/noOrder.svg') no-repeat center /
    contain;
}

.i-1 {
  width: 0.25rem !important; // 4px
  height: 0.25rem !important;
}

.i-2 {
  width: 0.5rem !important; // 8px
  height: 0.5rem !important;
}

.i-3 {
  width: 1rem !important; // 16px
  height: 1rem !important;
}

.i-4 {
  width: 1.5rem !important; // 24px
  height: 1.5rem !important;
}

.i-5 {
  width: 2rem !important; // 32px
  height: 2rem !important;
}

.i-6 {
  width: 2.5rem !important; // 40px
  height: 2.5rem !important;
}

.i-7 {
  width: 3rem !important; // 48px
  height: 3rem !important;
}

.i-8 {
  width: 4rem !important; // 64px
  height: 4rem !important; // 64px
}

.i-12 {
  width: 12px !important;
  height: 12px !important;
}

.i-14 {
  width: 14px !important;
  height: 14px !important;
}

.light .icon {
  &.r {
    background-color: $ref1-color !important;
  }

  &.f {
    background-color: $floor1-color !important;
  }

  &.c {
    background-color: $ceil1-color !important;
  }

  &.i {
    background-color: $up1-color !important;
  }

  &.d {
    background-color: $down1-color !important;
  }
}
