.account-page {
  %action-btn {
    display: inline-block;
    text-align: right;
    flex: 1;
    font-size: 13px;
    line-height: 24px;

    .btn-action {
      cursor: pointer;
      padding: 4px 8px;
      color: $light2-color;
      background-color: $dark2-color;
      border-radius: 16px;

      &:hover {
        color: $light-color;
        background-color: $dark3-color;
        transition: all 0.15s ease-in-out;
      }
    }
  }

  %account-panel-items {
    .account-panel-items {
      padding-top: 12px;
      padding-bottom: 12px;

      & + .account-panel-items {
        border-top: 1px solid $dark2-color;
      }
    }
  }

  %account-panel-item {
    .account-panel-item {
      min-height: 24px;
      height: auto;
      font-size: 13px;
      font-weight: 500;
      line-height: 20px;
      display: flex;
      gap: 12px;
      justify-content: space-between;
      align-items: center;

      & + .account-panel-item {
        margin-top: 12px;
      }

      &__name {
        color: $light3-color;
        flex: 2;
      }

      &__value {
        color: $light-color;
        flex: 3;
      }

      &__action {
        @extend %action-btn;

        .react-switch {
          vertical-align: middle;
        }
      }
    }
  }

  @extend %text;
  color: $light-color;
  display: flex;
  flex-direction: row;
  background-color: $bg-dark;

  &-content {
    color: $light-color;
    padding: 12px;
    border-radius: 4px;
    width: calc(100vw - 234px);
    height: calc(100vh - 94px);
    position: relative;
  }

  .account-filters {
    display: flex;
    align-items: center;
    gap: 12px;

    &__select {
      min-width: 150px;
    }
  }

  .filter-control-select {
    font-size: 13px;
    font-weight: 500;
    width: 100%;
    padding: 0px 12px;

    &__control {
      background-color: $dark-color;
      height: 28px;
      min-height: 24px;
      outline: none;
      border: none;
      box-shadow: none;
    }

    &__menu {
      background-color: $dark-color;
      width: 100%;
      border-top-left-radius: 5;
      border-top-right-radius: 5;
      z-index: 2;
      position: absolute;
      margin-top: 5px;
    }

    &__single-value {
      font-size: 13px;
      padding: 2px;
      color: $light-color;
    }

    &__option {
      color: $light1-color;
      background-color: $dark-color;
      padding: 5px 8px;
      font-size: 13px;
      text-align: start;

      &--is-selected {
        color: #e2e2e3;
        background-color: $dark4-color;
      }

      &:hover {
        background-color: $dark3-color;
      }
    }

    &__value-container,
    &__indicators {
      height: 24px;
    }

    &__indicator-separator {
      display: none;
    }

    &__placeholder {
      color: $light-color;
    }
  }

  .account-panel {
    border-radius: 4px;
    background-color: $dark1-color;

    & + .account-panel {
      margin-top: 12px;
    }

    &-header {
      display: flex;
      justify-content: space-between;
      padding-top: 12px;
      padding-bottom: 12px;
      border-bottom: 1px solid $dark2-color;

      &__title {
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
      }

      &__action {
        @extend %action-btn;
      }

      &__extend {
        display: flex;
        gap: 10px;
      }
    }

    &-body {
      @extend %account-panel-items;
      @extend %account-panel-item;
    }

    .btn-action {
      display: flex;
      padding: 6px 12px;
      justify-content: center;
      align-items: center;
      gap: 6px;
      align-self: stretch;
      border-radius: 4px;
      width: 100%;
      background: $dark-blue3;

      .iKey {
        display: block;
        background-color: $white-color;
      }
    }

    .btn-download {
      display: flex;
      padding: 6px 12px;
      justify-content: center;
      align-items: center;
      gap: 6px;
      align-self: stretch;
      border-radius: 4px;
      width: 100%;
      background: rgba(109, 110, 113, 0.2);
      color: $light-color;

      .iImport {
        display: block;
        background-color: $light-color;
      }
    }
  }

  .account-profile {
    // css for profile page
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 12px;
    color: $light-color;

    .profile-state {
      color: $active-color;
      font-size: 13px;
      font-weight: 500;
      line-height: 20px;
      margin-bottom: 12px;
    }

    .account-panel {
      border-radius: 4px;
      background-color: $dark1-color;

      & + .account-panel {
        margin-top: 12px;
      }

      &-header {
        display: flex;
        justify-content: space-between;
        padding-top: 12px;
        padding-bottom: 12px;
        border-bottom: 1px solid $dark2-color;

        &__title {
          font-size: 16px;
          font-weight: 500;
          line-height: 24px;
          color: $light-color;
        }

        &__action {
          @extend %action-btn;
        }
      }

      &-body {
        @extend %account-panel-items;
        @extend %account-panel-item;
      }
    }

    .btn-unAuthen {
      background-color: $unAuthen-color !important;
      color: $light-color !important;
    }
  }

  .account-bank {
    // css for bank page
  }

  .authorization-detail {
    background-color: $dark1-color;
    padding: 0px 12px 0px 12px;

    table {
      border-top: 1px solid $dark2-color;
      border-left: none;
      border-right: none;
      border-bottom: none;

      tr {
        margin: 0;

        td {
          font-size: 13px;
          line-height: 20px;
          padding: 12px 0px;
          border: none;
        }
      }
    }
  }

  .account-securities {
    // css for password & authencate
    display: flex;
    flex-direction: column;
    align-items: center;

    .company-contact {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 12px;
      margin-top: 12px;

      font-size: 13px;
      font-weight: 400;
      line-height: 20px;

      &__hotline {
        width: 135px;
      }

      &__note {
        text-align: center;
        color: $light3-color;
      }
    }

    .account-filters {
      &__select {
        width: 200px;

        .filter-control-select__control {
          border-radius: 2rem;
        }
      }
    }
  }

  .account-service {
    // css for service settings page
    display: flex;
    flex-direction: column;
    align-items: center;

    .btn-reset {
      font-size: 12px;
      padding: 2px 8px;
      background-color: none;
      border: 1px solid $light-blue3;
      color: $light-blue3;
      border-radius: 4px;
      background-color: transparent;
      outline: none;
      box-shadow: none;

      &:hover {
        background-color: $light-blue3;
        color: $light-color;
      }
    }

    table {
      border: none;
    }

    tr {
      border-top: 1px solid $dark2-color;
    }

    .account-item {
      line-height: 32px;
    }
  }

  .partner-container {
    width: 100%;
    height: 100%;
  }
  .partner-page {
    height: calc(100vh - 178px);
    .mw-165px {
      min-width: 165px;
      max-width: 165px;
    }
    display: flex;
    justify-content: center;
    overflow: auto;
    &__qr {
      // height: 136px;
      // width: 136px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 4px;
      background-color: $white-color;
      padding: 8px;
      // background-repeat: no-repeat;
      // background-position: center;
      // background-size: 136px 136px;
    }
  }
}
