.panel {
  @extend %text;
  width: 100%;
  // height: 100%;
  border-radius: 4px;
  border: 1px solid $dark2-color;
  color: $light3-color;

  &-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    // width: 100%;
    background-color: $dark2-color;
    gap: 10px;
    height: 32px;
    padding: 4px;

    .special-badge {
      position: absolute;
      top: -20px;
      right: -20px;
      background: #d32f2f;
      color: $light-color;
      border-radius: 2px;
      font-size: 10px;
      line-height: 1.5;
      padding: 1px 6px;
      text-align: center;
    }

    &--type-1 {
      background-color: $dark1-color;
      .panel-header {
        background-color: $dark1-color;
      }
    }

    &--left {
      flex-direction: row;
      &__decoration {
        .panel {
          &-tabs {
            display: flex;
            gap: 4px;
          }
          &-tab {
            position: relative;
            padding: 2px 8px;
            border-radius: 24px;
            cursor: pointer;
            height: 24px;
            color: $light3-color;
            transition: all 0.3s;

            &:hover {
              background-color: $dark1-color;
              color: $light-color;
            }

            &.active {
              background-color: unset;
              color: $light-color;
              &::after {
                display: block;
                content: '';
                position: absolute;
                width: 24px;
                border-top: 1px solid $ref-color;
                left: 50%;
                transform: translateX(-50%);
                top: 24px;
              }
            }
          }
        }
      }
      &__tab-bg--active {
        .panel {
          &-tab {
            position: relative;
            padding: 2px 8px;
            border-radius: 24px;
            cursor: pointer;
            height: 24px;
            color: $light3-color;
            transition: all 0.3s;

            &:hover {
              background-color: $gray-color;
              color: $light-color;
            }

            &.active {
              color: $dark-color;
              background-color: $active-color;
            }
          }
        }
      }
    }

    &--right {
      flex-direction: row-reverse;
    }

    .icon {
      background-color: $light3-color;
      transition: all 0.3s;

      &:hover {
        background-color: $light-color;
        cursor: pointer;
      }
    }
  }

  &-body {
    height: calc(100% - 32px);
    background-color: transparent;
    .filter {
      padding: 2px 4px;
      background-color: $dark2-color;
    }
  }

  &-tabs {
    display: flex;
    gap: 4px;
  }

  &-tab {
    padding: 2px 8px;
    border-radius: 24px;
    cursor: pointer;
    height: 24px;
    color: $light3-color;
    transition: all 0.3s;

    &:hover {
      background-color: $dark1-color;
      color: $light-color;
    }

    &.active {
      background-color: $dark1-color;
      color: $light-color;
    }
  }
}

.light {
  .panel {
    border: 1px solid $light-color;
    color: $gray-color;

    &-header {
      background-color: $light-color;

      .icon {
        background-color: $light3-color !important;

        &:hover {
          background-color: $light-color;
        }
      }
      &--type-1 {
        background-color: $white-color;
        .panel-header {
          background-color: $white-color;
        }
      }

      &--left {
        &__tab-bg--active {
          .panel {
            &-tab {
              &:hover {
                background-color: $light-color;
                color: $gray-color;
              }

              &.active {
                color: $dark-color;
                background-color: $active1-color;
              }
            }
          }
        }
        &__decoration {
          .panel {
            &-tab {
              color: $light3-color;
              &:hover {
                background-color: $light-color;
                color: $dark1-color;
              }
              &.active {
                background-color: unset;
                color: $dark1-color;
                &::after {
                  border-top: 1px solid $active1-color;
                }
              }
            }
          }
        }
      }
    }

    &-body {
      // padding: 12px;
      background-color: $white-color;
      .filter {
        padding: 2px 4px;
        background-color: $light-color;
      }
    }

    &-tab {
      color: $gray-color;

      &:hover {
        background-color: $white-color;
        color: $dark3-color;
      }

      &.active {
        background-color: $white-color;
        color: $dark3-color;
      }
    }
  }
}
