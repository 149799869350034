/* vietnamese */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Open Sans Regular'), local('OpenSans-Regular'),
    url(./assets/css/mem8YaGs126MiZpBA-UFWp0bbck.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1,
    U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Open Sans Regular'), local('OpenSans-Regular'),
    url(./assets/css/mem8YaGs126MiZpBA-UFW50bbck.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Open Sans Regular'), local('OpenSans-Regular'),
    url(./assets/css/mem8YaGs126MiZpBA-UFVZ0b.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}

/* roboto */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  src: url(./assets/font/roboto/Roboto-Regular.ttf);
}

body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

label {
  margin-bottom: 0.3rem;
  margin-top: 0.3rem;
}

#root {
  overflow: hidden;
}

.btn-secondary {
  background-color: #3d4054;
  border-color: #3d4054;
}

.btn-secondary.active {
  background-color: #589dc0 !important;
  border-color: #589dc0 !important;
  z-index: 0 !important;
}

.auth-messages,
.widget-messages {
  color: #dc3545;
}

.navbar {
  padding: 0.125rem 1rem;
}

.color-lightning path {
  stroke: #589dc0;
}

.sidebar .nav {
  width: 200px;
  flex-direction: column;
  min-height: 100%;
  padding: 0;
}

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.sidebar .nav-item {
  position: relative;
  margin: 0;
  -webkit-transition: background 0.3s ease-in-out;
  transition: background 0.3s ease-in-out;
}

.sidebar .nav-title {
  padding: 0.75rem 1rem;
  font-size: 80%;
  font-weight: 500;
  color: #e4e7ea;
  text-transform: uppercase;
}

.sidebar .nav-link {
  display: block;
  padding: 0.75rem 1rem;
  color: #fff;
  text-decoration: none;
  background: 0 0;
}

.sidebar .nav-link:hover {
  color: #fff;
  background: #20a8d8;
}

.sidebar .nav-dropdown-items {
  max-height: 0;
  padding: 0;
  margin: 0;
  overflow-y: hidden;
  -webkit-transition: max-height 0.3s ease-in-out;
  transition: max-height 0.3s ease-in-out;
}

.sidebar .nav-dropdown.open {
  background: rgba(0, 0, 0, 0.2);
}

.sidebar .nav-dropdown.open > .nav-dropdown-items {
  max-height: 1500px;
}

.sidebar .nav-link .badge {
  float: right;
  margin-top: 2px;
}

.sidebar .nav-link.active {
  color: #fff;
  background: #3a4248;
}

.sidebar .nav-dropdown-toggle::before {
  position: absolute;
  top: 50%;
  right: 1rem;
  display: block;
  width: 8px;
  height: 8px;
  padding: 0;
  margin-top: -4px;
  content: '';
  background-image: url(data:image/svg+xml;charset=utf8;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9JzAgMCAxMSAxNCc+PHBhdGggZmlsbD0nIzczODE4ZicgZD0nTTkuMTQ4IDIuMzUybC00LjE0OCA0LjE0OCA0LjE0OCA0LjE0OHEwLjE0OCAwLjE0OCAwLjE0OCAwLjM1MnQtMC4xNDggMC4zNTJsLTEuMjk3IDEuMjk3cS0wLjE0OCAwLjE0OC0wLjM1MiAwLjE0OHQtMC4zNTItMC4xNDhsLTUuNzk3LTUuNzk3cS0wLjE0OC0wLjE0OC0wLjE0OC0wLjM1MnQwLjE0OC0wLjM1Mmw1Ljc5Ny01Ljc5N3EwLjE0OC0wLjE0OCAwLjM1Mi0wLjE0OHQwLjM1MiAwLjE0OGwxLjI5NyAxLjI5N3EwLjE0OCAwLjE0OCAwLjE0OCAwLjM1MnQtMC4xNDggMC4zNTJ6Jy8+PC9zdmc+);
  background-repeat: no-repeat;
  background-position: 50%;
  -webkit-transition: -webkit-transform 0.3s;
  transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
  transition: transform 0.3s, -webkit-transform 0.3s;
  transform: rotate(-180deg);
}

.sidebar .nav-dropdown.open > .nav-dropdown-toggle::before {
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
}

.navbar-expand-md .navbar-toggler {
  display: block;
}

/* .list-group-item {
  padding: 0.125rem 0.75rem;
} */

.container-fluid,
.container-lg,
.container-md,
.container-sm,
.container-xl {
  padding-right: 0;
  padding-left: 0;
}

.container-fluid .navbar {
  padding: 0ch;
}

.container-fluid .navbar .navbar-collapse {
  background: #1d2022;
}

.bg-dark {
  background-color: #282a36 !important;
}

.oval {
  width: 36px;
  height: 36px;
  background-color: #d8d8d8;
  border: solid 1px #979797;
  border-radius: 50%;
}

.btn-trade {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 121px;
  height: 39px;
  background-color: #005581;
  border: none;
  font-size: 14px;
}

.btn-trade .iconTrade {
  width: 13.1px;
  height: 13.1px;
  object-fit: contain;
  mask: url('./assets/img/trade.svg') no-repeat center / contain;
  -webkit-mask: url('./assets/img/trade.svg') no-repeat center / contain;
  background-color: #ffffff;
  margin-right: 10px;
}

.user-name {
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #d4d4d4;
}

.user-code {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #d4d4d4;
}

.text-content {
  color: #d4d4d4;
}

.txt-status {
  width: 37px;
  height: 16px;
  border-radius: 8.5px;
  background-color: #589dc0;
}

.fz_24 {
  font-size: 24px !important;
}

.fz_20 {
  font-size: 20px !important;
}

.fz_18 {
  font-size: 18px !important;
}

.fz_16 {
  font-size: 16px !important;
}

.fz_14 {
  font-size: 14px !important;
}

.fz_13 {
  font-size: 13px !important;
}

.fz_12 {
  font-size: 12px !important;
}

.fz_10 {
  font-size: 10px;
}

.fz_8 {
  font-size: 8px;
}

.fz_40 {
  font-size: 40px !important;
}

.f_i {
  font-style: italic;
}

.badge-warning {
  /* opacity: 0.9; */
  border-radius: 10px;
  color: #f8f8f2;
  background-color: #e4994a;
}

.badge-header {
  position: absolute;
  top: -10px;
  right: -5px;
}

.form-search .form-control {
  width: 212px;
  height: 26px;
  border-radius: 13px;
  background-color: #1d1f2d;
  border-color: transparent;
}

.form-add.form-control:focus {
  border-color: #6d6e71 !important;
  box-shadow: none !important;
}

.form-add {
  width: 175px !important;
  height: 26px;
  padding-left: 22px;
  border-radius: 13px;
  background-color: #05090b;
  border-color: transparent;
  color: #fff;
  text-transform: uppercase;
}

.form-add:focus {
  color: #f5f5f5;
  background-color: #000;
}

.form-add::placeholder,
.form-search .form-control::placeholder {
  font-size: 14px;
  color: #8e8e8e;
  text-transform: capitalize !important;
}

.po-relative {
  position: relative;
}

.form-search-img {
  position: absolute;
  right: 55px;
}

.form-add-img {
  position: absolute;
  left: 5px;
}

.up_mark {
  width: 15px;
  height: 20px;
  object-fit: contain;
}

.down_mark {
  width: 15px;
  height: 20px;
  object-fit: contain;
}

.unchange_mark {
  width: 15px;
  height: 20px;
  object-fit: contain;
}

.btn-stock-minimize {
  min-width: 65px;
  height: 28px;
  padding: 1px 5px;
  border-radius: 4px;
  background-color: #474b63;
  color: #8e8e8e;
  display: flex;
  align-items: center;
}

.btn-stock-minimize.active {
  background-color: #6a7094;
  color: #fff;
}

.btn-stock-minimize:hover .btn-close {
  display: block;
}

.btn-stock-minimize .btn-close {
  display: none;
}

/* @media (max-width: 1456px) {
  .form-search {
    display: none;
  }
} */

.highcharts-xaxis .highcharts-axis-line {
  stroke: #dadada;
}

.highcharts-xaxis .highcharts-tick {
  stroke: #8e8e8e;
  stroke-width: 0.5;
}

/**
*!perfect scrollbar
*/

.ps__rail-x:hover > .ps__thumb-x,
.ps__rail-x:focus > .ps__thumb-x,
.ps__rail-x.ps--clicking .ps__thumb-x {
  height: 10px;
}

.ps .ps__rail-x:hover,
.ps .ps__rail-y:hover,
.ps .ps__rail-x:focus,
.ps .ps__rail-y:focus,
.ps .ps__rail-x.ps--clicking,
.ps .ps__rail-y.ps--clicking {
  background-color: initial !important;
  opacity: 0.9;
}

.notification-search .form-control {
  width: 322px;
  height: 26px;
  font-size: 14px;
  border-radius: 13px;
  border: solid 1px rgba(0, 0, 0, 0.4);
}

.detail-search .form-search-img,
.notification-search .form-search-img {
  position: absolute;
  right: 5px;
  top: 6px;
}

.detail-search .form-control {
  width: 114px;
  height: 26px;
  font-size: 14px;
  border-radius: 13px;
  background-color: #f2f4f6;
  border: solid 1px #f2f4f6;
}

.modal-header,
.modal-footer {
  background-color: #f5f5f5;
}

.modal-footer {
  padding: 0.5rem;
  justify-content: center;
}

.title-unread {
  color: #005581;
}

.title-read {
  color: #666666;
}

.title-time {
  color: #999999 !important;
}

.content-read {
  color: #333333;
}

.list-group-item {
  padding: 0.5rem 0;
  border: none;
}

.cursor-pointer,
header .nav .nav-item {
  cursor: pointer;
}

.tab-header-right {
  height: 30px;
}

.tab-header-right.active {
  border-radius: 4px;
  background-color: #589dc0;
  color: #ffffff;
}

.listGroup-dropdown {
  position: absolute;
  z-index: 2;
  top: 40px;
  left: 0;
  width: 210px;
  color: #333333;
}

.listGroup-dropdown .list-group-item {
  display: flex;
  align-items: center;
}

.listGroup-dropdown .list-group-item:first-child {
  border-bottom: 2px solid #f9f9f9;
}

.panel_order_B {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 44px;
  height: 20px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  background-color: #005581;
  font-size: 12px;
  color: #fff;
  padding: 2px;
}
.panel_order_S {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 44px;
  height: 20px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  background-color: #ff5555;
  font-size: 12px;
  color: #fff;
  padding: 2px;
}

.panel-order-card-B .card-header,
.panel-order-card-S .card-header {
  padding: 2px 10px;
}

.action-info {
  height: 32px;
  border-radius: 8px;
  background-color: #f2f4f6;
}

.tbl-toggle-left {
  position: absolute;
  top: 9px;
  left: 0;
  font-size: 10px;
  color: #fff;
}

.tbl-toggle-right {
  position: absolute;
  top: 9px;
  right: 0;
  font-size: 10px;
  color: #fff;
}

.toggle-color--light3 {
  color: #a7a8aa;
}

.auth-messages ul {
  list-style-type: none;
  padding: 0;
  text-align: left;
}

.auth-messages a {
  color: #007bff;
}

.auth-messages a:hover {
  color: #0056b3;
  text-decoration: underline;
}

.ReactModalPortal {
  user-select: none;
}

.panel-trade {
  position: absolute;
  bottom: 40px;
  height: 210px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  background-color: #ffffff;
}

.full-trade {
  height: calc(100vh - 300px);
}

.form-trade .form-control {
  padding: 0.375rem 0.5rem;
}

.btn-type-trade {
  width: 98px;
  height: 26px;
  border-radius: 4px;
  background-color: #d4d4d4;
  color: #666666;
  font-size: 12px;
  padding: 4px;
}

.btn-type-trade.buy.active {
  background-color: #005581;
  color: #ffffff;
}

.btn-type-trade.sell.active {
  background-color: #ff5555;
  color: #ffffff;
}

.input-order {
  height: 26px;
  border: solid 1px #c6cbd2;
  background-color: #ffffff;
}

.input-order::placeholder {
  text-transform: capitalize;
}

.input-order.text-right::placeholder {
  text-align: left;
}

.btn-order {
  width: 121px;
  height: 32px;
  border-radius: 6px;
  padding: 5px;
  background-color: #999999;
  color: #ffffff;
}

.btn-order.order-buy {
  background-color: #005581;
}

.btn-order.order-sell {
  background-color: #ff5555;
}

.panel-order-asset {
  width: 266px;
  background-color: #f2f4f6;
}

.panel-order-asset ul {
  padding: 0.75rem 0;
}
.panel-order-asset ul li {
  padding: 0.25rem;
}

.form-trade .row.buy {
  background-color: #d9ebf8;
}

.form-trade .row.sell {
  background-color: #fff2f2;
}

.title-symbol-detail {
  font-size: 24px;
  font-weight: 500;
  color: #333333;
}

span.mc {
  width: 50px;
  height: 18px;
  border-radius: 4px;
  border: solid 1px #999999;
}

.br-1 {
  border-right: 1px solid #c4c9d0 !important;
}

.bb-1 {
  border-bottom: 1px solid #c4c9d0 !important;
}

.bt-1 {
  border-top: 1px solid #c4c9d0 !important;
}

.bt-1-half {
  border-top: 1px solid #42465b !important;
}

/* !page-asset */
.page-asset .nav {
  height: 40px;
  border-radius: 4px;
  background-color: #1a1d1f;
}

.page-asset ul.nav li a svg {
  height: 16px;
  margin-right: 15px;
}

.page-asset ul.nav li a svg path {
  fill: #8e8e8e;
}

.page-asset ul.nav li.active a svg path {
  fill: #ffffff;
}

.page-asset ul.nav li {
  padding: 10px 20px;
}

.page-asset ul.nav li a {
  /* height: 26px; */
  /* border-radius: 13px; */
  background-color: transparent;
  color: #8e8e8e;
  font-size: 14px;
  text-decoration: none;
}

.page-asset ul.asset.nav li {
  padding: 0;
}

.page-asset ul.asset.nav li a {
  height: 26px;
  border-radius: 13px;
  padding: 3px 10px;
  background-color: #2f3134;
}

.page-asset ul.asset.nav li a.active {
  background-color: #ffe70b;
  color: #05090b;
}

.page-asset .list-group .list-group-item,
.page-asset ul.nav li.active {
  background-color: #2f3134;
}

.page-asset .list-group .list-group-item:not(:last-child) {
  border-bottom: 1px solid #515882;
}

.page-asset ul.nav li.active a {
  color: #ffffff;
}

.page-asset .asset-header {
  height: 56px;
  /* border-radius: 4px; */
  background-color: #05090b;
  /* border-top: 1px solid #535a86; */
}

.page-asset .asset-header .header-title {
  text-transform: uppercase;
  font-size: 12px;
  color: #8e8e8e;
}

.page-asset .asset-header .header-title.br-1 {
  border-color: #42465b !important;
}

.page-asset .asset-header a {
  width: 122px;
  /* height: 36px; */
  border-radius: 4px;
  background-color: #005581;
  font-size: 14px;
  font-weight: 500;
  color: #ffffff;
  text-align: center;
  /* padding: 6px; */
  margin-right: 1rem;
}

.page-asset .page-content {
  height: calc(100vh - 90px);
  border-top: solid 1px #2f3134;
}

.page-asset .page-content .nav {
  width: 320px;
  height: 100%;
  background-color: #1a1d1f;
  border-right: 1px solid #2f3134;
  border-top: none;
}

.color-half-brown {
  color: #8e8e8e;
}

.page-asset .page-content .nav a {
  font-size: 14px;
  color: #8e8e8e;
}

.page-asset .page-content .nav a.active {
  background-color: #2f3134;
  color: #ffffff;
}

.btn-reload {
  width: 53px;
  height: 26px;
  border-radius: 13px;
  background-color: #2f3134;
  text-align: center;
  border: none;
}

.btn-reload svg {
  transform: rotate(45deg);
}

.table-light tbody + tbody,
.table-light td {
  border-color: #e8e8e8 !important;
  background-color: #ffffff !important;
  color: #000;
}
.table-light th,
.table-light thead th {
  border-color: #d2d2d2 !important;
  background-color: #7abbff !important;
  color: #000;
}

.page-asset .page-content .table-bordered th {
  /* background-color: #2f3134; */
}
.page-asset .page-content .table-bordered th.high-light {
  background-color: #303449;
}
.page-asset .page-content .table-bordered th.high-light-down,
.page-asset .page-content .table-bordered td.high-light-down {
  background-color: #20222f;
}

.page-asset .page-content .table-bordered tr:hover {
  background-color: #434648;
}

.page-asset .page-content .card {
  border: none;
  background-color: transparent;
}

.page-asset .page-content .card .card-header {
  font-size: 14px;
  color: #d4d4d4;
}

.page-asset .page-content .card .card-header,
.page-asset .page-content .card .card-body {
  padding: 0;
  background-color: #2f3134;
  border: none;
}

.page-asset .page-content .card .card-body a {
  width: 122px;
  height: 36px;
  border-radius: 4px;
  background-color: #005581;
  color: #fff;
  text-align: center;
  font-weight: 500;
  padding: 7px;
  font-size: 14px;
}

.page-asset .highcharts-background {
  fill: #1a1d1f;
}

.page-asset .highcharts-legend-item.highcharts-pie-series text tspan {
  color: #ffffff;
}

.btn-add {
  width: 41px;
  height: 26px;
  border-radius: 13px;
  background-color: #6a7094;
  text-align: center;
}

/* .show.picker-detail> .btn-secondary.dropdown-toggle{
  color: #005581;
  background-color: #ffffff;
  border-color: #005581;
} */

.picker-detail .dropdown-toggle {
  padding: 2px 10px !important;
  font-size: 14px !important;
}
.picker-detail .dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25) !important;
}

.picker-detail .dropdown-toggle {
  color: #005581 !important;
  background-color: #ffffff !important;
  border-color: #005581 !important;
}

.picker-detail .dropdown-toggle::after {
  display: none;
}

.ReactModal__Content {
  padding: 0 !important;
  overflow: visible !important;
}

.ReactModal__Content .tab-content {
  height: 100%;
  margin-top: 10px;
}
.ReactModal__Content .content-ordCond .tab-content {
  margin-top: 0px;
}

.tab-pane {
  width: 100%;
  height: 100%;
}

iframe {
  width: 100%;
  height: calc(100% - 0px);
  border: none;
}

.ReactModal__Content .nav-tabs {
  border-bottom: 1px solid #d4d4d4;
}

.ReactModal__Content .nav-tabs .nav-link {
  height: 26px;
  padding: 5px 1rem;
  color: #666666;
  font-size: 12px;
  border: none;
  text-transform: uppercase;
  margin-bottom: 5px;
}

.ReactModal__Content .nav-tabs .nav-link.active {
  border-radius: 13px;
  color: #ffffff;
  background-color: #589dc0;
}

.ReactModal__Content .table-borderless th {
  background-color: #f2f4f6;
  color: #333333;
  border: 1px solid #c4c9d0;
  border-top: none;
}

.ReactModal__Content .table-borderless td {
  border: 1px solid #c4c9d0;
}

.ReactModal__Content .table-borderless td.po-relative {
  padding: 0;
}

.ReactModal__Content .card-header {
  padding: 5px 10px;
}

.ReactModal__Content .btn-secondary {
  height: 26px;
  padding: 2px;
  font-size: 12px;
  color: #666666;
  background-color: #f2f4f6;
  border-color: #f2f4f6;
}

.ReactModal__Content .btn-secondary.active {
  color: #ffffff;
  background-color: #589dc0;
}

.table .progress {
  background-color: transparent;
  height: 0.5rem;
  border-radius: 0;
}

.table .rtl .progress {
  justify-content: flex-end;
}

.table td > .per-vol {
  position: absolute;
  color: #000000;
  font-size: 14px;
  right: 5px;
  top: 3px;
}

.table td.rtl > .per-vol {
  left: 5px;
}

.paging .btn {
  width: 24px;
  height: 24px;
  border-radius: 2px;
  border: solid 1px #d4d4d4;
  background-color: #ffffff;
  color: #000;
  padding: 5px 15px;
  font-weight: 600;
}

.paging a:not([href]) {
  color: #005581;
}

.table-borderless td:not(.d):not(.i) {
  color: #000;
}

.picker-add {
  position: absolute;
  top: 30px;
  z-index: 1;
}

.tooltip-inner {
  white-space: pre-line;
  color: #fff;
  background-color: #005581;
  max-width: 300px;
}

.tooltip.bs-tooltip-auto[x-placement^='top'] .arrow::before,
.tooltip.bs-tooltip-top .arrow::before {
  border-top-color: #005581;
}

.td_event {
  height: 50px;
  padding-bottom: 8px !important;
}

.td_event .has_event {
  height: 18px;
  border-radius: 4px;
  border: solid 1px #005581;
  background-color: #ffffff;
  padding: 0 5px;
  font-size: 12px;
  color: #005581;
}

.td_event .has_event.active {
  background-color: #589dc0;
  border-color: #589dc0;
  color: #ffffff;
}

.table tr td .act-del {
  position: absolute;
  right: 2px;
  top: 10px;
  display: none;
}

.table tr:hover td .act-del {
  display: block;
}

.form-input {
  padding: 0 10px;
  height: 28px;
}

.form-input::placeholder {
  font-weight: normal;
}

.mw-200 {
  max-width: 200px;
}

.mw-195 {
  max-width: 195px;
}

.w-195 {
  width: 195px;
}

.w_125 {
  width: 125px;
}

.w_150 {
  width: 150px;
}

.w_165 {
  width: 165px;
}

.w-140 {
  width: 140px;
}

.w-220 {
  width: 220px !important;
}

.w_75 {
  width: 75px;
}

.btn-light {
  background-color: #6a7094;
  border-color: #6a7094;
  color: #ffffff;
  padding: 4px 15px;
}

.btn-info {
  background-color: #005581;
  border-color: #005581;
  color: #ffffff;
  padding: 4px 15px;
}

.btn-warning {
  padding: 4px 15px;
}

.input-group {
  background-color: #6a7094;
  border-radius: 4px;
}
.input-group-text {
  background-color: transparent;
  border-color: #6a7094;
  color: #ffffff;
}

.input-group .react-datepicker-wrapper input[type='text'] {
  background-color: transparent;
  border: none;
  width: 80px;
  color: #ffffff;
  font-size: 14px;
  padding: 3px 0;
}
.input-group-append,
.input-group-prepend {
  height: 28px;
}

.selCashOut {
  border-radius: 5px;
}

.panel-cash-advance .btn {
  padding: 6px 16px !important;
}
.btn-secondary:not(:disabled):not(.disabled).active:focus {
  box-shadow: none !important;
}

.trade_side_2 {
  background: #ff5555;
  border-radius: 5px;
  color: #ffffff;
  width: 45px;
  text-align: center;
}

.trade_side_1 {
  background: #005581;
  border-radius: 5px;
  color: #ffffff;
  width: 45px;
  text-align: center;
}

td .side_2 {
  border: 1px solid #ff5555;
  /* padding: 0px 5px; */
  border-radius: 5px;
  color: #ff5555;
  width: 45px;
  text-align: center;
}

td .side_1 {
  border: 1px solid #005581;
  /* padding: 0px 5px; */
  border-radius: 5px;
  color: #005581;
  width: 45px;
  text-align: center;
}
/* table th.text-between:after {
  content: attr(data-number);
  display: inline-block;
  width: 25px;
  text-align: right;
}

table th.text-between:before {
  content: attr(data-text);
  display: inline-block;
  text-align: left;
} */

.form-search-head {
  background-color: #1a1d1f;
  border-radius: 4px;
}

.loading-bottom {
  bottom: 50px;
  left: 20px;
  padding: 10px 40px;
  background: #ffffff;
  border-radius: 5px;
  font-size: 14px;
}

.react-datepicker-popper.datepicker-right {
  left: -160px !important;
}
.react-datepicker-popper.datepicker-right .react-datepicker__triangle {
  left: 195px !important;
}

.alert {
  font-size: 14px;
}

.alert.alert-danger {
  color: red;
}

.alert.alert-success {
  color: #0abc0c;
}

.btn-reload,
.btn-reload:focus {
  outline: none;
}

.modal-user svg path {
  fill: #333333;
}
.modal-user .text-success svg path {
  fill: #8fcf4a;
}
.modal-user .text-danger svg path {
  fill: #dc3545;
}

.dropdown.lang .dropdown-toggle::after {
  display: none;
}

.dropdown.lang ul {
  background-color: #303449;
}

.dropdown.lang ul li {
  color: #ffffff;
}
.dropdown.lang ul li:hover {
  color: #000000;
}

.btn-primary {
  background-color: #005581;
  border-color: #005581;
}

.name-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.word-beak-all {
  word-break: break-all;
}

.bg-active td {
  background-color: #585a5d !important;
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .gr-type-order {
    flex-direction: column;
  }
  .form-trade .title-time {
    font-size: 12px !important;
    top: 5px !important;
  }
  .btn-type-trade {
    width: 65px;
  }
  .table td,
  .table th {
    font-size: 12px;
  }
  .form-trade .row-trading-detail {
    overflow: auto;
  }
  .form-trade .table-detail-trade {
    min-width: 500px;
  }

  .fz_16 {
    font-size: 16px !important;
  }

  .fz_14 {
    font-size: 12px;
  }

  .fz_12 {
    font-size: 11px;
  }

  .fz_8 {
    font-size: 8px;
  }
}

.headerSort {
  bottom: 0;
  left: 50%;
  line-height: 5px;
  margin-left: -3px;
  position: absolute;
}

.filterIcon {
  cursor: url(data:image/gif;base64,R0lGODlhCgALAIcAAAAAAMKW/wAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAACH/C05FVFNDQVBFMi4wAwEAAAAh+QQFAAAAACwAAAAACgALAAAIJwABCBQYYKBBAAESHkSYUOHAhhAJQmy4cOHEiAwnPtR4kGLFggMDAgA7),
    auto;
  user-select: none;
}

.w-60 {
  width: 60% !important;
}

.w-40 {
  width: 40% !important;
}

.hover {
  transition: 0.1s ease;
}

.hover.hover-1-25x:hover {
  transform: scale(1.25);
}

.hover.hover-1-5x:hover {
  transform: scale(1.5);
}

/* Custom Sweet Alert */
.swal2-popup {
  padding: 0 !important;
}

.swal2-container.swal2-center > .swal2-popup {
  overflow: hidden;
}

.swal2-image {
  margin-bottom: -15% !important;
  margin-top: -15% !important;
}

.swal2-styled {
  width: 100%;
}

.form-error-message {
  color: #ff5555;
  font-size: 11px;
}
