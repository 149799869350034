.margin-extending-modal {
  .popup-body {
    font-size: 13px;
    line-height: 20px;
    .subAccount {
      display: flex;
      gap: 4px;
      font-style: normal;
      font-weight: 400;
      align-items: center;
      &--label {
        width: 144px;
      }
      &--value {
        width: 100%;
        padding: 8px;
        background-color: $dark-color;
        border-radius: 4px;
      }
    }
    table {
      td,
      th {
        padding: 6px 8px !important;
      }
    }

    .actions {
      display: flex;
      gap: 8px;
      .btn {
        padding: 6px 16px;
      }
    }
  }
}

.add-margin-package-modal {
  &__body {
    min-height: 560px;
  }

  .add-package-form {
    width: 348px;
    padding: 16px;
  }

  input {
    background-color: $dark-color;
    color: $light-color;
    border: none;
    border-radius: 4px;
    outline: none;
    box-shadow: none;
    padding: 6px;

    font-size: 13px;
    line-height: 20px;
    font-weight: 400;
  }

  .btn {
    height: 32px;
    padding: 6px 12px;

    font-size: 13px;
    line-height: 20px;
    font-weight: 500;
  }

  .light {
    input {
      background-color: $light-color;
      color: $dark-color;
    }
  }
}

.add-margin-package-result-modal {
  &__body {
    min-height: 560px;
  }

  .icon-bg {
    height: 80px;
    width: 80px;
    background-color: rgba($color: $up-color, $alpha: 0.2);
    border-radius: 50%;
  }

  .btn {
    height: 32px;
  }
}

.margin-package-contract-modal {
  background-color: $dark1-color;
  height: 560px;

  &__left {
    flex: 5;
  }

  &__right {
    flex: 8;
    background-color: $dark2-color;
  }

  .cbb-box {
    min-width: 16px !important;
    width: 16px !important;
    height: 16px !important;
  }

  .cbb-text {
    color: $light1-color !important;
  }

  .btn {
    height: 32px;
  }

  .btn--buy {
    flex: 1;
  }

  .btn--cancel {
    width: 20%;
  }
}

.cancel-margin-package-modal {
  input {
    background-color: $dark-color;
    color: $light-color;
    border: none;
    border-radius: 4px;
    outline: none;
    box-shadow: none;
    padding: 6px;

    font-size: 13px;
    line-height: 20px;
    font-weight: 400;
  }

  .btn {
    height: 32px;
  }

  .light {
    input {
      background-color: $light-color;
      color: $dark-color;
    }
  }
}

.light {
  .margin-package-contract-modal {
    background-color: $white-color;

    &__right {
      background-color: $light-color;
    }
  }

  .cbb-text {
    color: $dark-color !important;
  }
}
