.marquee-manager {
  display: flex;
  align-items: center;
  background-color: $black-color;
  height: 32px;
  overflow: hidden;
  border-radius: 4px;
  padding: 0 2px;
  flex: 1;
  margin-left: 24px;
  margin-right: 24px;

  .marquee-container {
    overflow: hidden;
    white-space: nowrap;
    // max-width: 333px;
    line-height: 32px;
    flex: 1;

    &.paused {
      .marquee {
        &.animate {
          animation-play-state: paused;
        }
      }
    }

    .marquee {
      display: inline-block;
      padding-left: 0%;
      color: $light-color;
      padding: 0 4px;
      visibility: hidden;
      transform: translateX(600px);

      &.animate {
        visibility: visible;
        animation: marquee linear 1;
      }

      &.infinite {
        animation-iteration-count: infinite !important;
      }
    }
  }

  .marquee-pagings {
    height: 32px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    padding: 1px 8px 1px 0;
    gap: 2px;
  }

  .marquee-paging {
    height: 8px;
    width: 4px;
    background-color: $gray-color;
    cursor: pointer;

    &.active {
      background-color: $active-color;
    }

    &:hover {
      transform: scaleX(3);
    }
  }

  .marquee-action {
    cursor: pointer;
    padding: 0 4px;
    margin-left: 8px;
    margin-right: 8px;

    &:hover {
      span {
        text-decoration: underline;
      }
    }
  }
}

.light .marquee-manager {
  background-color: $dark-blue2;

  .marquee-container {
    .marquee {
      color: $light-color;
    }
  }

  .marquee-paging {
    background-color: $light3-color;
    &.active {
      background-color: $active1-color;
    }
  }
}

@keyframes marquee {
  from {
    transform: translateX(600px);
  }

  to {
    transform: translateX(-100%);
  }
}
