.price-chart {
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 0;

  .card-panel-body {
    align-items: stretch;
    height: 100%;
  }

  .chart {
    height: 100%;
  }
}
