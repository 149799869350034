.price-list {
  min-height: 150px !important;

  .card-panel-body {
    padding: 12px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }

  .price-list-step {
    height: 18px;
    display: flex;
    justify-content: space-between;
    color: $light1-color;
    line-height: 16px;
    font-size: 13px;
    font-weight: 500;
    font-style: normal;

    + .price-list-step {
      margin-top: 3px;
    }

    .price {
      &:hover {
        background-color: rgba($color: $light-color, $alpha: 0.1);
      }
    }

    &__buy {
      overflow: hidden;
      flex: 1;
      margin-right: 2px;
      padding: 4px;
      border-radius: 4px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: $dark-color;
      position: relative;

      .value {
        height: 100%;
        background-color: rgba($color: $up-color, $alpha: 0.2);
        position: absolute;
        top: 0;
        right: 0;
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
        -webkit-transition: width 0.6s ease-in-out;
        -moz-transition: width 0.6s ease-in-out;
        -o-transition: width 0.6s ease-in-out;
        transition: width 0.6s ease-in-out;
      }

      .price {
        position: absolute;
        z-index: 1;
        top: 1px;
        right: 4px;
      }
    }

    &__sell {
      overflow: hidden;
      flex: 1;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-left: 2px;
      padding: 4px;
      border-radius: 4px;
      background-color: $dark-color;
      position: relative;

      .overlay-text {
        color: black;
        z-index: 100 !important;
        position: absolute;
        left: 4px;
        top: 1px;
      }

      .display-text {
        z-index: 102 !important;
        left: 4px;
        top: 1px;
      }

      .value {
        height: 100%;
        background-color: rgba($color: $down-color, $alpha: 0.2);
        position: absolute;
        top: 0;
        left: 0;
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
        -webkit-transition: width 0.6s ease-in-out;
        -moz-transition: width 0.6s ease-in-out;
        -o-transition: width 0.6s ease-in-out;
        transition: width 0.6s ease-in-out;
      }

      .price {
        position: absolute;
        z-index: 1;
        top: 1px;
        left: 4px;
      }
    }
  }

  .price-list-total {
    position: relative;
    border-radius: 4px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: $down-color;
    padding-right: 4px;
    color: $dark-color;
    margin-top: 12px;
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
    min-height: 16px;

    &__buy {
      text-align: left;
      background-color: $up-color;
      padding-left: 4px;
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
      -webkit-transition: width 0.6s ease-in-out;
      -moz-transition: width 0.6s ease-in-out;
      -o-transition: width 0.6s ease-in-out;
      transition: width 0.6s ease-in-out;
    }

    &__sell {
      color: $light-color;
      text-align: end;
    }
  }
}

.price-tabs {
  display: flex;
  flex-direction: column;
  height: 100%;

  .card-panel-body {
    padding: 12px;
    align-items: stretch;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }
}

.pl-items {
  display: flex;
  flex-direction: column;
  gap: 4px;

  &--right {
    flex-direction: column-reverse;
  }
}

.pl-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: $dark-color;
  border-radius: 4px;
  padding: 2px 4px;
  height: 24px;
  position: relative;

  &__vol {
    font-size: 13px;
    line-height: 20px;
    font-weight: 400;
    color: $light1-color;
  }

  &__prc {
    z-index: 1;
    font-size: 13px;
    line-height: 20px;
    font-weight: 400;

    &.i {
      color: $up-color;
    }

    &.d {
      color: $down-color;
    }
  }

  &__pc {
    position: absolute;
    height: 24px;
    width: 50%;
    right: 0;
    z-index: 0;
    border-radius: 4px;

    &.i {
      background-color: rgba($color: $up-color, $alpha: 0.2);
    }

    &.d {
      background-color: rgba($color: $down-color, $alpha: 0.2);
    }
  }
}
