.auto-complete {
  position: relative;
  background-color: $dark-color;
  border-radius: 4px;
  width: 100%;

  &__input {
    width: 100%;
    padding: 10px 8px;
    font-size: 16px;
    color: $light-color;
    background-color: $dark-color;
    border: none;
    outline: none;
    border-radius: 4px;

    font-size: 13px;
    line-height: 20px;
  }

  &__popup {
    list-style: none;
    margin-top: 4px;
    padding: 0;
    border: none;
    max-height: 150px;
    overflow-y: auto;
    position: absolute;
    width: 100%;
    background-color: $dark-color;
    z-index: 10;

    font-size: 13px;
    line-height: 20px;
    border-radius: 4px;

    &-item {
      padding: 8px;
      cursor: pointer;

      &:hover {
        background-color: $dark3-color;
      }
    }
  }
}

.light {
  .auto-complete {
    background-color: $light-color;
    color: $light3-color;

    &__input {
      color: $dark-color;
      background-color: $light-color;
    }

    &__popup {
      background-color: $light-color;
      color: $dark-color;

      &-item {
        &:hover {
          background-color: $light1-color;
        }
      }
    }
  }
}
