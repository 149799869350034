.competition-modal {
  position: relative;

  &__header {
    position: absolute;
    right: -6px;
    top: -8px;

    .btn-icon {
      width: 24px;
      height: 24px;
      border-radius: 50%;
      cursor: pointer;

      &:hover {
        transform: scale(1.2);
      }
    }

    .icon {
      width: 12px !important;
      height: 12px !important;
      background-color: $light1-color;
    }
  }

  &__body {
    width: 708px;
    height: 424px;
    background-color: $dark2-color;
    border-radius: 4px;
    padding: 12px;
  }

  &__image {
    width: 100%;
    height: 100%;
    border-radius: 4px;
  }
}

.adv-modal {
  position: relative;

  &__header {
    position: absolute;
    right: -6px;
    top: -8px;

    .btn-icon {
      width: 24px;
      height: 24px;
      border-radius: 50%;
      cursor: pointer;

      &:hover {
        transform: scale(1.2);
      }
    }

    .icon {
      width: 12px !important;
      height: 12px !important;
      background-color: $light1-color;
    }
  }

  &__body {
    background-color: $dark2-color;
    border-radius: 4px;
    padding: 12px;
  }

  &__image {
    width: 100%;
    height: 100%;
    border-radius: 4px;
  }
}

.light {
  .adv-modal {
    &__body {
      background-color: $light1-color;
    }
  }
}
