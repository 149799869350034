@import '../../../../assets/scss/helpers/variables';

.detail-modal-dialog {
  display: flex;
  justify-content: center;
}
.detail-border-top {
  border-top: 1px dashed $dark3-color;
}
.detail-border-bottom {
  border-bottom: 1px dashed $dark3-color;
}
.backdrop-detail-modal-confirm {
  z-index: 2000 !important;
}
.detail-modal-confirm {
  height: unset !important;
  width: 360px !important;
}
.detail-modal {
  height: calc(100vh - 240px);
  width: 800px;
  background-color: $dark3-color;
  color: $light-color;
  border: none;
  max-width: 100vw;
  position: absolute;
  overflow: hidden;
  animation: slideDown 100ms ease;
  .detail-modal-header {
    padding: 12px;
    background-color: $dark2-color;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    .btn-close {
      right: 0px;
    }
  }
  .detail-modal-body {
    padding: 12px;
    width: 100%;
    .h-32 {
      height: 32px;
    }
    .w-82 {
      width: 82px;
    }
    .cancel {
      height: 32px;
      width: 100%;
    }
    .p-12px {
      padding: 12px;
    }
    .detail-modal-content {
      display: flex;
      flex-direction: column;
      gap: 6px;
    }
  }
}

.sell-all-checkbox {
  .custom-checkbox ~ .custom-checkbox-span {
    border: 2px solid;
    border-color: $dark3-color;
    color: $active-color;
    background: unset;
  }
  .custom-checkbox ~ .custom-checkbox-span::after {
    content: '';
    position: absolute;
    display: none;
  }
  .custom-checkbox:checked ~ .custom-checkbox-span {
    background: $active-color;
  }
}

.light {
}
