.bond-notification {
  display: flex;
  justify-content: center;
  text-align: center;
  background-color: $dark1-color !important;
  border: none;
  padding-bottom: 12px;

  .iClose {
    background-color: $down-color;
  }

  .iCheck {
    background-color: $up-color;
  }

  .iStar {
    background-color: $active-color;
  }

  .text-title {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    color: $light-color;
  }

  .text-desc {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: $light3-color;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: $dark2-color;
    color: $light-color;
    padding: 4px;
    position: relative;

    .text-title {
      width: 100%;
      text-align: center;
    }

    .btn-icon {
      position: absolute;
      right: 0;

      .icon {
        background-color: $light-color;
      }
    }
  }

  &__body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 12px;
    background-color: $dark1-color;
    padding-top: 24px;
    padding-bottom: 12px;
    border-radius: 4px;

    .icon {
      width: 32px;
      height: 32px;
      object-fit: contain;
    }
  }

  &__logo {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    background-color: $dark2-color;

    &--up {
      background-color: rgba($color: $up-color, $alpha: 0.2);
    }

    &--down {
      background-color: rgba($color: $down-color, $alpha: 0.2);
    }

    &--r {
      background-color: rgba($color: $active-color, $alpha: 0.2);
    }

    &--blue3 {
      background-color: rgba($color: $dark-blue3, $alpha: 0.2);
    }
  }

  &__footer {
    margin-bottom: 12px;
  }

  &__actions {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4px;
    height: 32px;

    .btn {
      height: 100%;
      padding: 6px 16px;

      &--cancel {
        &:hover {
          background-color: $light-color;
          color: $dark-color;
        }
      }
    }
  }
}
