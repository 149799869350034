// todo: need to reflector
.popup-fee-tax {
  // width: 228px;
  border-radius: 8px;

  .arrow {
    display: none;
  }

  .popover-body {
    background-color: $dark1-color;
    padding: 4px;
    border-radius: 4px;

    /* 1. inner glow - for dark mode 👇 */
    box-shadow: inset 0 0 0.5px 1px hsla(0, 0%, 100%, 0.1),
      /* 2. shadow ring 👇 */ 0 0 0 1px hsla(230, 13%, 9%, 0.075),
      /* 3. multiple soft shadows 👇 */ 0 0.3px 0.4px hsla(230, 13%, 9%, 0.02),
      0 0.9px 1.5px hsla(230, 13%, 9%, 0.045),
      0 3.5px 6px hsla(230, 13%, 9%, 0.09);

    table {
      border-collapse: collapse;
      width: 100%;
      border-radius: 4px;
      color: $light2-color;
      font-size: 13px;
      font-weight: 400;
    }

    td {
      padding: 4px 12px;
      line-height: 20px;
      border-bottom: 1px solid #242729;
    }

    .text--light1 {
      color: $light1-color;
    }

    /* Loại bỏ đường viền dưới cho hàng cuối cùng */
    tr:last-child td {
      border-bottom: none;
    }
  }
}

.price-overlay {
  &__popover {
    max-width: 500px;
    z-index: 11111;
    background-color: $dark-color;
    color: $light2-color;
    height: 32px;
    inset: -4px 0 auto auto !important;

    .arrow {
      // display: none;

      &::after {
        border-bottom-color: $dark-color;
        border-width: 0 0.25rem 0.5rem 0.25rem;
      }
    }

    .popover-body {
      background-color: $dark-color;
      padding: 4px;
      border-radius: 4px;

      .btn {
        height: 24px;
        background-color: $dark3-color;
        color: $light2-color;
        box-shadow: none;
        border: none;
        outline: none;
        font-size: 12px;
        line-height: 16px;

        & + .btn {
          margin-left: 0.25rem;
        }

        &:hover {
          background-color: $active-color;
          color: $dark2-color;
        }
      }
    }
  }
}

.warning-overlay {
  &__popover {
    border: none;
    border-radius: 4px;
    background: transparent;

    .arrow {
      bottom: -8px;

      &::before {
        bottom: 0;
        border-width: 0.5rem 0.5rem 0;
        border-top-color: $dark-blue;
      }

      &::after {
        display: none;
      }
    }

    .popover-body {
      display: flex;
      flex-direction: column;
      gap: 12px;
      border-radius: 4px;
      color: $light-blue;
      background-color: $dark-blue;
      width: 190px;
      border: none;
    }
  }
}

.reverse-box-shadow {
  box-shadow: inset 0 0 0.5px 1px hsla(0, 0%, 100%, 0.1),
    /* 2. shadow ring 👇 */ 0 0 0 1px hsla(230, 13%, 9%, 0.075),
    /* 3. multiple soft shadows 👇 */ 0 0.3px 0.4px hsla(230, 13%, 9%, 0.02),
    0 0.9px 1.5px hsla(230, 13%, 9%, 0.045),
    0 3.5px 6px hsla(230, 13%, 9%, 0.09);
}

.light {
  &.popup-fee-tax {
    border-radius: 4px;
    .popover-body {
      background-color: $white-color;
      table {
        color: $gray-color;
      }

      td {
        padding: 4px 12px;
        line-height: 20px;
        border-bottom: 1px solid $light-color;
      }

      .text--light1 {
        color: $dark3-color;
      }

      tr:last-child td {
        border-bottom: none;
      }
    }
  }

  &.price-overlay {
    &__popover {
      background-color: $light-color;
      color: $light2-color;
      border: none;

      .arrow {
        &::after {
          border-bottom-color: $light-color;
        }

        &::before {
          display: none;
        }
      }

      .popover-body {
        background-color: $light-color;
        border: none;

        .btn {
          background-color: $white-color;
          color: $dark3-color;

          &:hover {
            background-color: $active1-color;
            color: $dark2-color;
          }
        }
      }
    }
  }

  &.warning-overlay {
    &__popover {
      background: transparent;

      .arrow {
        bottom: -8px;

        &::before {
          bottom: 0;
          border-width: 0.5rem 0.5rem 0;
          border-top-color: $light-blue3;
        }

        &::after {
          display: none;
        }
      }

      .popover-body {
        color: $light-blue;
        background-color: $light-blue3;

        .btn {
          background-color: rgba($light-color, 0.2) !important;
          color: $light-color !important;
        }
      }
    }
  }
}
