@mixin buttonColor($bg, $color) {
  background-color: $bg;
  color: $color;
  border: none;
  outline: none;
  box-shadow: none;

  &:hover {
    background-color: $color;
    color: $bg;

    .icon {
      background-color: $bg !important;
    }
  }

  .icon {
    background-color: $color !important;
  }
}

@mixin buttonOutlineColor($bg, $color) {
  background-color: $bg;
  color: $color;

  &:hover {
    background-color: $color;
    color: $bg;

    .icon {
      background-color: $bg !important;
    }
  }

  .icon {
    background-color: $color !important;
  }
}

@mixin button($theme: dark) {
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  border: 1px solid transparent;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  outline: none;
  box-shadow: none;
  padding: 2px 12px;
  z-index: 1;
  font-size: 13px;
  font-weight: 500;

  &:focus {
    outline: none;
  }

  .icon {
    margin: 0;
  }

  &:focus {
    box-shadow: none;
  }

  @if $theme==light {
    &--primary {
      background-color: $dark-blue3;
      color: $light-color;

      &:hover {
        background-color: $light-blue3;
        color: $light-color;
      }

      .icon {
        background-color: $light-color;
      }
    }

    &--primary2 {
      background-color: rgba($color: $light-blue3, $alpha: 0.2);
      color: $light-blue3;

      &:hover {
        background-color: $light-blue3;
        color: $light-color;

        .icon {
          background-color: $light-color !important;
        }
      }

      .icon {
        background-color: $light-blue3 !important;
      }
    }

    &--light {
      background-color: rgba($color: $dark3-color, $alpha: 0.2);
      color: $dark3-color;

      &:hover {
        background-color: $dark3-color;
        color: $light-color;

        .icon {
          background-color: $light-color !important;
        }
      }

      .icon {
        background-color: $dark3-color !important;
      }
    }

    &--dark {
      background-color: $light-color;
      color: $gray-color;

      &:hover {
        background-color: rgba($color: $gray-color, $alpha: 1);
        color: $light-color;

        .icon {
          background-color: $dark3-color;
        }
      }

      &:focus {
        background-color: rgba($color: $gray-color, $alpha: 1);
        color: $light-color;
      }

      .icon {
        background-color: $light-color;
      }
    }

    &--buy {
      background-color: $up1-color;
      color: $dark3-color;

      &:hover {
        background-color: $up-color;
      }
    }

    &--sell {
      background-color: $down1-color;
      color: $light-color;

      &:hover {
        background-color: $down-color;
      }
    }

    &--buy2 {
      background-color: rgba($up1-color, 0.2);
      color: $up1-color;

      &:hover {
        background-color: rgba($up1-color, 1);
        color: $dark-color;
      }
    }

    &--sell2 {
      background-color: rgba($down-color, 0.2);
      color: $down-color;

      &:hover {
        background-color: $down-color;
        color: $light-color;
      }
    }

    &--sell3 {
      background-color: transparent;
      color: $down1-color;
      border: 1px solid $down1-color;

      &:hover {
        background-color: $down1-color;
        color: $light-color;
      }
    }

    &--demo2 {
      background-color: rgba($color: $light-blue3, $alpha: 0.2);
      color: $light-blue3;

      &:hover {
        background-color: $light-blue3;
        color: $light-color;
      }
    }

    &--active,
    &--authen {
      background-color: rgba($color: $active1-color, $alpha: 0.2);
      color: $active1-color;

      .icon {
        background-color: $active1-color !important;
      }

      &:hover {
        background-color: $active1-color !important;
        color: $dark3-color !important;

        .icon {
          background-color: $dark3-color !important;
        }
      }
    }

    &--cancel,
    &--reset {
      background-color: $light-color;
      color: $dark3-color;

      &:hover {
        background-color: $light1-color;
        color: $dark3-color;
      }
    }

    &--authenticated2 {
      background-color: rgba($color: $up1-color, $alpha: 0.2);
      color: $up1-color;

      &:hover {
        background-color: $up1-color;
        color: $dark3-color;

        .icon {
          background-color: $dark3-color !important;
        }
      }

      .icon {
        background-color: $up1-color !important;
      }
    }

    &--edit {
      background-color: rgba($color: $light-blue3, $alpha: 0.2);
      color: $light-blue3;

      .icon {
        background-color: $light-blue3;
      }

      &:hover {
        background-color: rgba($color: $light-blue3, $alpha: 1);
        color: $light-color;

        .icon {
          background-color: $light-color;
        }
      }
    }

    &--transparent {
      background-color: transparent;
      color: $dark3-color;

      &:hover {
        background-color: $dark3-color;
        color: $light-color;

        .icon {
          background-color: $dark3-color;
        }
      }
    }

    &--transparent2 {
      background-color: transparent;
      color: $dark3-color;

      &:hover {
        background-color: $gray-color;
        color: $light-color;

        .icon {
          background-color: $light-color;
        }
      }

      .icon {
        background-color: $dark3-color;
      }
    }

    &--refresh {
      background-color: $light-color;

      .icon {
        background-color: $dark3-color;
      }

      &:hover {
        background-color: $dark3-color;

        .icon {
          background-color: $light-color;
        }
      }
    }

    &--edit {
      background-color: rgba($color: $light-blue3, $alpha: 0.2) !important;
      color: $light-blue3;

      .icon {
        background-color: $light-blue3 !important;
      }

      &:hover {
        background-color: rgba($color: $light-blue3, $alpha: 1) !important;
        color: $light-color;

        .icon {
          background-color: $light-color !important;
        }
      }
    }

    &--delete {
      background-color: rgba($color: $down1-color, $alpha: 0.2) !important;

      .icon {
        background-color: $down1-color !important;
      }

      &:hover {
        background-color: rgba($color: $down1-color, $alpha: 1) !important;

        .icon {
          background-color: $light-color !important;
        }
      }
    }

    &--delete2 {
      background-color: transparent !important;

      .icon {
        background-color: $down1-color !important;
      }

      &:hover {
        background-color: rgba($color: $down1-color, $alpha: 1) !important;

        .icon {
          background-color: $light-color !important;
        }
      }
    }

    &--delete3 {
      background-color: transparent !important;

      .icon {
        background-color: $dark3-color !important;
      }

      &:hover {
        background-color: rgba($color: $dark3-color, $alpha: 1) !important;

        .icon {
          background-color: $light-color !important;
        }
      }
    }

    &--none {
      background-color: transparent;
      cursor: default !important;
    }

    &.disabled {
      background-color: rgba($light3-color, 0.2) !important;
      color: $gray-color !important;

      .icon {
        background-color: $light3-color !important;
      }

      // &:hover {
      //   background-color: $light3-color !important;
      //   color: $gray-color !important;

      //   .icon {
      //     background-color: $dark3-color;
      //   }
      // }
    }
  } @else {
    &--primary {
      background-color: $dark-blue3;
      color: $light-color;

      &:hover {
        background-color: $light-blue3;
        color: $light-color;

        .icon {
          background-color: $light-color;
        }
      }

      .icon {
        background-color: $light-color;
      }
    }

    &--primary2 {
      background-color: rgba($color: $light-blue3, $alpha: 0.2);
      color: $light-blue3;

      &:hover {
        background-color: $light-blue3;
        color: $light-color;

        .icon {
          background-color: $light-color;
        }
      }

      .icon {
        background-color: $light-blue3;
      }
    }

    &--light {
      background-color: rgba($color: $light-color, $alpha: 0.2);
      color: $light-color;

      &:hover {
        background-color: $light-color;
        color: $dark3-color;

        .icon {
          background-color: $dark3-color !important;
        }
      }

      .icon {
        background-color: $light-color !important;
      }
    }

    &--dark {
      background-color: $dark-color;
      color: $light3-color;

      &:hover {
        background-color: rgba($color: $light-color, $alpha: 1);
        color: $dark3-color;

        .icon {
          background-color: $dark3-color;
        }
      }

      &:focus {
        background-color: rgba($color: $light-color, $alpha: 1);
        color: $dark3-color;
      }

      .icon {
        background-color: $light-color;
      }
    }

    &--buy {
      background-color: $up-color;
      color: $dark-color;

      &:hover {
        color: $dark-color;
      }

      &:focus {
        background-color: $up1-color;
      }
    }

    &--sell {
      background-color: $down-color;
      color: $light-color;

      &:hover {
        color: $light-color;
      }

      &:focus {
        background-color: $down1-color;
      }
    }

    &--buy2 {
      background-color: rgba($up-color, 0.2);
      color: $up-color;

      &:hover {
        background-color: rgba($up-color, 1);
        color: $dark-color;
      }
    }

    &--demo2 {
      background-color: rgba($color: $light-blue3, $alpha: 0.2);
      color: $light-blue3;

      &:hover {
        background-color: $light-blue3;
        color: $light-color;
      }
    }

    &--sell2 {
      background-color: rgba($down-color, 0.2);
      color: $down-color;

      &:hover {
        background-color: $down-color;
        color: $light-color;
        .icon {
          background-color: $light-color !important;
        }
        transition: background-color 0.4ms;
      }
    }

    &--sell3 {
      background-color: transparent;
      color: $down-color;
      border: 1px solid $down-color;

      &:hover {
        background-color: $down-color;
        color: $light-color;
      }
    }

    &--active,
    &--authen {
      background-color: rgba($color: $active-color, $alpha: 0.2);
      color: $active-color;

      .icon {
        background-color: $active-color !important;
      }

      &:hover {
        background-color: $active-color !important;
        color: $dark3-color !important;

        .icon {
          background-color: $dark3-color !important;
        }
      }
    }

    &--cancel,
    &--reset {
      background-color: $dark3-color;
      color: $light-color;

      &:hover {
        color: $light-color;
      }
    }

    &--authenticated2 {
      background-color: rgba($color: $up-color, $alpha: 0.2);
      color: $up-color;

      &:hover {
        background-color: $up-color;
        color: $dark3-color;

        .icon {
          background-color: $dark3-color !important;
        }
      }

      .icon {
        background-color: $up-color !important;
      }
    }

    &--edit {
      background-color: rgba($color: $light-blue3, $alpha: 0.2) !important;
      color: $light-blue3;

      .icon {
        background-color: $light-blue3 !important;
      }

      &:hover {
        background-color: rgba($color: $light-blue3, $alpha: 1) !important;
        color: $light-color;

        .icon {
          background-color: $light-color !important;
        }
      }
    }

    &--delete {
      background-color: rgba($color: $down-color, $alpha: 0.2) !important;

      .icon {
        background-color: $down-color !important;
      }

      &:hover {
        background-color: rgba($color: $down-color, $alpha: 1) !important;

        .icon {
          background-color: $light-color !important;
        }
      }
    }

    &--delete2 {
      background-color: transparent !important;

      .icon {
        background-color: $down1-color !important;
      }

      &:hover {
        background-color: rgba($color: $down1-color, $alpha: 1) !important;

        .icon {
          background-color: $light-color !important;
        }
      }
    }

    &--delete3 {
      background-color: transparent !important;

      .icon {
        background-color: $light-color !important;
      }

      &:hover {
        background-color: rgba($color: $light-color, $alpha: 1) !important;

        .icon {
          background-color: $dark-color !important;
        }
      }
    }

    &--transparent {
      $self: &;
      background-color: transparent;
      color: $light3-color;

      &:hover {
        background-color: rgba($color: $light-color, $alpha: 0.2) !important;
        color: $light-color;

        .icon:not(.no-hover) {
          background-color: $light-color;
        }
      }
    }

    &--none {
      background-color: transparent;
      cursor: default !important;
    }
    &--transparent2 {
      background-color: transparent;
      color: $light-color;

      &:hover {
        background-color: $light3-color;
        color: $dark3-color;

        .icon {
          background-color: $dark3-color;
        }
      }

      .icon {
        background-color: $light1-color;
      }
    }

    &--refresh {
      background-color: transparent;

      .icon {
        background-color: $light-color;
      }
    }

    &--light3 {
      background-color: rgba($light3-color, 0.2);
      color: $light3-color;

      &:hover {
        background-color: $light3-color;
      }

      &.active {
        background-color: $active-color;
        color: $dark3-color;
      }
    }

    &.disabled {
      background-color: rgba($gray-color, 0.2) !important;
      color: $gray-color !important;

      .icon {
        background-color: $gray-color !important;
      }

      // &:hover {
      //   background-color: $dark4-color !important;
      //   color: $gray-color !important;
      // }
    }
  }
}
